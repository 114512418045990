// price provide as a integer with smallest fraction of the currency.
// same way as Stripe handles it.

import { daysSince, daysUntil } from "./days";

export function formatPrice(price: number) {
  if (price % 100 == 0) {
    return `$${(price / 100).toFixed(0)}`;
  } else {
    return `$${(price / 100).toFixed(2)}`;
  }
} // this avoids a "-0"

export function intToString(value: number) {
  return value.toFixed().replace("-0", "0");
}
// always adds a sign (+/-), but not for 0

export function intToSignedString(value: number) {
  let result = value.toLocaleString(undefined, { signDisplay: "always" });
  if (result === "-0" || result === "+0") result = "0";
  return result;
} // friendly formatting of past date

export function friendlyPast(date: Date) {
  let since = daysSince(date);
  if (since === 0) {
    return "Today " + date.toLocaleTimeString("en-US", { hour: "2-digit", minute: "2-digit" });
  } else if (since === 1) {
    return "Yesterday";
  } else if (since > 1 && since < 6) {
    return date.toLocaleDateString("en-US", { weekday: "long" });
  }
  return date.toLocaleDateString("en-US");
}
// friendly formatting of a future date

export function friendlyFuture(date: Date) {
  let until = daysUntil(date);
  if (until < 0) {
    return "today"; // date actually not in the future anymore, but customer is still 'today'
  }
  if (until === 0) {
    return "today";
  } else if (until === 1) {
    return "tomorrow";
  } else if (until > 1 && until < 6) {
    return date.toLocaleDateString("en-US", { weekday: "long" });
  }
  return date.toLocaleDateString("en-US");
}
// standard formating of a Date for the UI

export function friendlyDate(date: Date) {
  return date.toLocaleDateString("en-US");
}

export function friendlyTime(date: Date) {
  return date.toLocaleTimeString("en-US", { hour: "2-digit", minute: "2-digit" });
}

export function friendlyDateTime(date: Date) {
  return friendlyDate(date) + " " + friendlyTime(date);
}
