import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { AlertOk } from "./AlertOk";
import { FUNC_NOOP } from "../../util/constants";
import { AlertYesNo } from "./AlertYesNo";

type AlertOkOptions = {
  title: string;
  text: string | (() => JSX.Element);
  ok?: string;
};

type AlertYesNoOptions = {
  title: string;
  text: string | (() => JSX.Element);
  yes?: string;
  no?: string;
};

type YesNoCallbackType = (yesno: boolean | null | undefined) => void;

type AlertContextType = {
  openAlertOk: (opts: AlertOkOptions, callback: YesNoCallbackType) => void;
  openAlertYesNo: (opts: AlertYesNoOptions, callback: YesNoCallbackType) => void;
  closeAlert: () => void;
};

const AlertContext2 = createContext<AlertContextType | null>(null);

export const AlertProvider2 = (props: { children?: JSX.Element | JSX.Element[] }) => {
  const [alertOkOptions, setAlertOkOptions] = useState<AlertOkOptions | null>(null);
  const [alertYesNoOptions, setAlertYesNoOptions] = useState<AlertYesNoOptions | null>(null);
  const callbackRef = useRef<YesNoCallbackType>(FUNC_NOOP);

  const closeAlert = useCallback((yesno?: boolean | null) => {
    callbackRef.current?.(yesno);
    setAlertOkOptions(null);
    setAlertYesNoOptions(null);
    callbackRef.current = FUNC_NOOP;
  }, []);

  const openAlertOk = useCallback((opts: AlertOkOptions, callback: YesNoCallbackType): void => {
    closeAlert();
    setAlertOkOptions(opts);
    callbackRef.current = callback;
  }, []);

  const openAlertYesNo = useCallback(
    (opts: AlertYesNoOptions, callback: YesNoCallbackType): void => {
      closeAlert();
      setAlertYesNoOptions(opts);
      callbackRef.current = callback;
    },
    []
  );

  const contextValue = useMemo(() => ({ openAlertOk, openAlertYesNo, closeAlert }), []);

  useEffect(() => {
    return () => {
      // when the provider is unmounted also close all alerts
      closeAlert();
    };
  }, []);

  return (
    <AlertContext2.Provider value={contextValue}>
      {props.children}
      {!!alertOkOptions && (
        <AlertOk
          isOpen={!!alertOkOptions}
          title={alertOkOptions.title}
          msg={alertOkOptions.text}
          primary={alertOkOptions.ok}
          onClose={closeAlert}
        />
      )}
      {!!alertYesNoOptions && (
        <AlertYesNo
          isOpen={!!alertYesNoOptions}
          title={alertYesNoOptions.title}
          msg={alertYesNoOptions.text}
          primary={alertYesNoOptions.yes}
          secondary={alertYesNoOptions.no}
          onClose={closeAlert}
        />
      )}
    </AlertContext2.Provider>
  );
};

export const useAlerts2 = () => {
  const alerts = useContext(AlertContext2);

  useEffect(() => {
    return () => {
      // if the containing view is unmounted, then make sure also all alerts are closed
      alerts?.closeAlert();
    };
  }, []);

  return alerts!;
};
