import { VPointDoc } from "../../../model/VPointDoc";

export function selectPointScreen(
  point: VPointDoc | null | undefined
): "PointBorrow" | "PointReturn" | "PointPOS" | "PointReturnWScan" | "PointError" {
  if (point) {
    switch (point.type) {
      case "pos":
        return "PointPOS";

      case "self_borrow_w_confirm":
        return "PointBorrow";

      case "self_return":
        return "PointReturn";

      case "self_return_w_scan":
        return "PointReturnWScan";
    }
  }
  return "PointError";
}
