import _omit from "lodash/omit";
import { Button, IButtonProps } from "native-base";
import React from "react";

export const PrimaryButton: React.FunctionComponent<{ label: string } & IButtonProps> = (props) => {
  const extraProps = _omit(props, ["label", "colorScheme", "variant"]);

  return (
    <Button
      variant="unstyled"
      p={2}
      bg="teal.800"
      borderRadius="2xl"
      _pressed={{ bg: "teal.700" }}
      _focus={{ bg: "teal.700" }}
      _text={{ color: "white", fontWeight: "600" }}
      {...extraProps}
      isDisabled={props.isDisabled || props.isLoading}
      isLoading={props.isLoading}
    >
      {props.label}
    </Button>
  );
};
