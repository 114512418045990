import { Image, Text } from "native-base";
import { VFlex } from "../../../components/layout/VFlex";
import { Screen } from "../../../components/layout/Screen";

export const PointErrorScreen = () => {
  return (
    <Screen name="Point not found">
      <VFlex alignItems="center">
        <Image
          my={4}
          source={require("assets/images/placeholder.png")}
          alt={"What's happening?"}
          resizeMode="contain"
          w="220px"
          h="220px"
        />
        <Text p={4} fontSize="lg" textAlign="center">
          Sorry. This is an unknown check-out point.
        </Text>
      </VFlex>
    </Screen>
  );
};
