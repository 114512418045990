import { create } from "zustand";
import { DEFAULT_FEATURE_FLAGS, FeatureFlagsType } from "../../hooks/FeatureFlagsType";
import { ONE_HOUR, ONE_SEC } from "../../util/constants";
import { AppState } from "react-native";
import { isNative } from "../../util/deviceInfo";
import { firebaseRemoteConfig } from "./remoteConfig";
import { ifProduction } from "../firebase/ifEnv";

type RemoteFlagsStateType = FeatureFlagsType & {
  initializeFeatureFlags(): Promise<void>;
  refreshFeatureFlags(): Promise<void>;
};

export const useRemoteFlags = create<RemoteFlagsStateType>((set, _get) => {
  function copyFlags() {
    const remoteFlags = firebaseRemoteConfig().getAll();

    // extract all values
    const flags: Record<string, string | number | boolean> = {};
    for (const key in DEFAULT_FEATURE_FLAGS) {
      const remoteFlag = remoteFlags[key];
      if (remoteFlag) {
        switch (typeof DEFAULT_FEATURE_FLAGS[key as keyof FeatureFlagsType]) {
          case "boolean":
            flags[key] = remoteFlag.asBoolean();
            break;

          case "number":
            flags[key] = remoteFlag.asNumber();
            break;

          case "string":
            flags[key] = remoteFlag.asString();
            break;
        }
      }
    }

    return flags as FeatureFlagsType;
  }

  async function refresh() {
    try {
      await firebaseRemoteConfig().fetchAndActivate();
      set({ ...copyFlags() });
    } catch (error) {
      console.error("Error fetching remote flags", error);
    }
  }

  async function initialize() {
    // setup defaults
    firebaseRemoteConfig().setDefaults(DEFAULT_FEATURE_FLAGS);

    // copy all known flags (that should copy the last cached values)
    set({ ...copyFlags() });

    // now fetch fresh once
    const minFetchInterval = ifProduction(12 * ONE_HOUR, 10 * ONE_SEC);
    firebaseRemoteConfig().setConfigSettings({
      fetchTimeoutMillis: 5 * ONE_SEC, // initial fetch with a short timeout, so the app doesn't hang too long
      minimumFetchIntervalMillis: minFetchInterval,
    });
    await refresh();
    firebaseRemoteConfig().setConfigSettings({
      fetchTimeoutMillis: 10 * ONE_SEC, // switch to a longer timeout for the next fetches
      minimumFetchIntervalMillis: minFetchInterval,
    });

    // on native then refresh also, whenever the app comes into the foreground
    if (isNative()) {
      AppState.addEventListener("change", (nextAppState) => {
        if (nextAppState == "active") refresh();
      });
    }
  }

  return {
    ...DEFAULT_FEATURE_FLAGS,
    initializeFeatureFlags: initialize,
    refreshFeatureFlags: refresh,
  };
});
