import React from "react";
import { Box, IconButton, StatusBar } from "native-base";
import { SafeAreaView } from "react-native-safe-area-context";
import { useCloseScreen } from "../navigation/useCloseScreen";
import { CloseIcon } from "../icons/Icons";

export type ScreenProps = {
  name: string;
  children?: JSX.Element | JSX.Element[] | string | any;
};

export const FullScreen = (props: ScreenProps) => {
  const closeScreen = useCloseScreen();

  return (
    <SafeAreaView edges={["top"]} style={{}}>
      <Box
        key={props.name}
        w="100%"
        h="100%"
        alignSelf="center"
        bg="screenBackground"
        overflow="hidden"
        _web={{ h: "100vh" }}
      >
        <StatusBar barStyle={"dark-content"} backgroundColor={"white"} translucent={true} />
        <Box w="100%" h="100%">
          {props.children}
          <IconButton
            position="absolute"
            top={2}
            right={4}
            alignSelf="flex-start"
            variant="unstyled"
            colorScheme="black"
            backgroundColor="rgba(128, 128, 128, 0.5)"
            borderRadius={99}
            icon={<CloseIcon mb="-2px" mr="-2px" size="2xl" color="white" />}
            onPress={() => closeScreen()}
          />
        </Box>
      </Box>
    </SafeAreaView>
  );
};
