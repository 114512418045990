import { KeyboardAvoidingView } from "native-base";
import { useCallback } from "react";
import { Screen } from "../../../components/layout/Screen";
import { useCloseScreen } from "../../../components/navigation/useCloseScreen";
import { FrontAppScreenProps } from "../../../frontAppStack";
import { PhoneChangeBody } from "./PhoneChangeBody";

export function ProfileChangePhoneScreen({
  navigation,
  route,
}: FrontAppScreenProps<"ProfileChangePhone">) {
  const closeScreen = useCloseScreen();

  const handleSuccess = useCallback(() => {
    closeScreen();
  }, []);

  return (
    <Screen name="Phone Contact" showTitle={true}>
      <KeyboardAvoidingView behavior="position" keyboardVerticalOffset={0}>
        <PhoneChangeBody onSuccess={handleSuccess} />
      </KeyboardAvoidingView>
    </Screen>
  );
}
