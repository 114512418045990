import firebase from "firebase/compat/app";
import _clone from "lodash/clone";
import _omit from "lodash/omit";
import { firebaseFirestore } from "../firebase/fbenv";
import { TypedDoc } from "./TypedDoc";
import { AnyDocRefType, docRef } from "./fstore_docref";
import { FUNC_NOOP } from "../../util/constants";
import { onOverlay, overlayApply } from "./overlay";
import { TypeDocCons } from "./TypedDoc";

const ENABLE_TRACE = true;

export const ERROR_DOC_ID = "__error__";

/*
 * Firestore doc converter.
 */
class DocConverter<D extends TypedDoc> implements firebase.firestore.FirestoreDataConverter<D> {
  private docCons: TypeDocCons<D>;

  constructor(docCons: TypeDocCons<D>) {
    this.docCons = docCons;
  }

  fromFirestore(
    snapshot: firebase.firestore.QueryDocumentSnapshot,
    options: firebase.firestore.SnapshotOptions
  ): D {
    return new this.docCons(snapshot);
  }

  toFirestore(doc: D): firebase.firestore.DocumentData;
  toFirestore(
    partial: Partial<D>,
    options: firebase.firestore.SetOptions
  ): firebase.firestore.DocumentData;
  toFirestore(
    data: Partial<D>,
    options?: firebase.firestore.SetOptions
  ): firebase.firestore.DocumentData {
    const copy = _clone(data);
    return _omit(copy, ["id", "_ref", "_snapshot"]);
  }
}

/*
 * Repository for douments, which is just a thin wrapper around the Firestore compat API.
 */
export class Repo<D extends TypedDoc> {
  private collectionPath: string;
  private docCons: TypeDocCons<D>;

  constructor(collectionPath: string, docCons: TypeDocCons<D>) {
    this.collectionPath = collectionPath;
    this.docCons = docCons;
  }

  doc(id: string): firebase.firestore.DocumentReference<D>;
  doc(ref: firebase.firestore.DocumentReference<D>): firebase.firestore.DocumentReference<D>;
  doc(doc: D): firebase.firestore.DocumentReference<D>;
  doc(refOrId: AnyDocRefType<D>): firebase.firestore.DocumentReference<D> {
    return docRef(refOrId, this.collectionRef());
  }

  private collectionRef() {
    return firebaseFirestore()
      .collection(this.collectionPath)
      .withConverter<D>(new DocConverter<D>(this.docCons));
  }

  query() {
    return this.collectionRef();
  }
}

//
// ---- all the accessor functions we need -----
//

export async function docGet<D extends TypedDoc>(
  docRef: firebase.firestore.DocumentReference<D> | null | undefined
): Promise<D | null> {
  if (!docRef) return null;

  if (ENABLE_TRACE) console.log("FIRESTORE: docGet()", docRef.path);

  let snapshot = await docRef.get();
  return snapshot.data() ?? null;
}

export async function docUpdate<D extends TypedDoc>(
  docRef: D | firebase.firestore.DocumentReference<D>,
  data: Partial<D>
): Promise<void> {
  if ("_ref" in docRef) docRef = docRef._ref as firebase.firestore.DocumentReference<D>;

  if (ENABLE_TRACE) console.log("FIRESTORE: docUpdate()", docRef.path);

  await docRef.update(data);
}

export function docWatch<D extends TypedDoc>(
  docRef: firebase.firestore.DocumentReference<D> | null | undefined,
  callback: (doc: D | null) => void,
  onError?: (error: firebase.firestore.FirestoreError) => void
): () => void {
  if (!docRef) {
    callback(null);
    return FUNC_NOOP;
  }

  if (ENABLE_TRACE) console.log("FIRESTORE: docWatch()", docRef.path);

  onError ??= (error) => {
    console.error("docWatch()", error);
    callback(null);
  };

  return docRef.onSnapshot((snapshot) => {
    if (ENABLE_TRACE) console.log("FIRESTORE: docWatch snapshot", snapshot.ref.path);

    callback(snapshot.data() ?? null);
  }, onError);
}

// wait for a document to be created in the backend. typically happens with a small
// delay in a trigger. the method return null if the document is not created in the
// give timeout. it DOES NOT throw an exception.
export function docWaitUntilExists<D extends TypedDoc>(
  docRef: firebase.firestore.DocumentReference<D>,
  ms: number = 10000
): Promise<D | null> {
  if (ENABLE_TRACE) console.log("FIRESTORE: docWaitUntilExists()", docRef.path);

  return new Promise((resolve, reject) => {
    const timer = setTimeout(() => {
      unsubscribe();
      console.error(`docWaitUntilExists() at '${docRef.path}' timed out`);
      resolve(null);
    }, ms);
    const unsubscribe = docRef.onSnapshot(
      (snapshot) => {
        if (snapshot.exists) {
          if (ENABLE_TRACE)
            console.log("FIRESTORE: docWaitUntilExists snapshot", snapshot.ref.path);

          clearTimeout(timer);
          unsubscribe();
          resolve(snapshot.data() ?? null);
        }
      },
      (error) => {
        console.error("docWaitUntilExists(): ", error);
        resolve(null);
      }
    );
  });
}

// watches a document for changes, but also applies temp overlay data used by the
// framework here to set an anticipated update from the server.
export function docWatchOverlay<D extends TypedDoc>(
  ref: firebase.firestore.DocumentReference<D>,
  callback: (doc: D | null) => void,
  onError?: (error: firebase.firestore.FirestoreError) => void
): () => void {
  let originalDoc: D | null = null;

  if (ENABLE_TRACE) console.log("FIRESTORE: docWatchOverlay()", ref.path);

  onError ??= (error) => {
    console.error("docWatchOverlay()", error);
    callback(null);
  };

  // listen to the actual database
  const unsubscribeSnapshot = ref.onSnapshot((snapshot) => {
    if (ENABLE_TRACE) console.log("FIRESTORE: docWatchOverlay snapshot", snapshot.ref.path);

    originalDoc = snapshot.data() ?? null;
    const doc = originalDoc ? overlayApply(originalDoc) : null;
    callback(doc);
  }, onError);

  // listen to overlays
  const unsubscribeOverlay = onOverlay(ref.path, () => {
    if (originalDoc) {
      const doc = overlayApply(originalDoc);
      callback(doc);
    }
  });

  return () => {
    unsubscribeOverlay();
    unsubscribeSnapshot();
  };
}

export async function docQuery<D extends TypedDoc>(
  queryRef: firebase.firestore.Query<D>
): Promise<D[]> {
  if (ENABLE_TRACE) console.log("FIRESTORE: docQuery()");

  let querySnapshot = await queryRef.get();
  return querySnapshot.docs.map((doc) => doc.data());
}

export async function docQueryFirst<D extends TypedDoc>(
  queryRef: firebase.firestore.Query<D>
): Promise<D | null> {
  if (ENABLE_TRACE) console.log("FIRESTORE: docQueryFirst()");

  let querySnapshot = await queryRef.limit(1).get();
  if (querySnapshot.docs.length > 0) {
    return querySnapshot.docs[0].data();
  } else {
    return null;
  }
}

export function queryWatch<D extends TypedDoc>(
  query: firebase.firestore.Query<D>,
  callback: (docs: D[]) => void,
  onError?: (error: firebase.firestore.FirestoreError) => void
): () => void {
  if (ENABLE_TRACE) console.log("FIRESTORE: queryWatch()");

  onError ??= (error) => {
    console.error("watchQuery()", error);
    callback([]);
  };

  return query.onSnapshot((querySnapshot) => {
    callback(querySnapshot.docs.map((doc) => doc.data()));
  }, onError);
} // special doc id used when we reach an error situation
