import React from "react";
import { BoxSection, Section } from "../../../components/layout/Sections";
import { PrimaryButton } from "../../../components/primitive/PrimaryButton";
import { Heading, HStack, Text } from "native-base";
import { TCSection } from "../../../components/footer/TCSection";
import { AssetView } from "../../../components/primitive/AssetView";
import { VersionSection } from "../../../components/footer/VersionSection";
import { Screen } from "../../../components/layout/Screen";
import { PreambleScreenProps } from "../preambleStack";

const WELCOME_IMAGE_SIZE = 160;

export const Welcome2Screen = ({ navigation, route }: PreambleScreenProps<"Welcome1">) => {
  return (
    <Screen name="Welcome" onBack={() => navigation.popToTop()}>
      <BoxSection mt={2} pt={4}>
        <Heading mx={8} textAlign="left" fontSize="xl" fontWeight="700">
          How does it work?
        </Heading>
        <HStack px={2} mx={4} justifyContent="flex-start">
          <AssetView
            source={require("assets/illustrations/QR-Scan.svg")}
            alt="Illustration Welcome 1"
            resizeMode="contain"
            w={WELCOME_IMAGE_SIZE}
            h={WELCOME_IMAGE_SIZE}
          />
          <Text mt={8} w="40%" textAlign="left" fontSize="md" fontWeight="600">
            Scan to borrow bowls with your order.
          </Text>
        </HStack>
        <HStack px={2} mx={4} my={-4} justifyContent="flex-end">
          <Text mt={8} w="50%" textAlign="right" fontSize="md" fontWeight="600">
            You'll get reminders to help avoid unwanted purchases.
          </Text>
          <AssetView
            source={require("assets/illustrations/Happy-Message.svg")}
            alt="Illustration Welcome 2"
            resizeMode="contain"
            w={WELCOME_IMAGE_SIZE}
            h={WELCOME_IMAGE_SIZE}
          />
        </HStack>
        <HStack px={2} mx={4} justifyContent="flex-start">
          <AssetView
            source={require("assets/illustrations/Star-Rating.svg")}
            alt="Illustration Welcome 3"
            resizeMode="contain"
            w={WELCOME_IMAGE_SIZE}
            h={WELCOME_IMAGE_SIZE}
          />
          <Text mt={8} w="40%" textAlign="left" fontSize="md" fontWeight="600">
            Scan to return, and it's free of charge!
          </Text>
        </HStack>
      </BoxSection>
      <Section>
        <PrimaryButton
          label="Sign In/Up"
          onPress={() => navigation.navigate("SignIn", {})}
          w="100%"
        />
      </Section>
      <TCSection />
      <VersionSection />
    </Screen>
  );
};

export default Welcome2Screen;
