import { Section } from "./Sections";
import { IBoxProps, Text } from "native-base";

export type SectionMsgType = "text" | "error" | "muted";

export const SectionMsg: React.FC<
  {
    text: string | undefined | null;
    type?: SectionMsgType;
    textSize?: "sm" | "md";
  } & IBoxProps
> = (props) => {
  const textColor =
    props.type === "text" ? "primaryText" : props.type === "error" ? "errorMessage" : "gray.400";

  return (
    <Section mt={-1} alignContent="center" {...props}>
      <Text
        flexGrow={1}
        w="full"
        color={textColor}
        textAlign="center"
        fontSize={props.textSize ?? "sm"}
      >
        {props.text ? props.text : " "}
      </Text>
    </Section>
  );
};

SectionMsg.defaultProps = { type: "text" };
