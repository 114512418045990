import { DependencyList, useCallback, useState } from "react";

export type ErrorAction = "throw" | "ignore";

/**
 * like useCallback(), but any sync or async exceptions are caught and synchronously
 * rethrown by the component so that they can be caught by the error boundary.
 *
 * inspired by answer here: https://stackoverflow.com/questions/47002249/react-16-error-handling-for-event-handlers/47004469#47004469
 */
export function useCatchingCallback<T extends (...args: any[]) => any>(
  callback: T,
  deps: DependencyList,
  errorAction: ErrorAction = "throw"
): (...args: Parameters<T>) => ReturnType<T> | undefined {
  const [catchedError, setCatchedError] = useState<Error>();

  if (catchedError) {
    setCatchedError(undefined);
    throw catchedError;
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useCallback((...args: Parameters<T>) => {
    try {
      const result = callback(...args);
      Promise.resolve(result).catch((err) => {
        if (errorAction === "throw") setCatchedError(err);
      });
      return result;
    } catch (error: any) {
      if (errorAction === "throw") setCatchedError(error);
    }
  }, deps);
}
