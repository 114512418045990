import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/functions";
import "firebase/compat/firestore";
import once from "lodash/once";
import { appConfigExtra, appConfig } from "../expo/appConfig";
import { isEmulator } from "./ifEnv";
import { initAuth } from "./fbenvAuth";
import { Platform } from "react-native";
var isNode = require("detect-node");

const emulatorPorts = {
  functions: 5001,
  hosting: 5000,
  auth: 9099,
  firestore: 8888,
};

export function getEmulatorHostName() {
  // return window.location.hostname;
  if (Platform.OS === "android") {
    return "10.0.2.2";
  }
  return "127.0.0.1";
}

export function iosGoogleClientId(): string | undefined {
  // FirebaseCore does provide some values from the GoogleServiceFile. On iOS it would be the
  // same clientId on Android it's a different clientId. We'll just provide it explicitly.
  return appConfigExtra().clientId.ios;
}

export function androidGoogleClientId(): string | undefined {
  // FirebaseCore does provide some values from the GoogleServiceFile. On iOS it would be the
  // same clientId on Android it's a different clientId. We'll just provide it explicitly.
  return appConfigExtra().clientId.android;
}

export function webGoogleClientId(): string | undefined {
  // NOTE: the web client id is also necessary on native when running in Expo Go
  return appConfig()?.web?.config?.firebase?.clientId;
}

export const firebaseApp = once(() => {
  const fbConfig = appConfigExtra().firebase;
  return firebase.initializeApp(fbConfig!);
});

export const firebaseFirestore = once(() => {
  let fs = firebaseApp().firestore();
  if (isEmulator()) {
    fs.useEmulator(getEmulatorHostName(), emulatorPorts.firestore);
  }

  // this fixes an issues, when on some wifi connections the websocket for onSnapshot() fails.
  // the failure happens most likey because of some proxy configuration of the internet provider.
  // strangely enough this issue only happens on Safari and not in Chrome. a helpful source was:
  // https://github.com/firebase/firebase-js-sdk/issues/5977
  //
  // note: "experimentalAutoDetectLongPolling: true" is not sufficient to solve issues
  //
  // note: there is something funky going on when running in jest/node. setting "experimentalForceLongPolling" without merge
  // works, but removes the host. with "merge" true it doesn't work. the network connection hangs. adding a hidden setting
  // "useFetchStreams: false" makes it work.
  fs.settings({
    experimentalForceLongPolling: true,
    experimentalAutoDetectLongPolling: false,
    // @ts-ignore
    ...(isNode ? { useFetchStreams: false } : undefined),
    merge: true,
  });

  /* FIXME: turn persistence off for now. it might cause further issue in the web app. the Firebase
     documentation is also not super enthusiastic about it in the web. for example it's not
     turned on by default, while on web it's turned on as default.
     
  // NOTE: synchronizeTabs=true causes problems in the WKWebView. unclear why. also not essential.
  fs.enablePersistence({ synchronizeTabs: false }).then(() => {
    console.log("🍏 FS PERSISTENCE ENABLED");
  }).catch(error => {
    console.log("🍎 FS PERSISTENCE FAILED", error);
  })
  */

  // turn on firestore debugging
  // firebase.firestore.setLogLevel("debug");

  return fs;
});

export const firebaseAuth = once(() => {
  // NOTE: for RN we must initialize the auth instance with a persistence provider. this
  // is down in a separate platform specific file, so we avoid all the imports on web. this
  // is also only possible with the Firebase SDK 9.
  initAuth(firebaseApp());

  // now we retrieve the auth instance
  var fa = firebaseApp().auth();
  if (isEmulator()) {
    fa!.useEmulator(`http://${getEmulatorHostName()}:${emulatorPorts.auth}`);
  }
  return fa!;
});

export const firebaseFunctions = once(() => {
  let fn = firebaseApp().functions("us-east4");
  if (isEmulator()) {
    fn.useEmulator(getEmulatorHostName(), emulatorPorts.functions);
  }
  return fn;
});
