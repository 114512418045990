import React from "react";
import { Box, HStack, Text, VStack } from "native-base";

const SIZE = "53px";
export const LargeStat: React.FunctionComponent<{ count: string; title: string }> = (props) => {
  const lines = props.title.split(" ");

  return (
    <HStack p={2} justifyContent="flex-start">
      <Box
        bg="primaryText"
        borderRadius="md"
        w={SIZE}
        h={SIZE}
        justifyContent="center"
        alignItems="center"
      >
        <Text color="white" fontSize="3xl" fontWeight="700">
          {props.count}
        </Text>
      </Box>
      <VStack ml={2}>
        <Text color="primaryText" fontSize="lg" fontWeight="700">
          {lines[0]}
        </Text>
        <Text color="primaryText" fontSize="lg" fontWeight="700">
          {lines[1] ?? ""}
        </Text>
      </VStack>
    </HStack>
  );
};

export const Stat: React.FunctionComponent<{ count: string; title: string }> = (props) => {
  return (
    <VStack alignItems="center">
      <Text color="primaryText" fontSize="2xl" fontWeight="700">
        {props.count}
      </Text>
      <Text mt={1} color="primaryText" fontSize="sm" fontWeight="700">
        {props.title}
      </Text>
    </VStack>
  );
};
