import * as ExpoSplashScreen from "expo-splash-screen";
import * as WebBrowser from "expo-web-browser";
import { NativeBaseProvider } from "native-base";
import React, { useEffect } from "react";
import { SafeAreaProvider } from "react-native-safe-area-context";
import { AppLoading } from "./AppLoading";
import { ErrorBoundary } from "./components/error/ErrorBoundary";
import { initSentry } from "./lib/sentry/sentry";
import { theme } from "./theme";
import { CustomerProvider } from "./features/preamble/auth/CustomerProvider";
import { FrontAppNavigation } from "./FrontAppNavigation";
import { AlertProvider } from "./components/modals/AlertProvider";
import { AlertProvider2 } from "./components/modals/AlertProvider2";
import { AppStoreBanner } from "./AppStoreBanner";

// keeps the native SplashScreen up
ExpoSplashScreen.preventAutoHideAsync();

// this completes the auth from a redirect
WebBrowser.maybeCompleteAuthSession();

export default function FrontApp() {
  useEffect(() => {
    // not done globally because we need the settings from app.json (seee below)
    initSentry();
  }, []);

  return (
    <ErrorBoundary>
      <SafeAreaProvider>
        <NativeBaseProvider theme={theme} config={{ suppressColorAccessibilityWarning: true }}>
          <AppLoading>
            <AppStoreBanner>
              <CustomerProvider>
                <AlertProvider>
                  <AlertProvider2>
                    <FrontAppNavigation />
                  </AlertProvider2>
                </AlertProvider>
              </CustomerProvider>
            </AppStoreBanner>
          </AppLoading>
        </NativeBaseProvider>
        {/* {!isEmbeddedWebApp() ? <NetworkObserver /> : null} */}
      </SafeAreaProvider>
    </ErrorBoundary>
  );
}
