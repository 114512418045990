import * as Google from "expo-auth-session/providers/google";
import { GoogleAuthProvider } from "firebase/auth";
import React, { useCallback } from "react";
import {
  androidGoogleClientId,
  firebaseAuth,
  iosGoogleClientId,
  webGoogleClientId,
} from "../../../lib/firebase/fbenv";
import { ProviderConfig } from "./ProviderConfigTypes";
import { LogoGoogleIcon } from "../../../components/icons/Icons";

const useGoogleAuth = () => {
  // initialization:
  // - prepare the request and the modal prompt
  const [request, response, promptAsync] = Google.useIdTokenAuthRequest({
    androidClientId: androidGoogleClientId(),
    iosClientId: iosGoogleClientId(),
    webClientId: webGoogleClientId(),
    selectAccount: true,
  });
  // console.log("GOOGLE AUTH REQUEST", request);

  // first step:
  // - web, native: the prompt does everything
  // - Expo Go: the prompt only gets an access token, which expo then in async exchanges to a id token
  const promptSignIn = useCallback(async () => {
    try {
      const result = await promptAsync();
      return result.type === "success";
      // ... in a second step useEffect() handles the actual result (see comments)
    } catch (error) {
      console.log("ERROR WITH GOOGLE PROMPT", error);
    }
    return false;
  }, [promptAsync]);

  // second step:
  // - what ever flow (Expo Go or not) is used here the id token is available
  React.useEffect(() => {
    if (response?.type === "success") {
      const credential = GoogleAuthProvider.credential(response.params.id_token);
      firebaseAuth().signInWithCredential(credential);
      // nothing further to do, the MainNavigation will swap to a different stack of screens
    } else if (response?.type === "error") {
      // TODO: display an error message? ... but how to get message in UI?
    }
  }, [response]);

  return promptSignIn;
};

export const useGoogleAuthProviderConfig = (): ProviderConfig => {
  const googleAuth = useGoogleAuth();
  return {
    button: {
      label: "Google",
      color: "#ffffff",
      icon: LogoGoogleIcon,
    },
    promptAsync: googleAuth,
  };
};
