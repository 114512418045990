import React, { useEffect, useState } from "react";
import { Section } from "../../../components/layout/Sections";
import { AlertYesNo } from "../../../components/modals/AlertYesNo";
import { ScreenTitle } from "../../../components/layout/ScreenTitle";
import { VSpace } from "../../../components/layout/VSpace";
import { CheckoutLocationSection } from "./CheckoutLocationSection";
import { LargeCounterInput } from "../../../components/primitive/LargeCounterInput";
import { useCustomer } from "../../../model/useCustomer";
import { appAnalytics } from "../../../lib/analytics/analytics";
import { Screen } from "../../../components/layout/Screen";
import { FrontAppScreenProps } from "../../../frontAppStack";
import { IGNORE_RECENT_BORROW_FOR } from "./constantsCheckout";
import { MAX_RETURN_QUANTITY } from "./constantsCheckout";
import { useReturn } from "./useReturn";

function parseContainerParam(param?: string) {
  if (param) {
    let containerIds = param.split(",");
    containerIds.forEach((id) => id.trim());
    containerIds = containerIds.filter((id) => !!id);
    return containerIds;
  }
  return undefined;
}

export const SelfReturnScreen = ({ navigation, route }: FrontAppScreenProps<"PointReturn">) => {
  const minCount = parseContainerParam(route.params.containerIds)?.length ?? 0;
  const [count, setCount] = useState<number>(minCount);
  const { balance } = useCustomer();
  const [showAlert, setShowAlert] = useState(false);
  const [returnState, callSelfReturn] = useReturn();
  const [errorMsg, setErrorMsg] = useState<string | null>(null);

  useEffect(() => {
    if (returnState.value && returnState.value.success && returnState.value.transaction) {
      appAnalytics().eventReturn(count); // <-- count is user input and not the actual return

      navigation.replace("ShowReceipt", {
        transactionId: returnState.value.transaction.id,
        transaction: returnState.value.transaction,
      });
    } else if (returnState.error) {
      setErrorMsg("An error occurred. Please try again.");
    }
  }, [returnState.value, returnState.error]);

  function handleReturn() {
    if (!!IGNORE_RECENT_BORROW_FOR && balance.isAmbigousReturn(count, IGNORE_RECENT_BORROW_FOR)) {
      // returning a recent borrow -> show alert
      setShowAlert(true);
    } else {
      callSelfReturn(
        route.params.pointId,
        count,
        0,
        parseContainerParam(route.params.containerIds),
        route.params.reasonCode
      );
    }
  }

  function handleCloseAlert(yesno?: boolean) {
    setShowAlert(false);
    const ignoreRecentMs = yesno === false ? IGNORE_RECENT_BORROW_FOR : 0;
    callSelfReturn(
      route.params.pointId,
      count,
      ignoreRecentMs,
      parseContainerParam(route.params.containerIds),
      route.params.reasonCode
    );
  }

  return (
    <Screen name="Return" backMode="close">
      <ScreenTitle title="Return Bowls" />
      <CheckoutLocationSection
        locationId={route.params.locationId}
        imageSource={require("assets/illustrations/Empty-Bowl-Recircle.svg")}
        imageAlt="Illustration Return Bowl"
      />
      <VSpace h="20px" />
      <Section>
        <LargeCounterInput
          count={count}
          min={minCount}
          max={MAX_RETURN_QUANTITY}
          onChange={setCount}
          onSubmit={handleReturn}
          busy={returnState.loading}
          message="How many bowls are you returning?"
          errorMsg={errorMsg}
          label="Return Bowls"
        />
        <VSpace h="20px" />
      </Section>
      <AlertYesNo
        isOpen={showAlert}
        title="Return Bowls"
        primary="Yes, these bowls"
        secondary="No, other bowls"
        msg="Are you returning containers you just borrowed within the last few minutes?"
        onClose={handleCloseAlert}
      />
    </Screen>
  );
};
