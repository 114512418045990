import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { createNavDef } from "../../../components/navigation/NavigationDefinitions";
import PaymentSetupScreen from "./PaymentSetupScreen";

export type PaymentSetupParamList = {
  PaymentSetup: undefined;
};

export type PaymentSetupScreenProps<Screen extends keyof PaymentSetupParamList> =
  NativeStackScreenProps<PaymentSetupParamList, Screen>;

export const paymentSetupNavDef = createNavDef({
  postponeLinks: false,
  screens: {
    PaymentSetup: {
      component: PaymentSetupScreen,
    },
  },
});
