import "firebase/compat/installations";
import { firebaseApp } from "../firebase/fbenv";

export async function getInstallationId(): Promise<string | undefined> {
  try {
    return firebaseApp().installations().getId();
  } catch (error) {
    return undefined;
  }
}
