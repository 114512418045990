import React, { useCallback, useState } from "react";
import { Divider, Input, KeyboardAvoidingView, Text } from "native-base";
import { BoxSection, Section } from "../../../components/layout/Sections";
import { useCloseScreen } from "../../../components/navigation/useCloseScreen";
import { PrimaryButton } from "../../../components/primitive/PrimaryButton";
import { Screen } from "../../../components/layout/Screen";
import { useCustomer } from "../../../model/useCustomer";
import { SectionMsg } from "../../../components/layout/SectionMsg";
import { useFrontRpc } from "../../../lib/functions/rpc";
import { FrontAppScreenProps } from "../../../frontAppStack";
import { overlayWrite } from "../../../lib/firestore/overlay";

export const ProfileChangeEmailScreen = ({
  navigation,
  route,
}: FrontAppScreenProps<"ProfileChangeEmail">) => {
  const closeScreen = useCloseScreen();
  const { profile } = useCustomer();
  const [email, setEmail] = useState(profile.email);
  const [msg, setMsg] = useState<string | null>(null);
  const [changeEmailState, callEmailProfile] = useFrontRpc("changeEmail");

  const handleSaveEmail = useCallback(async () => {
    const result = await callEmailProfile({ email });
    if (result.success) {
      overlayWrite(profile, { email });
      closeScreen();
    } else {
      setMsg(result.msg ?? "Something went wrong");
    }
  }, [email]);

  const handleEmailInput = useCallback((text: string) => {
    setEmail(text);
    setMsg(null);
  }, []);

  const isBusy = changeEmailState.loading;
  const isDisabled =
    !email || email.length < 1 || email === profile.email || changeEmailState.loading;

  return (
    <Screen name="Account Email" showTitle={true}>
      <KeyboardAvoidingView behavior="position" keyboardVerticalOffset={0}>
        <BoxSection divider={<Divider size="1px" w="100%" />}>
          <Input
            nativeID="email"
            keyboardType="email-address"
            placeholder="Enter Email"
            autoComplete="email"
            autoCorrect={false}
            autoCapitalize={"none"}
            value={email}
            onChangeText={handleEmailInput}
          />
        </BoxSection>
        <SectionMsg text={msg} type="error" />
        <Section>
          <PrimaryButton
            my={1}
            label={"Save"}
            isLoading={isBusy}
            isDisabled={isDisabled}
            onPress={handleSaveEmail}
          />
        </Section>
      </KeyboardAvoidingView>
    </Screen>
  );
};
