import firebase from "firebase/compat/app";
import { TypedDoc } from "../lib/firestore/TypedDoc";
import { daysUntil } from "../util/days";
import { VBalanceData } from "../lib/apiDefs";
import { Repo } from "../lib/firestore/fstore";

export interface ExpirationSummary {
  sum: number;
  overdue: number;
  today: number;
  tomorrow: number;
  days2: number;
  week: number;
  rest: number;
}

export class VBalanceDoc extends TypedDoc implements VBalanceData {
  status!: VBalanceData["status"];
  info?: VBalanceData["info"];
  plan!: VBalanceData["plan"];
  outstandingSum!: number;
  outstanding!: VBalanceData["outstanding"];
  total!: VBalanceData["total"];
  events!: VBalanceData["events"];

  constructor(snapshot: firebase.firestore.DocumentSnapshot) {
    super(snapshot);
  }

  getExpirationSummary(now?: Date): ExpirationSummary {
    let sum = 0;
    let overdue = 0;
    let today = 0;
    let tomorrow = 0;
    let days2 = 0;
    let week = 0;
    let rest = 0;

    sum = this.outstandingSum!;

    // sort borrow items based on expiration date
    let items = Object.entries(this.outstanding ?? {}).map((entry) => entry[1]);
    items.sort((a, b) => a.expiresAt.getTime() - b.expiresAt.getTime());

    // place them into the buckets "overdue", "today", "tomorrow", "this week", "more than a week"
    for (let item of items) {
      let daysLeft = daysUntil(item.expiresAt, now);
      if (daysLeft < 0) {
        overdue += item.qty;
      } else if (daysLeft === 0) {
        today += item.qty;
      } else if (daysLeft === 1) {
        tomorrow += item.qty;
      } else if (daysLeft === 2) {
        days2 += item.qty;
      } else if (daysLeft < 7) {
        week += item.qty;
      } else {
        rest += item.qty;
      }
    }

    return { sum, overdue, today, days2, tomorrow, week, rest };
  }

  getNextExpiresAt(): Date | null {
    if (this.outstandingSum && this.outstanding) {
      let items = Object.entries(this.outstanding ?? {}).map((entry) => entry[1]);
      items.sort((a, b) => a.expiresAt.getTime() - b.expiresAt.getTime());
      if (items[0]) {
        return items[0].expiresAt;
      }
    }
    return null;
  }

  getBorrowedSince(durationMs: number) {
    if (durationMs <= 0) return 0;

    // is there anything borrowed?
    if (this.outstandingSum && this.outstanding) {
      // items as array
      const items = Object.entries(this.outstanding ?? {}).map((entry) => entry[1]);

      // now count
      const since = Date.now() - durationMs;
      return items.reduce((acc, item) => {
        if (item.transactionAt.getTime() > since) {
          return acc + item.qty;
        }
        return 0;
      }, 0);
    }
    return 0;
  }

  isAmbigousReturn(returnCount: number, durationMs: number) {
    if (returnCount > 0 && this.outstandingSum && this.outstanding) {
      const recentBorrows = this.getBorrowedSince(durationMs);
      return recentBorrows > 0 && this.outstandingSum - returnCount < recentBorrows;
    }
    return false;
  }
}

export const VBalances = new (class VBalances extends Repo<VBalanceDoc> {
  constructor() {
    super("v_balances", VBalanceDoc);
  }
})();
