import firebase from "firebase/compat/app";
import { TypedDoc } from "../lib/firestore/TypedDoc";
import { TTLCache } from "../util/ttlcache";
import { Address, PointType, VPointData } from "../lib/apiDefs";
import { Repo, docGet } from "../lib/firestore/fstore";

export class VPointDoc extends TypedDoc implements VPointData {
  type!: PointType;
  partnerId!: string;
  locationId!: string;
  locationName!: string;
  locationAddress!: Address;
  settings?: VPointData["settings"];

  constructor(snapshot: firebase.firestore.DocumentSnapshot) {
    super(snapshot);
  }
}

export const VPoints = new (class VPoints extends Repo<VPointDoc> {
  cache = new TTLCache<VPointDoc>();

  constructor() {
    super("v_points", VPointDoc);
  }

  async findById(pointId: string) {
    let point: VPointDoc | null | undefined = this.cache.get(pointId);
    if (!point) {
      point = await docGet(this.doc(pointId));
      if (point) this.cache.set(point.id, point);
    } else {
      // console.log("VPointsRepo cache hit", pointId);
    }
    return point;
  }
})();
