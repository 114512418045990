import "firebase/compat/functions";
import { getApiVersion } from "../expo/appConfig";
import { deserialize, serialize } from "./transform";
import { firebaseFunctions } from "../firebase/fbenv";
import {
  RpcBackProcedures,
  RpcDefaultInput,
  RpcDefaultOutput,
  RpcFrontProcedures,
  RpcRequest,
  RpcResponse,
  RpcPingProcedures,
} from "../../../../backend-types/src";
import useAsyncFn from "react-use/lib/useAsyncFn";
import { ONE_SEC } from "../../util/constants";
import { sentry } from "../sentry/sentry";

export const FUNCTION_CALL_TIMEOUT = 15 * ONE_SEC;

//
// -----  helper types  -----
//

export type RpcAbstractProcedures = Record<
  string,
  { input: RpcDefaultInput; output: RpcDefaultOutput }
>;
export type RpcNames<P extends RpcAbstractProcedures> = Extract<keyof P, string>;
export type RpcInputType<P extends RpcAbstractProcedures, N extends RpcNames<P>> = P[N]["input"];
export type RpcOutputType<P extends RpcAbstractProcedures, N extends RpcNames<P>> = P[N]["output"];

//
// ----- RPC client  -----
//

class RpcClient<P extends RpcAbstractProcedures> {
  firebaseFuncName: string;

  constructor(firebaseFuncName: string) {
    this.firebaseFuncName = firebaseFuncName;
  }

  async call<N extends RpcNames<P>>(
    path: N,
    input: RpcInputType<P, N>
  ): Promise<RpcOutputType<P, N>> {
    console.log("RPC REQUEST", path, input);

    // serialize the input data
    const jsonSer: any = serialize(input);

    // prepare the actual request
    const request: RpcRequest = {
      id: (Number.MAX_SAFE_INTEGER * Math.random()).toFixed(),
      version: getApiVersion(),
      path,
      input: jsonSer,
    };

    // prepare the function instance
    const instance = firebaseFunctions().httpsCallable(this.firebaseFuncName, {
      timeout: FUNCTION_CALL_TIMEOUT,
    });

    // make the actual server call
    let result;
    try {
      result = await instance(request);
      console.log("RPC RESPONSE", path, result);
    } catch (cause: any) {
      throw new Error("error calling rpc. " + String(cause));
    }

    // process the response
    const response: RpcResponse = result.data;
    if ("error" in response) {
      console.error("RPC ERROR", path, response);
      throw new Error(response.error.message ?? "rpc error", { cause: response as any });
    } else if ("output" in response) {
      const output = deserialize(response.output);
      return output as any;
    } else {
      throw new Error("malformed response");
    }
  }

  // "fire and forget" means we don't wait for the return
  fire<N extends RpcNames<P>>(path: N, input: RpcInputType<P, N>) {
    this.call<N>(path, input).catch((error) => {
      sentry().captureException(error);
      console.error("ONEWAY RPC ERROR", path, error);
    });
  }
}

//
// -----  the actual RPC client instances -----
//

export const FrontRpc = new RpcClient<RpcFrontProcedures>("frontrpc");
export const BackRpc = new RpcClient<RpcBackProcedures>("backrpc");
export const PingRpc = new RpcClient<RpcPingProcedures>("pingrpc");

//
// -----  hooks  -----
//

function createUseRpc<P extends RpcAbstractProcedures, N extends RpcNames<P>>(
  rpcClient: RpcClient<P>
) {
  return <N extends RpcNames<P>>(path: N) => {
    return useAsyncFn((input: RpcInputType<P, N>) => {
      return rpcClient.call(path, input);
    });
  };
}

export const useFrontRpc = createUseRpc(FrontRpc);
export const useBackRpc = createUseRpc(BackRpc);
export const usePintRpc = createUseRpc(PingRpc);
