const REPLACE_REGEX = /[\(\)\-\/\.\s]/g;

// used to prepare a phone number for use with a "tel:" link
export function cleanTel(phone: string | undefined): string | undefined {
  if (!phone) return phone;
  return phone.replace(REPLACE_REGEX, "");
}

export function fixPhone(phone: string | null | undefined): string {
  if (!phone) phone = "";

  phone = phone.trim();
  phone = phone.replace(REPLACE_REGEX, "");
  if (phone.startsWith("0")) return phone;
  if (phone.startsWith("1")) phone = "+" + phone;
  if (!phone.startsWith("+")) phone = "+1" + phone;

  return phone;
}

const PHONE_REGEX = /^\+(?:[0-9]●?){6,14}[0-9]$/g;
export function validatePhone(phone: string | null | undefined) {
  return !!phone && !!phone.match(PHONE_REGEX) && (!phone.startsWith("+1") || phone.length == 12);
}

const PHONE_REGEX_2 = /^(\+[0-9])?[0-9]{10,}$/g;
export function validatePhoneInput(phone: string | null | undefined) {
  if (!phone) return false;
  phone = phone.trim();
  phone = phone.replace(REPLACE_REGEX, "");
  return !!phone.match(PHONE_REGEX_2);
}
