import React from "react";
import { useCloseScreen } from "../../../components/navigation/useCloseScreen";
import { PaymentBody } from "./payment/PaymentBody";
import { Screen } from "../../../components/layout/Screen";
import { FrontAppScreenProps } from "../../../frontAppStack";

export const PaymentChangeScreen = ({
  navigation,
  route,
}: FrontAppScreenProps<"PaymentChange">) => {
  const closeScreen = useCloseScreen();

  return (
    <Screen name="Payment">
      <PaymentBody setup={false} submitLabel="Change Payment" onDone={closeScreen} />
    </Screen>
  );
};
