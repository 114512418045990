import firebase from "firebase/compat/app";
import { create } from "zustand";
import { FUNC_NOOP } from "../util/constants";
import { authSignOut, useAuthState } from "../lib/auth/authState";
import { VBalanceDoc, VBalances } from "./VBalanceDoc";
import { VProfileDoc, VProfiles } from "./VProfileDoc";
import { appAnalytics } from "../lib/analytics/analytics";
import { docWatchOverlay } from "../lib/firestore/fstore";

//
// -----  raw watch states  -----
//

type WatchProfileState = {
  profile: VProfileDoc | null;
};

const useWatchProfile = create<WatchProfileState>((set, get) => {
  let unsubscribeProfile = FUNC_NOOP;
  let store: WatchProfileState = { profile: null };

  useAuthState.subscribe((authState) => {
    if (authState.isPending) {
      unsubscribeProfile();
      unsubscribeProfile = FUNC_NOOP;
      set({ profile: null });
    } else if (authState.isAuthenticated) {
      // NOTE: this happens, because the auth state is update twice, first the user and then with the claims
      if (authState.user.uid === get().profile?.id) return;

      // watch the profile of the authenticated user
      unsubscribeProfile();
      unsubscribeProfile = docWatchOverlay(
        VProfiles.doc(authState.user.uid),
        (profile) => {
          // NOTE: after sign-up most likely the profile is null, because the view replication is still happening. just
          // wait for the next update. we'll eventually get a profile. if not it's anyways a "catastrophic failure". it
          // would mean that something on the server isn't working correctly.
          set({ profile });
        },
        (error) => {
          // if the snapshot listener fails, something is really wrong. so sign out
          // the customer to get back to a good state
          console.error("AUTH PROFILE LISTENER", error);
          authSignOut();
        }
      );
    } else {
      unsubscribeProfile();
      unsubscribeProfile = FUNC_NOOP;
      set({ profile: null });
    }
  });

  return store;
});

type WatchBalanceState = {
  balance: VBalanceDoc | null;
};

const useWatchBalance = create<WatchBalanceState>((set, get) => {
  let unsubscribeBalance = FUNC_NOOP;
  let store: WatchBalanceState = { balance: null };

  useWatchProfile.subscribe((profileState) => {
    if (profileState.profile) {
      // NOTE: a profile change is either a name change (no need to restart listener) or an account
      //       change. in that case we must restart the listener.
      if (profileState.profile.getAppAccountId() === get().balance?.id) return;

      // listen to the balance of that account
      unsubscribeBalance();
      unsubscribeBalance = docWatchOverlay(
        VBalances.doc(profileState.profile.getAppAccountId()),
        (balance) => {
          // NOTE: after sign-up most likely the balance is null, because the view replication is still happening. just
          // wait for the next update. we'll eventually get a balance. if not it's anyways a "catastrophic failure". it
          // would mean that something on the server isn't working correctly.
          set({ balance });
        },
        (error) => {
          // if the snapshot listener fails, something is really wrong. so sign out
          // the customer to get back to a good state
          console.error("AUTH BALANCE LISTENER", error);
          authSignOut();
        }
      );
    } else {
      unsubscribeBalance();
      unsubscribeBalance = FUNC_NOOP;
      set({ balance: null });
    }
  });

  return store;
});

//
// -----  combined auth state including user, profile, balance  -----
//

export interface AuthCustomerState {
  // whether any data is still pending. changes only once from true -> false.
  isPending: boolean;

  // authenticated user
  user?: firebase.User;

  // loading, indicates that we are busy getting profile and balance data.
  isLoading: boolean;

  // reference to the actual data
  balance: VBalanceDoc | null;
  profile: VProfileDoc | null;

  // last authenticated user (we need that for smooth state handling)
  //
  // The tricky part is when the customer signs out. The state change causes the screens to be
  // unmounted, but it might happen that a screen is using the useCustomer() hook might get updated
  // first before the swap out, which then causes an error. we track the last known user and leave the
  // values even after sign out.
  lastUser?: firebase.User;
  lastBalance: VBalanceDoc | null;
  lastProfile: VProfileDoc | null;
}

/*
 * This hook provides the profile/balance data for the current
 * authenticated user. If no user is authenticated, the values are null.
 */
export const useAuthCustomer = create<AuthCustomerState>((set, get) => {
  let store: AuthCustomerState = {
    isPending: true,
    isLoading: false,
    user: undefined,
    balance: null,
    profile: null,
    lastUser: undefined,
    lastBalance: null,
    lastProfile: null,
  };

  useAuthState.subscribe((authState) => {
    if (authState.isPending) {
      // WAITING TO DETERMINE AUTHENTICATION
      //
      set({
        isPending: true,
        isLoading: false,
        user: undefined,
        profile: null,
        balance: null,
        // don't update last known user!
      });
    } else if (authState.isAuthenticated) {
      // AUTHENTICATED
      //
      // IMPORTANT: in case the authenticated user changes (currently no user case in the app), then we'll make the transition
      //            not visible (loading: false). this prevents a UI change in the navigation.
      set((prev) => {
        const loaded = !!prev.profile && !!prev.balance;
        return {
          user: authState.user,
          isLoading: !loaded,
          lastUser: authState.user,
        };
      });
      appAnalytics().eventSignIn();
    } else {
      // UNAUTHENTICATED
      //
      set({
        isPending: false,
        isLoading: false,
        user: undefined,
        balance: null,
        profile: null,
        // don't update last known user (see comments above)
      });
    }
  });

  useWatchProfile.subscribe((profileState) => {
    set((prev) => {
      const loaded = !prev.user || (!!profileState.profile && !!prev.balance);
      return {
        isPending: prev.isPending && !loaded,
        isLoading: !loaded,
        profile: profileState.profile,
        lastProfile: profileState.profile ?? prev.lastProfile,
      };
    });
  });

  useWatchBalance.subscribe((balanceState) => {
    set((prev) => {
      const loaded = !prev.user || (!!balanceState.balance && !!prev.profile);
      return {
        isPending: prev.isPending && !loaded,
        isLoading: !loaded,
        balance: balanceState.balance,
        lastBalance: balanceState.balance ?? prev.lastBalance,
      };
    });
  });

  return store;
});

//
// -----  special version for all components, which anyways require authentication  -----
//

export interface CustomerState {
  user: firebase.User;
  balance: VBalanceDoc;
  profile: VProfileDoc;
}

/* This hook raises an exception when used without a customer. It makes writting code
 * easier, because no null check is necessary and it's also a safe guard for using screens
 * which require an authenticated user without authentication.
 *
 * Returning the last known user ensures that there will be no issues, if a screen gets updated
 * before being swapped out by a sign out.
 */
export const useCustomer = () => {
  const authState = useAuthCustomer();

  if (!!authState.lastUser && !!authState.lastBalance && !!authState.lastProfile) {
    return {
      user: authState.lastUser,
      balance: authState.lastBalance,
      profile: authState.lastProfile,
    };
  } else {
    throw new Error(
      "useCustomer() has no authenticated customer. " +
        "hook must only be used where authentication is garanteed. " +
        "check code that MainNavigation doesn't swap in screens to early."
    );
  }
};
