import { create } from "zustand";

// helper state to simulate date dependent features, while testing
export interface DateState {
  offset: number;
  now: () => Date;
  setDateOffset: (value: number) => void;
  reset: () => void;
}

export const useDate = create<DateState>((set, get) => {
  return {
    offset: 0,
    now: () => new Date(Date.now() + get().offset),
    setDateOffset: (value: number) => set({ offset: value }),
    reset: () => set({ offset: 0 }),
  };
});
