import React from "react";
import { useAuthCustomer } from "./model/useCustomer";
import { AppNavigation } from "./components/navigation/Navigation";
import { NavigationDefinition } from "./components/navigation/NavigationDefinitions";
import { frontAppNavDef } from "./frontAppStack";
import { paymentSetupNavDef } from "./features/setup/payment/paymentSetupStack";
import { preambleNavDef } from "./features/preamble/preambleStack";
import { phoneSetupNavDef } from "./features/setup/phone/phoneSetupStack";
import _isFinite from "lodash/isFinite";

export function FrontAppNavigation() {
  const { profile, balance } = useAuthCustomer(); // NOTE: data already loaded, because protected by CustmerProvider.

  let navDef: NavigationDefinition<string>;
  if (!profile || !balance) {
    navDef = preambleNavDef;
  } else if (profile.requiresPhoneSetup()) {
    navDef = phoneSetupNavDef;
  } else if (profile.requiresPaymentSetup()) {
    navDef = paymentSetupNavDef;
  } else {
    navDef = frontAppNavDef;
  }

  return (
    <AppNavigation
      navDef={navDef}
      rewrites={{
        "p/": "/a/point/",
        "r/": "/a/register/",
        "i/": "/a/item/",
        "cc/1000/": "/a/item/",
      }}
    />
  );
}
