import _isFinite from "lodash/isFinite";
import { MessageToast, useMesageToast } from "../../../components/modals/MessageToast";
import { VProfileDoc } from "../../../model/VProfileDoc";

export function VerifyPhoneToast(props: { profile: VProfileDoc; onPress?: () => void }) {
  const toastProps = useMesageToast({
    id: "verifyPhone",
    enabled: props.profile.askAddPhone() || props.profile.needsPhoneVerification(),
    onPress: props.onPress,
  });

  return (
    <MessageToast
      status="info"
      title={props.profile.phone ? "Please Verify Phone Number" : "Please add a phone number"}
      description={
        props.profile.phone
          ? "It's important that we have a validated phone number."
          : "It's important that we have your mobile phone number."
      }
      actionLabel={props.profile.phone ? "Verify" : "Add Phone"}
      {...toastProps}
    />
  );
}
