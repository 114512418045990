import React from "react";
import Lottie from "react-lottie-player";
import type { LottierPlayerProps } from "./LottiePlayer";

export const LottiePlayer = (props: LottierPlayerProps) => {
  return (
    <Lottie
      loop
      animationData={props.source}
      play
      speed={props.speed}
      style={{ height: `${props.height}px`, width: `${props.width}px` }}
    />
  );
};
