import { useFocusEffect } from "@react-navigation/native";
import {
  Alert,
  Box,
  Button,
  CloseIcon,
  HStack,
  IconButton,
  Text,
  VStack,
  useDisclose,
} from "native-base";
import { useCallback, useMemo } from "react";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { useLocalUserState } from "../../hooks/useLocalUserState";
import { ONE_DAY } from "../../util/constants";

export function useMesageToast(opts: {
  id: string;
  enabled: boolean;
  silentTime?: number;
  onPress?: () => void;
}) {
  const { isOpen, onOpen, onClose } = useDisclose(false);
  const localState = useLocalUserState();
  const silentKey = `silent:${opts.id}`;

  const handleClose = useCallback(() => {
    localState.setFlag(silentKey, true, opts.silentTime ?? ONE_DAY);
    onClose();
  }, [silentKey, opts.silentTime]);

  const handlePress = useCallback(() => {
    localState.setFlag(silentKey, true, opts.silentTime ?? ONE_DAY);
    onClose();
    opts.onPress?.();
  }, [silentKey, opts.onPress, opts.silentTime]);

  useFocusEffect(
    useCallback(() => {
      const show = opts.enabled && !localState.getFlag(silentKey);
      if (show) onOpen();
      return onClose;
    }, [silentKey, opts.enabled])
  );

  const result = useMemo(() => {
    return {
      isOpen,
      onPress: handlePress,
      onClose: handleClose,
    };
  }, [isOpen]);

  return result;
}

export function MessageToast(props: {
  isOpen: boolean;
  status?: "info" | "warning" | "error" | "success";
  title: string;
  description: string;
  actionLabel?: string;
  onPress?: () => void;
  onClose?: () => void;
}) {
  const insets = useSafeAreaInsets();

  if (props.isOpen) {
    return (
      <VStack position="absolute" top={insets.top + 10} zIndex={2000} w="100%" alignItems="center">
        <AlertToast
          id="alert"
          status={props.status}
          title={props.title}
          description={props.description}
          actionLabel={props.actionLabel}
          onPress={props.onPress}
          onClose={props.onClose}
        />
      </VStack>
    );
  } else {
    return null;
  }
}

const AlertToast = (props: {
  id: string;
  status?: string;
  variant?: string;
  title: string;
  description: string;
  actionLabel?: string;
  onPress?: () => void;
  onClose?: () => void;
}) => {
  // NOTE: this code was copied from an example on NativeBase web site and just modified a bit
  return (
    <Alert
      m={2}
      p={3}
      maxWidth="100%"
      alignSelf="center"
      flexDirection="row"
      status={props.status ? props.status : "info"}
      variant={props.variant}
      borderRadius={6}
      bg="amber.300"
      shadow={2}
    >
      <VStack space={0} flexShrink={1} w="100%">
        <HStack flexShrink={1} alignItems="center" justifyContent="space-between">
          <HStack space={2} flexShrink={1} alignItems="center">
            <Alert.Icon color="amber.500" />
            <Text
              fontSize="md"
              flexShrink={1}
              fontWeight="bold"
              color={
                props.variant === "solid"
                  ? "lightText"
                  : props.variant !== "outline"
                  ? "darkText"
                  : null
              }
            >
              {props.title}
            </Text>
          </HStack>
          <HStack space={1} flexShrink={0} alignItems="center">
            {props.onClose ? (
              <IconButton
                variant="unstyled"
                icon={<CloseIcon size="3" />}
                _icon={{
                  color: props.variant === "solid" ? "lightText" : "darkText",
                }}
                onPress={props.onClose}
              />
            ) : null}
          </HStack>
        </HStack>
        <HStack flexShrink={1} alignItems="center" justifyContent="space-between">
          <Box w={6} />
          <Text
            fontSize="md"
            flexShrink={1}
            color={
              props.variant === "solid"
                ? "lightText"
                : props.variant !== "outline"
                ? "darkText"
                : null
            }
          >
            {props.description}
          </Text>
          <Box w={6} />
        </HStack>
        {!!props.actionLabel && !!props.onPress && (
          <HStack justifyContent="flex-end">
            <Button
              variant="outline"
              borderColor="amber.500"
              colorScheme="amber"
              size="xs"
              onPress={props.onPress}
            >
              <Text fontWeight="bold">{props.actionLabel}</Text>
            </Button>
          </HStack>
        )}
      </VStack>
    </Alert>
  );
};
