import { Flex, IBoxProps, IFlexProps } from "native-base";
import React from "react";

export const VFlex = React.forwardRef<unknown, IBoxProps<IFlexProps>>((props, ref) => {
  return (
    <Flex ref={ref} flexDirection="column" {...props}>
      {props.children}
    </Flex>
  );
});
