import React, { useState } from "react";
import { useElements, useStripe, PaymentElement } from "@stripe/react-stripe-js";
import { PrimaryButton } from "../../../../components/primitive/PrimaryButton";
import { Box, Text } from "native-base";
import { GestureResponderEvent } from "react-native";
import { FrontRpc } from "../../../../lib/functions/rpc";

export const PaymentCardSection = (props: {
  isDisabled: boolean; // external disabled
  isLoading: boolean; // additional external loading
  submitLabel: string;
  intentSecret?: string;
  onProcessing: (state: boolean) => void;
  onDone: () => void;
}) => {
  const elements = useElements();
  const stripe = useStripe();
  const [message, setMessage] = useState<string | null | undefined>(null);
  const [didSubmit, setDidSubmit] = useState(false);
  const [processing, setProcessing] = useState(false);

  const handleSubmit = async (event: GestureResponderEvent) => {
    event.preventDefault();

    if (!!stripe && !!elements) {
      try {
        setDidSubmit(true);
        setProcessing(true);
        const setupResult = await stripe?.confirmSetup({
          elements,
          redirect: "if_required",
          confirmParams: {
            payment_method_data: { billing_details: { address: { country: "US" } } },
          },
        });
        if (!setupResult.error) {
          await FrontRpc.call("stripeFinish", { setupIntentId: setupResult.setupIntent.id });
          props.onDone();
        } else {
          console.error(setupResult.error.message);
          setMessage(setupResult.error.message);
          setProcessing(false);
          props.onProcessing(false);
        }
      } catch (error) {
        console.error(error);
        setMessage("There was an issue confirming the card.");
        setProcessing(false);
        props.onProcessing(false);
      }
    }
  };

  return (
    <Box nativeID="card_box">
      <PaymentElement
        options={{
          fields: { billingDetails: { address: { country: "never" } } },
          // don't do pay buttons here. UI texts not well suites for a payment setup
          wallets: { applePay: "never", googlePay: "never" },
        }}
      />
      {message ? <Text color="errorMessage">{message}</Text> : null}
      <PrimaryButton
        mt={4}
        isDisabled={props.isDisabled}
        isLoading={processing || (didSubmit && props.isLoading)}
        onPress={handleSubmit}
        label={props.submitLabel}
      />
    </Box>
  );
};
