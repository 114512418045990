import useAsyncFn from "react-use/lib/useAsyncFn";
import { FrontRpc } from "../../../lib/functions/rpc";
import { overlayCancel, overlayWrite } from "../../../lib/firestore/overlay";
import { useCustomer } from "../../../model/useCustomer";

export function useReturn() {
  const { balance } = useCustomer();

  return useAsyncFn(
    async (
      pointId: string,
      qty: number,
      ignoreRecentMs: number,
      containerIds?: string[],
      reasonCode?: string
    ) => {
      try {
        // pre-calculate the new outstanding balance
        let returnCount = qty;
        if (containerIds && containerIds.length > qty) returnCount = containerIds.length;
        let newOutstanding = (balance.outstandingSum ?? 0) - returnCount;
        if (newOutstanding < 0) newOutstanding = 0;

        // optimistically update the balance shown in the app
        overlayWrite(balance, { outstandingSum: newOutstanding });

        // do the actual return
        const response = await FrontRpc.call("selfReturn", {
          pointId,
          qty,
          ignoreRecentMs,
          containerIds,
          reasonCode,
        });

        // there are some edge cases where we don't get a error, but nothing was returned.
        if (!response.success || !response.transaction) {
          throw new Error("No return.");
        }

        // done
        return response;
      } catch (error) {
        // in case of an error, remove the temp overwrite
        overlayCancel(balance);
        throw error;
      }
    }
  );
}
