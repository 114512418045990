import React from "react";
import { authSignOut } from "../../../lib/auth/authState";
import { useCloseScreen } from "../../../components/navigation/useCloseScreen";
import { PaymentBody } from "../../main/profile/payment/PaymentBody";
import { Screen } from "../../../components/layout/Screen";
import { PaymentSetupScreenProps } from "./paymentSetupStack";

export const PaymentSetupScreen = ({
  navigation,
  route,
}: PaymentSetupScreenProps<"PaymentSetup">) => {
  const closeScreen = useCloseScreen();

  return (
    <Screen
      name="Setup Payment"
      onBack={async () => {
        // sign-out will swap out the PaymentScreen and bring the user back to the Welcome screen
        await authSignOut();
        closeScreen();
      }}
    >
      <PaymentBody
        setup={true}
        submitLabel="Sign Up"
        onDone={
          () => {} /* no need to to anything, the app naviagtion will switch to application */
        }
      />
    </Screen>
  );
};

export default PaymentSetupScreen;
