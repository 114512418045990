import { firebaseApp } from "../firebase/fbenv";
import "firebase/compat/remote-config";

export function firebaseRemoteConfig() {
  const remoteConfig = firebaseApp().remoteConfig();

  return {
    async fetchAndActivate(): Promise<boolean> {
      return remoteConfig.fetchAndActivate();
    },

    getAll: () => {
      return remoteConfig.getAll();
    },

    setDefaults(defaults: Record<string, string | number | boolean>) {
      remoteConfig.defaultConfig = { ...defaults };
    },

    setConfigSettings(config: { minimumFetchIntervalMillis: number; fetchTimeoutMillis: number }) {
      remoteConfig.settings.minimumFetchIntervalMillis = config.minimumFetchIntervalMillis;
      remoteConfig.settings.fetchTimeoutMillis = config.fetchTimeoutMillis;
    },
  };
}
