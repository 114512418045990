import { useEffect } from "react";
import { ONE_SEC } from "../util/constants";
import useError from "react-use/lib/useError";
import useTimeoutFn from "react-use/lib/useTimeoutFn";

export const DEFAULT_WAIT_TIMEOUT = 10 * ONE_SEC;

export function usePendingWatchDog(hardPending: boolean, softPending: boolean, msg: string = "") {
  const dispatchError = useError();

  const [_isReady, cancelTimeout, _resetTimeout] = useTimeoutFn(() => {
    if (hardPending) {
      console.log("🍎 WatchDog timeout", msg);
      dispatchError(new Error("Waiting too long. " + msg));
    }
  }, DEFAULT_WAIT_TIMEOUT);

  useEffect(() => {
    if (!hardPending && !softPending) cancelTimeout();
  }, [hardPending, softPending]);
}
