import { ONE_DAY } from "./constants";

// Date(1970, 0, 1) sets time portion in local time. this is intentionally, so
// reference point is in the local timezone.
const REF_TIME = new Date(1970, 0, 1).getTime();

// days since the reference time
function days(date: Date): number {
  return Math.trunc((date.getTime() - REF_TIME) / ONE_DAY);
}

// days until the provided future date is reached
export function daysUntil(date: Date, now: Date = new Date()): number {
  return days(date) - days(now);
}

// days since provided date, which is in the past
export function daysSince(date: Date, now: Date = new Date()): number {
  return days(now) - days(date);
}

export function dateSince(date: Date, deltaMs: number): Date {
  return new Date(date.getTime() - deltaMs);
}

export function dateSinceDays(date: Date, days: number): Date {
  return new Date(date.getTime() - days * ONE_DAY);
}

export function dateBeforeNow(deltaMs: number) {
  return new Date(Date.now() - deltaMs);
}

export function dateBefore(date: Date, deltaMs: number) {
  return new Date(date.getTime() - deltaMs);
}

export function dateAfter(date: Date, deltaMs: number) {
  return new Date(date.getTime() + deltaMs);
}

export function isBefore(date1: Date, date2: Date) {
  return date1.getTime() < date2.getTime();
}

export function isAfter(date1: Date, date2: Date) {
  return date1.getTime() > date2.getTime();
}

export function isMaxAge(date: Date | null | undefined, deltaMs: number): boolean {
  if (date) {
    return date.getTime() > Date.now() - deltaMs;
  }
  return false;
}
