import { Text } from "native-base";
import React from "react";
import { HFlex } from "./HFlex";
import { TextIndentSection } from "./TextIndentSection";

export const SectionTitle: React.FunctionComponent<{
  title: string;
  json?: {
    name?: string;
    data: any;
  };
}> = (props) => {
  return (
    <TextIndentSection my={0} mb={-1} mt={3} py={0}>
      <HFlex justifyContent="space-between" alignItems="flex-end">
        <Text color="gray.500" fontSize="md" fontWeight="400">
          {props.title}
        </Text>
      </HFlex>
    </TextIndentSection>
  );
};
