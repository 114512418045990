import { useAuthCustomer } from "../../../model/useCustomer";
import { WaitScreen } from "../../../components/layout/WaitScreen";

// just waits until the customer data is loaded
export const CustomerProvider = (props: {
  children?: JSX.Element | JSX.Element[] | string | any;
}) => {
  const customerState = useAuthCustomer();

  if (customerState.isPending || customerState.isLoading)
    return <WaitScreen name="Wait for Customer" />;
  return props.children;
};
