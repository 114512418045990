import { create } from "zustand";

// helper to trigger some simulations for testing
export interface TestState {
  sampleReturnInfo: number;
  setSampleReturnInfo: (value: number) => void;

  sampleCompleteModal: boolean;
  setSampleCompleteModal: () => void;

  reset: () => void;
}

export const useTestState = create<TestState>((set, get) => {
  return {
    sampleReturnInfo: 0,
    setSampleReturnInfo: (value: number) => set({ sampleReturnInfo: value }),

    sampleCompleteModal: false,
    setSampleCompleteModal: () => set({ sampleCompleteModal: true }),

    reset: () => set({ sampleReturnInfo: 0, sampleCompleteModal: false }),
  };
});
