import { ONE_SEC } from "../util/constants";

export type FeatureFlagsType = {
  //
  // ----  version handling  ----
  //

  /* from which version on to be show a upgrade warning */
  warnAppVersion: string;

  /* overwrite the message for a version warning */
  warnAppVersionMessage: string;

  /* from which version on to we require an update */
  minAppVersion: string;

  /* overwrite default message shown */
  minAppVersionMessage: string;

  //
  // ----  settings  ----
  //

  /* weather online ordering is enabled */
  onlineOrder: boolean;

  /* after which time the fallback message is shown when scanning containers */
  returnFallback: number;

  /* profiles created after that daterequire a phone number. YYYY-MM-DD string. */
  requirePhoneDate: string;

  /* ask profiles created after that date to add a phone number. YYYY-MM-DD string. */
  askPhoneDate: string;

  // NOTE: askPhoneDate <= requirePhoneDate

  //
  // ----  test flags  ----
  //

  /* just a test message for checking the feature flags */
  testMsg: string;

  /* just a test message for to check beta users are working */
  betaMsg: string;
};

export type FeatureFlagNames = keyof FeatureFlagsType;

export const DEFAULT_FEATURE_FLAGS: FeatureFlagsType = {
  warnAppVersion: "1.1.0",
  warnAppVersionMessage: "",
  minAppVersion: "1.1.0",
  minAppVersionMessage: "",

  onlineOrder: true,
  returnFallback: 5 * ONE_SEC,
  requirePhoneDate: "2024-03-31",
  askPhoneDate: "2024-03-31",

  testMsg: "no message set",
  betaMsg: "no beta user",
} as const;
