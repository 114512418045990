import { useCallback, useState } from "react";
import { ONE_SEC } from "../util/constants";
import useTimeoutFn from "react-use/lib/useTimeoutFn";

export function useAutoClearState<T>(
  initial: T,
  timeout: number = 3 * ONE_SEC
): [T | null, (value: T) => void] {
  const [stateValue, stateSet] = useState<T | null>(initial);

  const [_, __, resetTimer] = useTimeoutFn(() => {
    stateSet(null);
  }, timeout);

  const set = useCallback(
    (value: T) => {
      stateSet(value);
      resetTimer();
    },
    [stateSet, resetTimer]
  );

  return [stateValue, set];
}
