import * as FireAnalytics from "firebase/analytics";
import { AppAnalytics, AppAnalyticsInterface } from "./analyticsTypes";
import { getApiVersion, getAppVersion } from "../expo/appConfig";
import { firebaseApp } from "../firebase/fbenv";
import _once from "lodash/once";

const fireAnalytics = _once(() => {
  // try/catch, so an issue with analytics won't fail the whole app
  try {
    const fa = FireAnalytics.getAnalytics(firebaseApp());
    console.log("ANALYTICS INITIALIZED");

    // set the app version and api version as additional event parameters
    FireAnalytics.setDefaultEventParameters({
      rcrl_api_version: getApiVersion(),
      rcrl_app_version: getAppVersion(),
    });
    return fa;
  } catch (error) {
    console.error("error while initializing analytics", error);
    return null;
  }
});

export const appAnalytics: AppAnalytics = (): AppAnalyticsInterface => {
  const fa = fireAnalytics();
  if (fa) {
    return {
      setUserId(userId: string) {
        FireAnalytics.setUserId(fa, userId);
        if (!userId) {
          FireAnalytics.setUserProperties(fa, {
            is_admin: null,
            is_partner: null,
            is_beta: null,
          });
        }
      },
      setUserRoles(roles: { isAdmin: boolean; isPartner: boolean; isBeta: boolean }) {
        const props: Record<string, any> = {};
        props.is_admin = roles.isAdmin || null; // ... || null remove the property if GA
        props.is_partner = roles.isPartner || null;
        props.is_beta = roles.isBeta || null;
        FireAnalytics.setUserProperties(fa, props);
      },
      trackScreen(screenName: string, screenParams?: Record<string, any>) {
        FireAnalytics.logEvent(fa, "screen_view", {
          screen_name: screenName,
          firebase_screen: screenName,
          firebase_screen_class: "web",
        });

        const eventParams: Record<string, string> = {};
        if (screenParams?.pointId) eventParams.point_id = String(screenParams.pointId);
        if (screenParams?.locationId) eventParams.location_id = String(screenParams.locationId);
        FireAnalytics.logEvent(fa, `rcrl_screen_${screenName}`, eventParams);
      },
      eventSignInProvider(providerId?: string) {
        FireAnalytics.logEvent(fa, "rcrl_sign_in_provider", {
          provider_id: providerId,
        });
      },
      eventSignIn() {
        FireAnalytics.logEvent(fa, "rcrl_sign_in");
      },
      eventSignUp(providerId?: string) {
        FireAnalytics.logEvent(fa, "rcrl_sign_up", { provider_id: providerId });
      },
      eventAppLink(path: string) {
        /* we don't track app links, because every click is seen as an app link */
      },
      eventBorrow(qty?: number) {
        FireAnalytics.logEvent(fa, "rcrl_borrow", { qty });
      },
      eventReturn(qty?: number) {
        FireAnalytics.logEvent(fa, "rcrl_return", { qty });
      },
    };
  } else {
    // some issue getting analytics going --> return dummy implementation
    return {
      setUserId(userId: string) {},
      setUserRoles(roles: { isAdmin: boolean; isPartner: boolean; isBeta: boolean }) {},
      trackScreen(name: string, params?: Record<string, any>) {},
      eventSignInProvider(providerId?: string) {},
      eventSignIn() {},
      eventSignUp(providerId?: string) {},
      eventAppLink(path: string) {},
      eventBorrow(qty?: number) {},
      eventReturn(qty?: number) {},
    };
  }
};
