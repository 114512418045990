import React, { useState } from "react";
import { Divider, Input, KeyboardAvoidingView } from "native-base";
import useAsyncFn from "react-use/lib/useAsyncFn";
import { firebaseAuth } from "../../../lib/firebase/fbenv";
import { BoxSection, Section } from "../../../components/layout/Sections";
import { PrimaryButton } from "../../../components/primitive/PrimaryButton";
import { fixPhone } from "../../../util/phone";
import { SecondaryButton } from "../../../components/primitive/SecondaryButton";
import { Screen } from "../../../components/layout/Screen";
import { HeroSection } from "../../../components/layout/HeroSection";
import { TCSection } from "../../../components/footer/TCSection";
import { useAuthCustomer } from "../../../model/useCustomer";
import { SectionMsg } from "../../../components/layout/SectionMsg";
import { PreambleScreenProps } from "../preambleStack";
import { Platform } from "react-native";
import { FrontRpc } from "../../../lib/functions/rpc";

export const PhoneSignInScreen = ({ navigation, route }: PreambleScreenProps<"PhoneSignIn">) => {
  const [phone, setPhone] = useState("");
  const [code, setCode] = useState("");
  const [msg, setMsg] = useState<string | null>(null);
  const loadingCustomer = useAuthCustomer((s) => s.isLoading);
  const [codeSent, setCodeSent] = useState<boolean>(false);

  // signIn with phone number. next step is confirmation code
  const [sendCodeState, doSendCode] = useAsyncFn(async (phn: string) => {
    try {
      phn = fixPhone(phn);
      const result = await FrontRpc.call("phoneSignIn", { phone: phn });

      if (result.success) {
        setCodeSent(result.success);
        setMsg(null);
      } else {
        console.error("error when preparing phone number for sign-in", result.msg);
        setMsg(result.msg ?? "Somethign went wrong");
      }
    } catch (error) {
      console.error("error when calling phoneSignIn function to send code", error);
      setMsg((error as any).message ?? "Something went wrong");
    }
  });

  // confirm text msg code
  const [verifyCodeState, doVerifyCode] = useAsyncFn(async (phn: string, code: string) => {
    try {
      phn = fixPhone(phn);
      const result = await FrontRpc.call("phoneSignIn", { phone: phn, code });
      if (result.success && result.token) {
        await firebaseAuth().signInWithCustomToken(result.token);
        setMsg(null);
        // nothing further to do, the MainNavigation will swap to a different stack of screens
      } else {
        console.error("error when verifying phone number for sign-in", result.msg);
        setMsg(result.msg ?? "Something went wrong");
      }
    } catch (error) {
      console.error("error when calling phoneSignIn function to verify code", error);
      setMsg((error as any).message ?? "Something went wrong");
    }
  });

  function handlePhoneChange(text: string) {
    setPhone(text);
  }

  function handleCodeChange(text: string) {
    setCode(text);
  }

  function handlePrepare(event: any) {
    doSendCode(phone);
  }

  function handleVerifyCode(event: any) {
    doVerifyCode(phone, code);
  }

  function renderInputBlock() {
    if (!codeSent) {
      return (
        <>
          <BoxSection divider={<Divider size="1px" w="100%" />}>
            <Input
              nativeID="phone"
              keyboardType="phone-pad"
              placeholder="Enter Phone Number"
              autoComplete="tel"
              value={phone}
              onChangeText={handlePhoneChange}
            />
          </BoxSection>
          <SectionMsg text={msg} type="error" />

          <Section>
            <PrimaryButton
              my={1}
              label={"Send Pass Code"}
              isDisabled={phone?.length < 10 || sendCodeState.loading}
              isLoading={sendCodeState.loading}
              onPress={handlePrepare}
            />
          </Section>
        </>
      );
    } else {
      return (
        <>
          <BoxSection divider={<Divider size="1px" w="100%" />}>
            <Input
              nativeID="phone"
              keyboardType="phone-pad"
              placeholder="Phone Number"
              autoComplete="tel"
              isDisabled={true}
              value={phone}
            />
            <Input
              nativeID="code"
              keyboardType="number-pad"
              placeholder="Enter Code from Text Message"
              // iOS shows the auto complete for a text message code only with "one-time-code"
              autoComplete={Platform.OS === "ios" ? ("one-time-code" as any) : "sms-otp"}
              value={code}
              onChangeText={handleCodeChange}
            />
          </BoxSection>
          <SectionMsg text={msg} type="error" />

          <Section>
            <PrimaryButton
              my={1}
              label={"Continue with Code"}
              isDisabled={!code?.length || verifyCodeState.loading || loadingCustomer}
              isLoading={verifyCodeState.loading}
              onPress={handleVerifyCode}
            />
            <SecondaryButton
              my={1}
              label={"Resend Pass Code"}
              isDisabled={phone?.length < 10 || sendCodeState.loading}
              onPress={handlePrepare}
            />
          </Section>
        </>
      );
    }
  }

  return (
    <Screen name="Phone Sign In">
      <KeyboardAvoidingView behavior="position" keyboardVerticalOffset={0}>
        <HeroSection image={require("assets/illustrations/Welcome.svg")} />
        {renderInputBlock()}
        <TCSection />
      </KeyboardAvoidingView>
    </Screen>
  );
};
