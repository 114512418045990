import { Box } from "native-base";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { SafeAreaView } from "react-native-safe-area-context";
import WebView from "react-native-webview";
import { getEmulatorHostName } from "../../../lib/firebase/fbenv";
import { Header } from "../../../components/navigation/Header";
import { useCloseScreen } from "../../../components/navigation/useCloseScreen";
import { appConfigExtra, getProjectId } from "../../../lib/expo/appConfig";
import { isEmulator } from "../../../lib/firebase/ifEnv";
import { WebContainer } from "../../../components/webview/WebContainer";
import { retrieveAuthToken } from "../../../lib/auth/authState";
import { FrontAppScreenProps } from "../../../frontAppStack";

export const BackOfficeScreen = ({ navigation, route }: FrontAppScreenProps<"BackOffice">) => {
  const closeScreen = useCloseScreen();
  const [token, setToken] = useState<string | null | undefined>();
  const webRef = useRef<WebView>(null);
  const [canGoBack, setCanGoBack] = useState(false);

  useEffect(() => {
    // get an auth token, which we can pass into the web app
    retrieveAuthToken()
      .then((tokenResult) => {
        if (tokenResult) {
          setToken(tokenResult);
        } else {
          closeScreen();
        }
      })
      .catch(() => {
        closeScreen();
      });
  }, []);

  // a back press either navigates the browser back, or if not possible brings the
  // user back into the main app.
  const handleBack = useCallback(() => {
    if (canGoBack) {
      webRef.current?.goBack();
    } else {
      closeScreen();
    }
  }, [canGoBack]);

  // a long press brinds the user back in the main app
  const handleLongBack = useCallback(() => {
    closeScreen();
  }, []);

  // we watch the navigation and determine whether "back" is possible
  const handleCanGoBack = useCallback((canGoBack: boolean) => {
    console.log("🍏 WEBVIEW can go back:", canGoBack);
    setCanGoBack(canGoBack);
  }, []);

  return (
    <SafeAreaView
      edges={["top"]}
      style={{
        backgroundColor: "white",
      }}
    >
      <Box
        w="100%"
        h="100%"
        alignSelf="center"
        bg="screenBackground"
        overflow="hidden"
        _web={{ h: "100vh" }}
      >
        <Header
          title={"Recirclable"}
          backMode="back"
          onBack={handleBack}
          onLongBack={handleLongBack}
          label={route.params.label}
        />
        {token ? (
          <WebContainer
            ref={webRef}
            origin={backendOrigin()}
            path={route.params.path}
            title={route.params.title}
            token={token}
            onCanGoBack={handleCanGoBack}
          />
        ) : null}
      </Box>
    </SafeAreaView>
  );
};

function backendOrigin() {
  const localBackoffice = appConfigExtra().localBackoffice;
  if (isEmulator() || localBackoffice) {
    return `http://${getEmulatorHostName()}:19006`;
  } else {
    // the domain "web.app" is one of the two Firebase standard host names
    return `https://${getProjectId()}-admin.web.app`;
  }
}
