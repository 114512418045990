import React from "react";
import { AlertDialog, Button } from "native-base";
import { TwoButtonGroup } from "../primitive/TwoButtonGroup";

export const AlertYesNo: React.FunctionComponent<{
  title: string;
  msg: string | (() => JSX.Element);
  primary?: string;
  secondary?: string;
  isOpen: boolean;
  onClose?: (yesno?: boolean) => void;
}> = (props) => {
  const yesLabel = props.primary ?? "Yes";
  const noLabel = props.secondary ?? "No";

  const leastDestructiveRef = React.useRef(null);
  return (
    <AlertDialog
      leastDestructiveRef={leastDestructiveRef}
      isOpen={props.isOpen}
      onClose={() => props.onClose?.()}
      size="xl"
    >
      <AlertDialog.Content>
        <AlertDialog.CloseButton />
        <AlertDialog.Header textAlign="center" fontWeight="700">
          {props.title}
        </AlertDialog.Header>
        <AlertDialog.Body px={6} py={4}>
          {typeof props.msg === "function" ? props.msg() : props.msg}
        </AlertDialog.Body>
        <AlertDialog.Footer justifyContent="center">
          <TwoButtonGroup
            _left={{
              label: noLabel,
              onPress: () => props.onClose?.(false),
            }}
            _right={{
              label: yesLabel,
              onPress: () => props.onClose?.(true),
            }}
          />
        </AlertDialog.Footer>
      </AlertDialog.Content>
    </AlertDialog>
  );
};
