import { useFocusEffect } from "@react-navigation/native";
import { Modal, Text, useDisclose, VStack } from "native-base";
import React from "react";
import { TwoButtonGroup } from "../../../components/primitive/TwoButtonGroup";
import { useLocalUserState } from "../../../hooks/useLocalUserState";
import { AssetView } from "../../../components/primitive/AssetView";

export const ModalCompleteProfile: React.FunctionComponent<{
  missingName: boolean;
  missingEmail: boolean;
  onCompleteProfile: () => void;
  children?: JSX.Element | JSX.Element[] | string | any;
}> = (props) => {
  const { didShowCompleteProfile: showedCompleteProfile, showCompleteProfileAgain } =
    useLocalUserState();
  const { isOpen, onOpen, onClose } = useDisclose(false);

  useFocusEffect(
    React.useCallback(() => {
      if ((props.missingName || props.missingEmail) && showCompleteProfileAgain()) {
        onOpen();
        showedCompleteProfile();
      }
    }, [props.missingEmail, props.missingName])
  );

  if (isOpen) {
    return (
      <Modal isOpen={isOpen} onClose={onClose} size={"xl"} _backdrop={{ opacity: 0.4 }}>
        <Modal.Content marginBottom="auto" marginTop={70}>
          <Modal.Body p={4}>
            <VStack alignItems="center">
              <AssetView
                source={require("assets/illustrations/Profile-Picture.svg")}
                alt="Reminder"
                w="160px"
                h="160px"
              />
              <Text my={3} fontSize="lg" fontWeight="700" textAlign="center">
                Complete your Profile?
              </Text>
              <Text mb={5} fontSize="md" fontWeight="400" textAlign="center">
                {props.missingName ? (
                  <Text>
                    Tell us your <Text fontWeight={700}>name</Text>, so we can greet you everytime
                    you vist the app.
                  </Text>
                ) : null}
                {props.missingEmail ? (
                  <Text>
                    {" "}
                    Add your <Text fontWeight={700}>email address</Text> to receive notifications
                    via email and hear about news from Recirclable.
                  </Text>
                ) : null}
              </Text>
              <TwoButtonGroup
                _left={{
                  label: "Later",
                  onPress: onClose,
                }}
                _right={{
                  label: "Let's do it!",
                  onPress: () => {
                    onClose();
                    props.onCompleteProfile();
                  },
                }}
              />
            </VStack>
          </Modal.Body>
        </Modal.Content>
      </Modal>
    );
  } else {
    // note: multiple modals are "chained" as children. after one was shown, the next can be shown.
    return <>{props.children}</>;
  }
};
