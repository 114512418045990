import _omit from "lodash/omit";
import { Box, IBoxProps, Text } from "native-base";
import React from "react";
import { ImageSourcePropType, Pressable } from "react-native";
import { AssetView } from "./AssetView";
import { HFlex } from "../layout/HFlex";
import { BoxSection } from "../layout/Sections";
import { LargeStat } from "./Stats";

export const CountSection: React.FunctionComponent<
  {
    count: string;
    title: string;
    imageSource?: ImageSourcePropType;
    imageTitle?: string;
    message?: string;
    onPress?: () => void;
  } & IBoxProps
> = (props) => {
  const extraBoxProps = _omit(props, [
    "title",
    "imageSource",
    "imageTitle",
    "count",
    "message",
    "onPress",
  ]);
  return (
    <BoxSection
      bgImageSource={require("assets/images/hero-background.svg")}
      shadow={99}
      {...extraBoxProps}
    >
      <Pressable onPress={props.onPress}>
        <HFlex px={2} py={2} justifyContent="space-between">
          <Box mt={8}>
            <LargeStat count={props.count} title={props.title} />
          </Box>
          <AssetView
            source={props.imageSource ?? require("assets/illustrations/Salad.svg")}
            alt={props.imageTitle ?? "Illustration of a bowl"}
            w={160}
            h={160}
          />
        </HFlex>
      </Pressable>
      <Text position="absolute" left={4} bottom={2} fontSize="md" fontWeight={600}>
        {props.message}
      </Text>
    </BoxSection>
  );
};
