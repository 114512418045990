import { IBoxProps } from "native-base";
import React from "react";
import { VFlex } from "./VFlex";

export const TextIndentSection: React.FunctionComponent<{} & IBoxProps> = (props) => {
  return (
    <VFlex position="relative" px={6} my={2} w="100%" {...props}>
      {props.children}
    </VFlex>
  );
};
