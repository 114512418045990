import _isFinite from "lodash/isFinite";
import firebase from "firebase/compat/app";
import { VProfileData } from "../../../backend-types/src";
import { TypedDoc } from "../lib/firestore/TypedDoc";
import { Repo } from "../lib/firestore/fstore";
import { useRemoteFlags } from "../lib/remote_flags/useRemoteFlags";

export class VProfileDoc extends TypedDoc implements VProfileData {
  accountId!: string;
  status!: VProfileData["status"];
  signUpAt?: Date;
  orderCode!: string;
  name!: string;
  email!: string;
  emailVerified!: boolean;
  phone!: string;
  phoneVerified!: boolean;
  notification!: VProfileData["notification"];
  paymentStatus!: VProfileData["paymentStatus"];
  paymentInfo?: string;
  plan!: VProfileData["plan"];

  constructor(snapshot: firebase.firestore.DocumentSnapshot) {
    super(snapshot);
  }

  getAppAccountId() {
    // TEMP FIX: until the database is updated
    if (!this.accountId) return this.id;
    return this.accountId;
  }

  // unverified phone number?
  needsPhoneVerification() {
    return !!this.phone && !this.phoneVerified;
  }

  // adding a phone number required?
  requiresPhoneSetup() {
    if (this.phone) return false;

    const requirePhoneDateFlag = useRemoteFlags.getState().requirePhoneDate;
    let requirePhoneTime = new Date(requirePhoneDateFlag).getTime();
    requirePhoneTime = _isFinite(requirePhoneTime) ? requirePhoneTime : Number.POSITIVE_INFINITY;

    const signUpAt = this.signUpAt?.getTime() || this.createdAt?.getTime();
    return signUpAt && signUpAt > requirePhoneTime;
  }

  // ask to add a phone number?
  askAddPhone() {
    if (this.phone) return false;

    const addPhoneDateFlag = useRemoteFlags.getState().askPhoneDate;
    let addPhoneTime = new Date(addPhoneDateFlag).getTime();
    addPhoneTime = _isFinite(addPhoneTime) ? addPhoneTime : Number.POSITIVE_INFINITY;

    return this.signUpAt && this.signUpAt.getTime() > addPhoneTime;
  }

  hasPayment() {
    return !this.requiresPaymentSetup();
  }

  requiresPaymentSetup() {
    return !["active", "test"].includes(this.paymentStatus);
  }
}

export const VProfiles = new (class VProfiles extends Repo<VProfileDoc> {
  constructor() {
    super("v_profiles", VProfileDoc);
  }
})();
