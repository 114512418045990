import { AlertDialog, Text } from "native-base";
import { useCallback, useMemo, useRef, useState } from "react";
import { TwoButtonGroup } from "../primitive/TwoButtonGroup";

export type OnCloseCallback<R> = (yesno: boolean | null | undefined, result: R | null) => void;

export type ModalState<R> = {
  isOpen: boolean;
  resultRequired: boolean;
  value: R | null;
  onClose: (yesno?: boolean | null) => void;
  onChange: (value: R) => void;
};

export function useModal<R>(
  callback: OnCloseCallback<R>,
  resultRequired: boolean = false
): [ModalState<R>, () => void] {
  const [result, setResult] = useState<R | null>(null);
  const [isOpen, setOpen] = useState(false);

  const handleOpen = useCallback(() => {
    setResult(null);
    setOpen(true);
  }, [setOpen]);

  const handleClose = useCallback(
    (yesno?: boolean | null) => {
      setOpen(false);
      callback(yesno, result);
    },
    [setOpen, callback]
  );

  const handleChange = useCallback(
    (value: R) => {
      setResult(value);
    },
    [setOpen]
  );

  const state = useMemo(() => {
    return {
      isOpen,
      resultRequired,
      value: result,
      onClose: handleClose,
      onChange: handleChange,
    };
  }, [isOpen, result, handleClose, handleChange, resultRequired]);

  return [state, handleOpen];
}

export type ModalBodyProps<R> = {
  value: R | null;
  onChange: (value: R) => void;
};

export function AlertModal<R>(props: {
  modalState: ModalState<R>;
  title: string;
  text?: string;
  yesLabel?: string;
  noLabel?: string;
  extraBody?: (props: ModalBodyProps<R>) => void;
}) {
  const leastDestructiveRef = useRef(null);

  return (
    <AlertDialog
      leastDestructiveRef={leastDestructiveRef}
      isOpen={props.modalState.isOpen}
      onClose={props.modalState.onClose}
      size="xl"
    >
      <AlertDialog.Content>
        <AlertDialog.CloseButton />
        <AlertDialog.Header textAlign="center" fontWeight="700">
          {props.title}
        </AlertDialog.Header>
        <AlertDialog.Body px={6} py={4}>
          <Text my={2}>Please describe the issue:</Text>
          {props.extraBody?.({
            value: props.modalState.value,
            onChange: props.modalState.onChange,
          })}
        </AlertDialog.Body>
        <AlertDialog.Footer justifyContent="center">
          <TwoButtonGroup
            _left={{
              label: props.noLabel ?? "No",
              onPress: () => props.modalState.onClose?.(false),
            }}
            _right={{
              isDisabled: props.modalState.resultRequired && !props.modalState.value,
              label: props.yesLabel ?? "Yes",
              onPress: () => props.modalState.onClose?.(true),
            }}
          />
        </AlertDialog.Footer>
      </AlertDialog.Content>
    </AlertDialog>
  );
}
