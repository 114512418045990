import { Box, Center, Text } from "native-base";
import React from "react";
import { ScreenTitle } from "../layout/ScreenTitle";
import { HEADER_HEIGHT_PX } from "../navigation/Header";
import { ActivitySpinner } from "../primitive/ActivitySpinner";

export type WebWaitOverlayProps = {
  title?: string;
  label?: string;
};

export const WebWaitOverlay = (props: WebWaitOverlayProps) => {
  return (
    <Box
      position="absolute"
      top={HEADER_HEIGHT_PX}
      left={0}
      flex={1}
      w="100%"
      h="100%"
      bg="screenBackground"
    >
      {props.title ? (
        <ScreenTitle title={props.title} />
      ) : (
        <Center w="100%" h="100%">
          <Box>
            <ActivitySpinner size={"sm"} />
            {props.label ? (
              <Text textAlign={"center"} color="primaryText" my={2}>
                {props.label}
              </Text>
            ) : null}
          </Box>
        </Center>
      )}
    </Box>
  );
};
