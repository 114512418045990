import { BackOfficeScreen } from "./features/main/home/BackOfficeScreen";
import { createNavDef, ScreenDefinitions } from "./components/navigation/NavigationDefinitions";
import { ActivityScreen } from "./features/main/activity/ActivityScreen";
import { DeleteAccountScreen } from "./features/main/profile/DeleteAccountScreen";
import { LinkTransactionScreen } from "./features/main/checkout/LinkTransactionScreen";
import { PaymentChangeScreen } from "./features/main/profile/PaymentChangeScreen";
import { PointScanScreen } from "./features/main/checkout/PointScanScreen";
import { PointLinkScreen } from "./features/main/checkout/PointLinkScreen";
import { ProfileScreen } from "./features/main/profile/ProfileScreen";
import { ShowReceiptScreen } from "./features/main/activity/ShowReceiptScreen";
import { HomeScreen } from "./features/main/home/HomeScreen";
import { MainTabs } from "./features/main/home/MainTabs";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { Platform } from "react-native";
import { ItemInfoScreen } from "./features/main/item/ItemInfoScreen";
import { OnlineOrderScreen } from "./features/main/ordering/OnlineOrderScreen";
import { RpcCheckoutTransactionType } from "../../backend-types/src";
import { PointErrorScreen } from "./features/main/checkout/PointErrorScreen";
import { SelfReturnScreen } from "./features/main/checkout/SelfReturnScreen";
import { SelfBorrowScreen } from "./features/main/checkout/SelfBorrowScreen";
import { POSCheckOutScreen } from "./features/main/checkout/POSCheckOutScreen";
import { SelfReturnScanScreen } from "./features/main/checkout/SelfReturnScanScreen";
import { ProfileChangePhoneScreen } from "./features/main/profile/ProfileChangePhoneScreen";
import { ProfileChangeEmailScreen } from "./features/main/profile/ProfileChangeEmailScreen";

// parameter for all point action screens
export type PointScreenParams = {
  pointId: string;
  locationId: string;
};

export type FrontAppScreenParamList = {
  Home: undefined;
  Activity: undefined;
  PaymentChange: undefined;

  Scan: undefined;

  Point: { pointId?: string };
  PointBorrow: PointScreenParams;
  PointReturn: PointScreenParams & { containerIds?: string; reasonCode?: string };
  PointPOS: PointScreenParams;
  PointReturnWScan: PointScreenParams;
  PointError: undefined;

  Profile: undefined;
  ProfileChangePhone: undefined;
  ProfileChangeEmail: undefined;
  DeleteAccount: undefined;
  LinkTransaction: { prefix: string; txnId: string };
  ShowReceipt: {
    transactionId?: string;
    transaction?: RpcCheckoutTransactionType;
  };
  ItemInfo: undefined;
  BackOffice: { path: string; label?: string; title?: string };
  OnlineOrder: { locationId: string };
};

export type FrontAppScreenProps<Screen extends keyof FrontAppScreenParamList> =
  NativeStackScreenProps<FrontAppScreenParamList, Screen>;

const frontAppScreenDefs: ScreenDefinitions<keyof Omit<FrontAppScreenParamList, "Home">> = {
  Profile: {
    component: ProfileScreen,
    title: "Profile",
  },
  ProfileChangePhone: {
    component: ProfileChangePhoneScreen,
    title: "Change Phone",
  },
  ProfileChangeEmail: {
    component: ProfileChangeEmailScreen,
    title: "Change Email",
  },
  DeleteAccount: {
    component: DeleteAccountScreen,
    title: "Delete Account",
  },
  PaymentChange: {
    component: PaymentChangeScreen,
    title: "Change Payment",
  },
  Activity: {
    component: ActivityScreen,
    title: "Recent Activity",
  },

  Scan: {
    component: PointScanScreen,
    title: "Scan QR Code",
    style: "replace", // "from_bottom",
  },

  Point: {
    component: PointLinkScreen,
    path: "a/point/:pointId",
    title: "Borrow/Return",
  },
  PointBorrow: {
    component: SelfBorrowScreen,
    path: "a/point_borrow/:pointId",
    title: "Borrow",
    style: "replace",
  },
  PointReturn: {
    component: SelfReturnScreen,
    path: "a/point_return/:pointId",
    title: "Return",
    style: "replace",
  },
  PointPOS: {
    component: POSCheckOutScreen,
    path: "a/point_checkout/:pointId",
    title: "Checkout",
    style: "replace",
  },
  PointReturnWScan: {
    component: SelfReturnScanScreen,
    path: "a/point_return_scan/:pointId",
    title: "Return /w Scan",
    style: "replace",
  },
  PointError: {
    component: PointErrorScreen,
    title: "Point Error",
  },

  LinkTransaction: {
    component: LinkTransactionScreen,
    title: "Register Order",
    path: "a/register/:prefix/:txnId",
  },
  ShowReceipt: {
    component: ShowReceiptScreen,
    title: "Receipt",
    style: "from_bottom",
  },
  ItemInfo: {
    component: ItemInfoScreen,
    title: "Item Info",
    path: "a/item/:itemId",
    style: "from_bottom",
  },
  BackOffice: {
    component: BackOfficeScreen,
    title: "BackOffice",
    path: "a/backoffice",
  },
  OnlineOrder: {
    component: OnlineOrderScreen,
    title: "Online Ordering",
    path: "a/online_order",
  },
};

const webFrontAppNavDef = createNavDef({
  postponeLinks: false,
  screens: {
    Home: {
      component: HomeScreen,
      path: "a/home",
    },
    ...frontAppScreenDefs,
  },
});

const nativeFrontAppNavDef = createNavDef({
  postponeLinks: false,
  screens: {
    Home: {
      component: MainTabs,
      path: "a/home",
    },
    ...frontAppScreenDefs,
  },
});

export const frontAppNavDef = Platform.OS === "web" ? webFrontAppNavDef : nativeFrontAppNavDef;
