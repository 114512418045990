import firebase from "firebase/compat/app";
import { ContainerData } from "../../../backend-types/src";
import { TypedDoc } from "../lib/firestore/TypedDoc";
import { ONE_MIN } from "../util/constants";
import { isProduction } from "../lib/firebase/ifEnv";
import { Repo } from "../lib/firestore/fstore";

export class ContainerDoc extends TypedDoc implements ContainerData {
  name!: string;
  format!: ContainerData["format"];
  seqNo!: number;
  productId?: string;
  locationId?: string;
  locationName?: string;
  locationAt?: Date;
  returnLockUntil?: Date;
  event?: ContainerData["event"];
  transactionId?: string;

  constructor(snapshot: firebase.firestore.DocumentSnapshot) {
    super(snapshot);
  }

  isLocked() {
    return !!this.returnLockUntil && this.returnLockUntil.getTime() > Date.now();
  }

  recentlyDelivered(locationId: string) {
    const DELIVER_DELTA_TIME = isProduction() ? 15 * ONE_MIN : ONE_MIN;
    return (
      this.event === "deliver" &&
      this.locationId === locationId &&
      this.locationAt &&
      this.locationAt.getTime() > Date.now() - DELIVER_DELTA_TIME
    );
  }
}

export const Containers = new (class Containers extends Repo<ContainerDoc> {
  constructor() {
    super("containers", ContainerDoc);
  }
})();
