import { getProjectId } from "../expo/appConfig";

export function isEmulator(): boolean {
  // NOTE: 'undefined' happens for unit tests. we basically treat unit test like emulator runs.
  return getProjectId() === "recirclable-emu" || getProjectId() === undefined;
}

export function isDevelopment(): boolean {
  return getProjectId().startsWith("recirclable-dev");
}

export function isProduction(): boolean {
  return getProjectId() === "recirclable-prd";
}

export function ifProduction<T>(trueValue: T): T | undefined;
export function ifProduction<T>(trueValue: T, falseValue: T): T;
export function ifProduction<T>(trueValue: T, falseValue?: T): T | undefined {
  return isProduction() ? trueValue : falseValue;
}

export function ifNotProduction<T>(trueValue: T): T | undefined;
export function ifNotProduction<T>(trueValue: T, falseValue: T): T;
export function ifNotProduction<T>(trueValue: T, falseValue?: T): T | undefined {
  return !isProduction() ? trueValue : falseValue;
}

export function isProductionLike(): boolean {
  return getProjectId() === "recirclable-prd" || getProjectId() === "recirclable-stg";
}
