import { NavigationProp, useLinkTo, useNavigation } from "@react-navigation/native";
import { Actionsheet, Box, Divider, Text, useDisclose } from "native-base";
import React, { useCallback, useEffect, useState } from "react";
import { Platform } from "react-native";
import { useCustomer } from "../../../model/useCustomer";
import { useCatchingCallback } from "../../../hooks/useCatchingCallback";
import { useLocalUserState } from "../../../hooks/useLocalUserState";
import { getProjectId } from "../../../lib/expo/appConfig";
import { isEmulator, isProduction } from "../../../lib/firebase/ifEnv";
import { ONE_DAY, ONE_MIN } from "../../../util/constants";
import { wait } from "../../../util/wait";
import { useDate } from "../../../hooks/useDate";
import { useTestState } from "../../../hooks/useTestState";
import { FrontAppScreenParamList } from "../../../frontAppStack";
import useError from "react-use/lib/useError";
import { useRemoteFlags } from "../../../lib/remote_flags/useRemoteFlags";

export type DevActionSheetPropType = ReturnType<typeof useDisclose>;

export function DevActionSheet(props: DevActionSheetPropType) {
  const dispatchError = useError();
  const navigation = useNavigation<NavigationProp<FrontAppScreenParamList>>();
  const linkTo = useLinkTo();
  const dateState = useDate();
  const testState = useTestState();
  const appState = useLocalUserState();
  const { user } = useCustomer();
  const [renderError, setRenderError] = useState(false);
  const [effectError, setEffectError] = useState(false);

  // ---------  begin: error scenarios  ----------

  function simulateError() {
    props.onClose();
    dispatchError(new Error("Just dispatching an error."));
  }

  const simulateSyncError = useCatchingCallback(() => {
    throw new Error("Just testing a sync error.");
  }, []);

  const simulateAsyncError = useCatchingCallback(async () => {
    await wait(50);
    throw new Error("Just testing an async error.");
  }, []);

  const crashError = useCallback(async () => {
    wait(50).then(() => {
      throw new Error("This should crash the embedded app.");
    });
  }, []);

  const forceHttpError = useCallback(() => {
    // @ts-ignore
    // error doesn't happen on local development, because metro always serves index.html
    window.location = "/this/page/does/not/exist.html";
  }, []);

  const forceAboutBlank = useCallback(() => {
    // @ts-ignore
    window.location = "about:blank";
  }, []);

  useEffect(() => {
    if (effectError) {
      setEffectError(false);
      throw new Error("This is an effect error!");
    }
  }, [effectError]);

  // ---------  end: error scenarios  ----------

  function setFutureDate(days: number) {
    props.onClose();
    dateState.setDateOffset(days * ONE_DAY);
  }

  function simulateJustSignedUp() {
    props.onClose();
    dateState.setDateOffset(new Date(user.metadata.creationTime!).getTime() + ONE_MIN - Date.now());
  }

  function renderDevMenuItems() {
    return (
      <>
        {renderError
          ? (() => {
              throw new Error("Just a render error");
              return null;
            })()
          : null}
        <Actionsheet.Item
          py={0}
          onPress={simulateError}
          _text={{ fontWeight: "600", fontSize: "md" }}
        >
          Simulate Hook Error
        </Actionsheet.Item>

        <Actionsheet.Item
          py={0}
          onPress={() => setRenderError(true)}
          _text={{ fontWeight: "600", fontSize: "md" }}
        >
          Simulate Render Error
        </Actionsheet.Item>

        <Actionsheet.Item
          py={0}
          onPress={() => setEffectError(true)}
          _text={{ fontWeight: "600", fontSize: "md" }}
        >
          Simulate Effect Error
        </Actionsheet.Item>

        <Actionsheet.Item
          py={0}
          onPress={simulateSyncError}
          _text={{ fontWeight: "600", fontSize: "md" }}
        >
          Simulate Sync Error
        </Actionsheet.Item>

        <Actionsheet.Item py={0} onPress={crashError} _text={{ fontWeight: "600", fontSize: "md" }}>
          Crash Web App
        </Actionsheet.Item>

        <Actionsheet.Item
          py={0}
          onPress={forceHttpError}
          _text={{ fontWeight: "600", fontSize: "md" }}
        >
          Force Http Error
        </Actionsheet.Item>

        <Actionsheet.Item
          py={0}
          onPress={forceAboutBlank}
          _text={{ fontWeight: "600", fontSize: "md" }}
        >
          about:blank
        </Actionsheet.Item>

        <Actionsheet.Item
          py={0}
          onPress={simulateAsyncError}
          _text={{ fontWeight: "600", fontSize: "md" }}
        >
          Simulate Async Error
        </Actionsheet.Item>
        <Actionsheet.Item
          py={0}
          onPress={() => setFutureDate(8)}
          _text={{ fontWeight: "600", fontSize: "md" }}
        >
          Date: 8 Days in the future
        </Actionsheet.Item>
        <Actionsheet.Item
          py={0}
          onPress={() => setFutureDate(13)}
          _text={{ fontWeight: "600", fontSize: "md" }}
        >
          Date: 13 Days in the future
        </Actionsheet.Item>
        <Actionsheet.Item
          py={0}
          onPress={() => setFutureDate(14)}
          _text={{ fontWeight: "600", fontSize: "md" }}
        >
          Date: 14 Days in the future
        </Actionsheet.Item>
        <Actionsheet.Item
          py={0}
          onPress={simulateJustSignedUp}
          _text={{ fontWeight: "600", fontSize: "md" }}
        >
          Date: Just Signed Up
        </Actionsheet.Item>
        <Actionsheet.Item
          py={0}
          onPress={() => {
            props.onClose();
            testState.setSampleCompleteModal();
          }}
          _text={{ fontWeight: "600", fontSize: "md" }}
        >
          Sample Complete Profile
        </Actionsheet.Item>
        <Actionsheet.Item
          py={0}
          onPress={() => {
            props.onClose();
            appState.resetAppState();
            testState.reset();
            dateState.reset();
            useRemoteFlags.getState().refreshFeatureFlags();
          }}
          _text={{ fontWeight: "600", fontSize: "md" }}
        >
          Reset App and Test State
        </Actionsheet.Item>
        <Actionsheet.Item
          py={0}
          onPress={() => {
            props.onClose();
            testState.setSampleReturnInfo(1);
          }}
          _text={{ fontWeight: "600", fontSize: "md" }}
        >
          Sample Return Info 1
        </Actionsheet.Item>
        <Actionsheet.Item
          py={0}
          onPress={() => {
            props.onClose();
            testState.setSampleReturnInfo(2);
          }}
          _text={{ fontWeight: "600", fontSize: "md" }}
        >
          Sample Return Info 2
        </Actionsheet.Item>
        <Actionsheet.Item
          py={0}
          onPress={() => {
            props.onClose();
            navigation.navigate("Scan");
          }}
          _text={{ fontWeight: "600", fontSize: "md" }}
        >
          Scan Recirclable Code
        </Actionsheet.Item>
        <Actionsheet.Item
          py={0}
          onPress={() => {
            props.onClose();
            linkTo("/a/point/co123456789012345678");
          }}
          _text={{ fontWeight: "600", fontSize: "md" }}
        >
          POS Checkout
        </Actionsheet.Item>
        <Actionsheet.Item
          py={0}
          onPress={() => {
            props.onClose();
            linkTo("/a/point/sb123456789012345678");
          }}
          _text={{ fontWeight: "600", fontSize: "md" }}
        >
          Self Borrow
        </Actionsheet.Item>
        <Actionsheet.Item
          py={0}
          onPress={() => {
            props.onClose();
            linkTo("/a/point/sr123456789012345678");
          }}
          _text={{ fontWeight: "600", fontSize: "md" }}
        >
          Self Return
        </Actionsheet.Item>
        <Actionsheet.Item
          py={0}
          onPress={() => {
            props.onClose();
            linkTo("/a/point/ss123456789012345678");
          }}
          _text={{ fontWeight: "600", fontSize: "md" }}
        >
          Self Return /w Scan
        </Actionsheet.Item>
        {Platform.OS === "web" && isEmulator() ? (
          <Actionsheet.Item py={0} _text={{ fontWeight: "600", fontSize: "md" }}>
            --- COVERED BY EMULATOR ON WEB ---
          </Actionsheet.Item>
        ) : null}
      </>
    );
  }

  if (!isProduction()) {
    return (
      <Actionsheet isOpen={props.isOpen} onClose={props.onClose}>
        <Actionsheet.Content>
          <Box backgroundColor="accentLightMediumGreen" borderRadius={5} w="100%">
            <Text p={2} textAlign="center" fontWeight={500} fontSize="md">
              {getProjectId()}
            </Text>
          </Box>
          <Divider w="100%" />
          {renderDevMenuItems()}
        </Actionsheet.Content>
      </Actionsheet>
    );
  }
  return null;
}

export default DevActionSheet;
