import { useEffect, useState } from "react";
import { Camera, PermissionStatus } from "expo-camera";
import { isWebAndroid } from "../../util/deviceInfo";

export function useCameraPermission() {
  const [hasPermission, setHasPermission] = useState<boolean | null>(null);

  useEffect(() => {
    (async () => {
      if (isWebAndroid()) {
        // IMPORTANT: don't check permissions on web android. causes issue, at least on Android 10.
        // the permission check calls getUserMedia() and kind of 'locks' the video stream. when the
        // actual camera is opened, then getUserMedia() fails with a NotReadableError because the
        // stream is locked. asking for permission happens anyways automatically on the web. only
        // really necessary on native.
        setHasPermission(true);
      } else {
        const response = await Camera.requestCameraPermissionsAsync();
        console.log("CAMERA PERMISSION HOOK", response.status);
        setHasPermission(response.status === PermissionStatus.GRANTED);
      }
    })();
  }, []);

  return hasPermission;
}
