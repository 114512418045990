import firebase from "firebase/compat/app";
import { UPrivilegesData } from "../../../backend-types/src";
import { TypedDoc } from "../lib/firestore/TypedDoc";
import { Repo } from "../lib/firestore/fstore";

export class UPrivilegesDoc extends TypedDoc implements UPrivilegesData {
  role?: UPrivilegesData["role"];
  eroot?: string;
  beta?: boolean;

  constructor(snapshot: firebase.firestore.DocumentSnapshot) {
    super(snapshot);
  }
}

export const UPrivileges = new (class UPrivileges extends Repo<UPrivilegesDoc> {
  constructor() {
    super("u_privileges", UPrivilegesDoc);
  }
})();
