import React from "react";
import { AlertDialog, Button } from "native-base";
import { PrimaryButton } from "../primitive/PrimaryButton";

export const AlertOk: React.FunctionComponent<{
  title: string;
  msg: string | (() => JSX.Element);
  primary?: string;
  isOpen: boolean;
  onClose?: (yes?: boolean) => void;
}> = (props) => {
  const leastDestructiveRef = React.useRef(null);
  return (
    <AlertDialog
      leastDestructiveRef={leastDestructiveRef}
      isOpen={props.isOpen}
      onClose={() => props.onClose?.()}
      size="xl"
    >
      <AlertDialog.Content>
        <AlertDialog.CloseButton />
        <AlertDialog.Header textAlign="center" fontWeight="700">
          {props.title}
        </AlertDialog.Header>
        <AlertDialog.Body px={6} py={4}>
          {typeof props.msg === "function" ? props.msg() : props.msg}
        </AlertDialog.Body>
        <AlertDialog.Footer justifyContent="center">
          <PrimaryButton
            onPress={() => props.onClose?.(true)}
            label={props.primary ?? "Ok"}
            minW="30%"
          />
        </AlertDialog.Footer>
      </AlertDialog.Content>
    </AlertDialog>
  );
};
