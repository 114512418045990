import firebase from "firebase/compat/app";
import { TypedDoc } from "../lib/firestore/TypedDoc";
import { TTLCache } from "../util/ttlcache";
import { Address, VLocationData } from "../lib/apiDefs";
import { Repo, docGet } from "../lib/firestore/fstore";

export class VLocationDoc extends TypedDoc implements VLocationData {
  type!: string;
  test?: boolean;
  name!: string;
  address!: Address;
  image?: string | undefined;
  url?: string | undefined;
  external?: {
    contentfulId?: string;
  };
  phone?: string;
  onlineOrder?: VLocationData["onlineOrder"];
  onlineOrderUrl?: string;

  constructor(snapshot: firebase.firestore.DocumentSnapshot) {
    super(snapshot);
  }
}

export const VLocations = new (class VLocations extends Repo<VLocationDoc> {
  cache = new TTLCache<VLocationDoc>();

  constructor() {
    super("v_locations", VLocationDoc);
  }

  async findById(locationId: string) {
    let location: VLocationDoc | null | undefined = this.cache.get(locationId);
    if (!location) {
      location = await docGet(this.doc(locationId));
      if (location) this.cache.set(location.id, location);
    } else {
      // console.log("VLocationsRepo cache hit", locationId);
    }
    return location;
  }
})();
