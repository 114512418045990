import { useToken } from "native-base";
import { ActivityIndicator } from "react-native";

export type ActivitySpinnerProps = {
  size?: "sm" | "lg";
  color?: string;
};

export const ActivitySpinner = (props: ActivitySpinnerProps) => {
  let size: "small" | "large" = "small";
  if (props.size === "sm") size = "small";
  if (props.size === "lg") size = "large";

  const color: string = useToken("color", props.color ?? "black");

  return <ActivityIndicator size={size} color={color} focusable={false} />;
};
