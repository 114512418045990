import { Platform } from "react-native";
import { create } from "zustand";
import { useAuthState } from "../lib/auth/authState";
import { ONE_DAY } from "../util/constants";
import { localStorage } from "../util/storage";
import { useDate } from "./useDate";
import _isNumber from "lodash/isNumber";

function getLastCompleteShowKey(uid: string) {
  return `recirclable:${uid}:last_complete_modal`;
}

function getCompleteCountKey(uid: string) {
  return `recirclable:${uid}:complete_count_modal`;
}

// global app state, which captures a random app wide state
export interface LocalUserState {
  // flag, whether the payment setup was shown --> indicates a sign-up
  didShowPaymentSetup: boolean;
  showedPaymentSetup: () => void;

  // flag, whether a borrow/return happened since the app was opened
  didVisitPoint: boolean;
  visitedPoint: () => void;

  // flag, whether the special sign up welcome modal was shown
  didShowWelcome: boolean;
  showedWelcome: () => void;

  // helper to show complete profile modal not too often
  didShowCompleteProfile: () => void;
  showCompleteProfileAgain: () => boolean;

  // status flag with ttl
  setFlag(key: string, flag: boolean, ttl?: number): void;
  getFlag(key: string): boolean;

  // good for testing
  resetAppState: () => void;
}

export const useLocalUserState = create<LocalUserState>((set, get) => {
  return {
    // this right now triggers whether a special dialog is shown, when the customers
    // signs up with scanning the borrow QR code. we'll only show the dialog on web.
    didShowPaymentSetup: false,
    showedPaymentSetup: () => {
      console.log("SHOWeD PAYMENT SETUP");

      set({ didShowPaymentSetup: Platform.OS === "web" });
    },

    // TODO: remove after switching to native only
    // this is used to suppress the Welcome screen in case someone signs up when scanning
    // the borrow QR code. Not necessary for native, because there is anyways the whole
    // app downloading
    didVisitPoint: false,
    visitedPoint: () => set({ didVisitPoint: true }),

    didShowWelcome: false,
    showedWelcome: () => set({ didShowWelcome: true }),

    didShowCompleteProfile: () => {
      const uid = useAuthState.getState().user?.uid;
      if (uid) {
        localStorage.setInt(getLastCompleteShowKey(uid), useDate.getState().now().getTime());
        localStorage.inc(getCompleteCountKey(uid));
      } else {
        return false;
      }
    },
    showCompleteProfileAgain: () => {
      const uid = useAuthState.getState().user?.uid;
      if (uid) {
        // not more than 10 modal shows?
        const count = localStorage.getInt(getCompleteCountKey(uid));
        if (count < 11) {
          // last modal shown more than a day ago?
          const last = localStorage.getInt(getLastCompleteShowKey(uid));
          if (last < useDate.getState().now().getTime() - ONE_DAY) {
            return true;
          }
        }
      }
      return false;
    },

    setFlag(key: string, flag: boolean, ttl?: number) {
      const uid = useAuthState.getState().user?.uid;
      if (uid) {
        const storageKey = `recirclable:${uid}:${key}`;
        const storageValue = JSON.stringify(ttl ? [flag, Date.now() + ttl] : [flag]);
        // console.log("SET FLAG", storageKey, storageValue);
        localStorage.set(storageKey, storageValue);
      }
    },

    getFlag(key: string) {
      const uid = useAuthState.getState().user?.uid;
      if (uid) {
        const storageKey = `recirclable:${uid}:${key}`;
        const storageValue = localStorage.get(storageKey);
        // console.log("GET FLAG", storageKey, storageValue);
        if (storageValue) {
          try {
            const parsed = JSON.parse(storageValue);
            if (Array.isArray(parsed)) {
              const flag = !!parsed?.[0];
              if (parsed.length === 1) return flag;
              const expiresAt = parsed?.[1];
              if (_isNumber(expiresAt) && expiresAt > Date.now()) {
                return flag;
              }
            }
          } catch {
            /* do nothing */
          }
        }
      }
      return false;
    },

    resetAppState: () => {
      // old inidividual flags
      const uid = useAuthState.getState().user?.uid;
      if (uid) {
        set({
          didVisitPoint: false,
          didShowWelcome: false,
        });
        localStorage.remove(getCompleteCountKey(uid));
        localStorage.remove(getLastCompleteShowKey(uid));
      }

      // new flags with ttl
      localStorage.clear(`recirclable:${uid}`);
    },
  };
});
