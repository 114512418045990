import React, { useCallback } from "react";
import { Text, Heading } from "native-base";
import { useCloseScreen } from "../../../components/navigation/useCloseScreen";
import { TwoButtonGroup } from "../../../components/primitive/TwoButtonGroup";
import { BoxSection, Section } from "../../../components/layout/Sections";
import { authSignOut } from "../../../lib/auth/authState";
import { HeroSection } from "../../../components/layout/HeroSection";
import { Platform } from "react-native";
import { Screen } from "../../../components/layout/Screen";
import { FrontAppScreenProps } from "../../../frontAppStack";
import { useFrontRpc } from "../../../lib/functions/rpc";

export const DeleteAccountScreen = ({
  navigation,
  route,
}: FrontAppScreenProps<"DeleteAccount">) => {
  const closeScreen = useCloseScreen();
  const [deleteAccountState, callDeleteAccount] = useFrontRpc("deleteAccount");

  const handleDelete = useCallback(async () => {
    const result = await callDeleteAccount({});
    if (result.success) {
      if (Platform.OS === "web") {
        window.location.href = "/a/home";
      }
      authSignOut();
    } else {
      closeScreen();
    }
  }, []);

  const handleClose = async () => {
    closeScreen();
  };

  return (
    <Screen name="Delete Account">
      <HeroSection image={require("assets/illustrations/Delete-Account.svg")} />
      <BoxSection>
        <Heading pt={6} fontSize="xl" textAlign="center">
          Delete Account
        </Heading>
        <Text mt={3} mx={4} mb={6} textAlign="center">
          We are sorry to see you leaving Recirclable! Reducing single-use plastic is a great way to
          reduce waste and save the planet. We at Recirclable will continue with our mission and we
          welcome you to join us again any time!
        </Text>
      </BoxSection>

      <Section alignItems="center">
        <TwoButtonGroup
          _left={{
            label: "No, keep",
            isDisabled: deleteAccountState.loading,
            onPress: handleClose,
          }}
          _right={{
            label: "Yes, delete",
            isDisabled: deleteAccountState.loading,
            isLoading: deleteAccountState.loading,
            bg: "red.600",
            _pressed: { bg: "red.400" },
            _focus: { bg: "red.400" },
            onPress: handleDelete,
          }}
        />
      </Section>
    </Screen>
  );
};
