import {
  getPathFromState,
  getStateFromPath,
  LinkingOptions,
  NavigationContainer,
  NavigationContainerRef,
} from "@react-navigation/native";
import {
  createNativeStackNavigator,
  NativeStackNavigationOptions,
} from "@react-navigation/native-stack";
import * as Linking from "expo-linking";
import { useMemo, useRef } from "react";
import { Platform } from "react-native";
import { appName, appUniversalDomain, getAppScheme } from "../../lib/expo/appConfig";
import { NavigationDefinition, PathRewrites, ScreenDefinitions } from "./NavigationDefinitions";
import { clearPendingLink, setPendingLink } from "./pendingLink";
import { appAnalytics } from "../../lib/analytics/analytics";
import _keys from "lodash/keys";
import { StackAnimationTypes } from "react-native-screens";

type AppNavigationProps<S extends string> = {
  rewrites?: PathRewrites;
  navDef: NavigationDefinition<S>;
};

const Stack = createNativeStackNavigator<any>();

// this is the actual component, which establishes the navigation
//
// IMPORTANT: the component is built in a way that the "navDef", so the actual definition
// of which screens are shown can be dynamically changed. react navigation supports changing
// the screens, but there are issue with dynamically chaning the link configuration. this
// is all handled here.
export function AppNavigation<S extends string>(props: AppNavigationProps<S>) {
  const navigationRef = useRef<NavigationContainerRef<any>>(null);
  const linkConfiguration = useDynamicLinkConfiguration(props.navDef, props.rewrites);
  const aname = appName();

  return (
    <NavigationContainer
      ref={navigationRef}
      linking={linkConfiguration}
      onReady={() => {
        const currentRoute = navigationRef.current?.getCurrentRoute();
        if (currentRoute?.name) appAnalytics().trackScreen(currentRoute.name, currentRoute.params);
      }}
      onStateChange={async (state) => {
        const currentRoute = navigationRef.current?.getCurrentRoute();
        if (currentRoute?.name) appAnalytics().trackScreen(currentRoute.name, currentRoute.params);
      }}
    >
      <Stack.Navigator screenOptions={{ headerShown: false }}>
        {renderScreens(props.navDef.screens, aname)}
      </Stack.Navigator>
    </NavigationContainer>
  );
}

function renderScreens(screenDefinitions: ScreenDefinitions<any>, appName?: string) {
  function screenTitle(name: string, title?: string) {
    const prefix = appName && Platform.OS === "web" ? `${appName} - ` : "";
    return `${prefix}${title ?? name}`;
  }

  return (Object.keys(screenDefinitions) as Array<string>).map((name) => {
    const title = screenTitle(name, screenDefinitions[name].title);

    let presentation: NativeStackNavigationOptions["presentation"] = undefined;
    let animation: StackAnimationTypes = "default";
    switch (screenDefinitions[name].style) {
      case "modal":
        presentation = "modal";
        break;

      case "from_bottom":
        animation = "slide_from_bottom";
        break;

      case "replace":
        animation = "none";
        break;
    }

    return (
      <Stack.Screen
        key={name}
        name={name}
        component={screenDefinitions[name].component}
        options={{ title, animation, presentation }}
      />
    );
  });
}

// the functions maps our short links to the longer screen links. essentially
// what a server side rewrite rule would do.
export function rewriteLink(redirects: PathRewrites, link: string) {
  const prefixes = _keys(redirects);
  for (const prefix of prefixes) {
    if (link.startsWith(prefix)) return redirects[prefix] + link.substring(prefix.length);
    if (!prefix.startsWith("/")) {
      if (link.startsWith("/" + prefix))
        return redirects[prefix] + link.substring(prefix.length + 1);
    }
  }
  return link;
}

// this checks, whether the current screens can map a path. ideally this check
// would be done with the react navigation library, but we have no access to the
// engine and can't get the information in another. we do just a very simple check
// which is sufficient for our purpose. true -> path can be mapped to a screen and
// false means it can't be mapped.
function canLink(screenDefinitions: ScreenDefinitions<any>, path: string) {
  for (const key of Object.keys(screenDefinitions)) {
    const screenPath = screenDefinitions[key].path;
    const fix = screenPath?.split(":")[0];
    if (fix) {
      if (path.startsWith(fix)) return true;
      if (path.startsWith("/" + fix)) return true;
    }
  }
  return false;
}

function useDynamicLinkConfiguration(navDef: NavigationDefinition<any>, rewrites?: PathRewrites) {
  // with this hack our getStateFromPath() always sees the latest stack definition
  const navDefRef = useRef(navDef);
  navDefRef.current = navDef;

  // create a base configuration, which must stay stable. based on a source review of react-navigation,
  // all values are kept immutable, but the values of ...config, in particular the initialRouteName and
  // screens can be changed in place.
  const baseLinkConfig = useMemo<LinkingOptions<any>>(() => {
    return {
      prefixes: [
        Linking.createURL("/", { scheme: getAppScheme() }),
        `https://${appUniversalDomain()}`,
        "https://rcrl.me",
        "https://wecrcl.com",
      ],

      getStateFromPath: (path, options?) => {
        // console.log("GET STATE FROM PATH", path);

        // handle redirect from short url to longer screen paths
        if (rewrites) path = rewriteLink(rewrites, path);

        // analytics tracking (native does track, web doesn't track)
        appAnalytics().eventAppLink(path);

        // if the link can not be handled, check whether to postpone the link for later. this
        // typically happens when the user isn't yet authenticated. the home screen then can
        // check whether there is a pending link and handle it.
        //
        // criteria are:
        // - screen stack must indicate postpone (= preamble screens)
        // - stack can't handle provided path (= user wants to navigate somewhere else)
        if (!canLink(navDefRef.current.screens, path)) {
          if (navDefRef.current.postponeLinks) setPendingLink(path);
          if (!navDefRef.current.fallbackPath) return undefined;
          path = navDefRef.current.fallbackPath;
        } else {
          // on web more (not every) navigation is a path based navigation. for example a browser
          // refresh is a path based navigation. in contrast on native a path based navigation is
          // really a universal link and express the users intent to navigate to a specific app location.
          if (Platform.OS !== "web") clearPendingLink();
        }

        // do the actual mapping
        const state = getStateFromPath(path, options);
        return state;
      },

      getPathFromState: (state, options) => {
        const path = getPathFromState(state, options);
        // console.log("PATH FROM STATE", path);
        return path;
      },

      config: {
        screens: {},
      },
    };
  }, []);

  // when screens change, dynamically apply their link definitions
  const linkConfig = useMemo<LinkingOptions<any>>(() => {
    // add the screens to the link mapping
    const screens: Record<string, any> = {};
    (Object.keys(navDef.screens) as string[]).forEach((name) => {
      const screenDef = navDef.screens[name];
      if (screenDef.path) screens[name] = { path: screenDef.path };
    });
    baseLinkConfig.config!.screens = screens;

    // assign an initial route. the first defined screen is the home screen.
    baseLinkConfig.config!.initialRouteName = navDef.initialScreen;

    return baseLinkConfig;
  }, [navDef]);

  return linkConfig;
}
