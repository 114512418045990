import { useFonts } from "expo-font";
import * as ExpoSplashScreen from "expo-splash-screen";
import { Box, Center, Text } from "native-base";
import React, { useEffect, useState } from "react";
import { Linking, Platform } from "react-native";
import { useAuthState } from "./lib/auth/authState";
import { PrimaryButton } from "./components/primitive/PrimaryButton";
import { AssetView } from "./components/primitive/AssetView";
import { VFlex } from "./components/layout/VFlex";
import { VSpace } from "./components/layout/VSpace";
import { usePendingWatchDog } from "./hooks/usePendingWatchDog";
import { FlatScreen } from "./components/layout/FlatScreen";
import { compareVersion } from "./hooks/versionCheck";
import { ActivitySpinner } from "./components/primitive/ActivitySpinner";
import { SecondaryButton } from "./components/primitive/SecondaryButton";
import useTimeout from "react-use/lib/useTimeout";
import useAsync from "react-use/lib/useAsync";
import { useRemoteFlags } from "./lib/remote_flags/useRemoteFlags";
import { isWeb } from "./util/deviceInfo";
import { docGet } from "./lib/firestore/fstore";
import { VPoints } from "./model/VPointDoc";
import { appMiniDomain } from "./lib/expo/appConfig";

export const AppWaitScreen = ({}) => {
  const [showSpinner] = useTimeout(2000); // Spinner after 2sec wait

  return (
    <FlatScreen name="Waiting ...">
      <Center w="100%" h="100%">
        {showSpinner() ? (
          <ActivitySpinner color="primaryText" size="lg" />
        ) : (
          <Box>{/* TODO: Logo */}</Box>
        )}
      </Center>
    </FlatScreen>
  );
};

const CheckUpgradeScreen = (props: { children: JSX.Element }) => {
  const warnAppVersion = useRemoteFlags((s) => s.warnAppVersion);
  const warnAppVersionMessage = useRemoteFlags((s) => s.warnAppVersionMessage);
  const minAppVersion = useRemoteFlags((s) => s.minAppVersion);
  const minAppVersionMessage = useRemoteFlags((s) => s.minAppVersionMessage);
  const [skipUpdate, setSkipUpdate] = useState(false);

  const versionCheck = compareVersion({ minAppVersion, warnAppVersion });
  if (!skipUpdate && versionCheck.outdated) {
    let message =
      "You appear to be on an older version of the app. There is a newer version available. Please go to App Store to update and continue using the app.";
    if (versionCheck.required) {
      if (!!minAppVersionMessage) message = minAppVersionMessage;
    } else {
      if (!!warnAppVersionMessage) message = warnAppVersionMessage;
    }

    return (
      <FlatScreen name="Version Check">
        <VFlex w="100%" h="100%" pt={16} px={6} alignItems="center">
          <AssetView
            source={require("assets/logo/logo-with-tm.svg")}
            alt="Recirclable"
            resizeMode="contain"
            w={180}
            h={180}
          />
          <VSpace h={10} />
          <Text textAlign="center">{message}</Text>
          <VSpace h={8} />
          {Platform.OS === "ios" ? (
            <PrimaryButton
              label="Go to App Store"
              w="60%"
              onPress={() =>
                Linking.openURL("https://apps.apple.com/us/app/recirclable/id1638609156")
              }
            />
          ) : (
            <PrimaryButton
              label="Go to Play Store"
              w="60%"
              onPress={() =>
                Linking.openURL(
                  "https://play.google.com/store/apps/details?id=com.recirclable.recirclable"
                )
              }
            />
          )}
          {!versionCheck.required && (
            <SecondaryButton
              mt={3}
              label="Skip Update"
              w="60%"
              onPress={() => setSkipUpdate(true)}
            />
          )}
        </VFlex>
      </FlatScreen>
    );
  } else {
    return props.children;
  }
};

export const AppLoading: React.FunctionComponent<{ children: JSX.Element }> = (props) => {
  // start the auth system
  useEffect(() => {
    useAuthState.getState().start();
  }, []);

  // wait until auth state and role are determined
  const authPending = useAuthState((s) => s.isPending);

  // wait until fonts are loaded
  const fontsState = useFonts({
    "NotoSans-Regular": require("assets/fonts/NotoSans-Regular.ttf"),
    "NotoSans-Medium": require("assets/fonts/NotoSans-Medium.ttf"),
    "NotoSans-Bold": require("assets/fonts/NotoSans-Bold.ttf"),
  });
  const fontsLoaded = !!fontsState ? fontsState[0] : true; // NOTE: safe with mocked expo-fonts

  // initialize the remote flags
  const { loading: remoteFlagsPending } = useAsync(() => {
    return useRemoteFlags.getState().initializeFeatureFlags();
  }, []);

  // pending -> we are still waiting for something
  const hardPending = authPending || !fontsLoaded;
  const softPending = remoteFlagsPending;

  // when all is loaded remove the Splash screen
  useEffect(() => {
    if (!hardPending) ExpoSplashScreen.hideAsync();
  }, [hardPending]);

  // timeout in case the app isn't initializing within an expected time
  usePendingWatchDog(
    hardPending,
    softPending,
    `state was (auth, fonts, remote, point): ${authPending} ${!fontsLoaded} ${remoteFlagsPending}`
  );

  // just waiting until we have all initial data to start the app
  if (hardPending) return <AppWaitScreen />;

  // all load -> check version -> show the app
  return <CheckUpgradeScreen>{props.children}</CheckUpgradeScreen>;
};

async function loadRedirectReturnPointId() {
  if (isWeb()) {
    // extract the point id (local it's /p, on production due to the redirect /a/point)
    let pointId;
    if (window.location.pathname?.startsWith("/p")) {
      pointId = window.location.pathname.slice(3);
    }
    if (window.location.pathname?.startsWith("/a/point")) {
      pointId = window.location.pathname.slice(9);
    }

    // now load the point
    if (pointId) {
      const point = await docGet(VPoints.doc(pointId));
      if (point?.type === "self_return" || point?.type === "self_return_w_scan") {
        if (point.settings?.redirectToMini) {
          return pointId;
        }
      }
    }
  }
  return null;
}

async function redirecToMiniApp(pointId: string) {
  console.log("Redirecting to mini app", pointId);
  window.location.href = `https://${appMiniDomain()}/point/${pointId}`;
}
