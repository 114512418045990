import omit from "lodash/omit";
import { IBoxProps, VStack } from "native-base";
import React from "react";
import { ImageSourcePropType } from "react-native";
import { AssetView } from "../primitive/AssetView";
import { SectionTitle } from "./SectionTitle";
import { VFlex } from "./VFlex";

export const SECTION_PADDING_X = 4;

export const Section: React.FunctionComponent<{} & IBoxProps> = (props) => {
  return (
    <VFlex position="relative" px={SECTION_PADDING_X} my={2} w="100%" {...props}>
      {props.children}
    </VFlex>
  );
};

export const ShadowedSection: React.FunctionComponent<{} & IBoxProps> = (props) => {
  return (
    <VFlex
      position="relative"
      px={SECTION_PADDING_X}
      my={2}
      w="100%"
      borderWidth={1}
      borderColor="coolGray.200"
      borderRadius={8}
      {...props}
    >
      {props.children}
    </VFlex>
  );
};

export const BoxSection: React.FunctionComponent<
  {
    title?: string;
    json?: {
      name?: string;
      data: any;
    };
    bgImageSource?: ImageSourcePropType;
    divider?: JSX.Element;
  } & IBoxProps
> = (props) => {
  const extraVProps = omit(props, ["title", "json", "bgImageSource", "divide"]);
  return (
    <>
      {props.title ? <SectionTitle title={props.title} json={props.json} /> : null}
      <Section>
        {/* don't replace VStack with VFlex. there is an optional divider */}
        <VStack
          position="relative"
          bg="sectionBackground"
          borderRadius="md"
          borderWidth={0}
          borderColor="gray.100"
          overflow="hidden"
          divider={props.divider}
          {...extraVProps}
        >
          {props.bgImageSource ? (
            <AssetView
              position="absolute"
              top={0}
              left={0}
              source={props.bgImageSource}
              alt="Background"
              w={"100%"}
              h={"100%"}
              zIndex={-1}
              preserveAspectRatio={false}
            />
          ) : null}
          {props.children}
        </VStack>
      </Section>
    </>
  );
};
