import { extendTheme } from "native-base";
import { Platform } from "react-native";

export const theme = extendTheme({
  fontConfig: {
    NotoSans: {
      // thin
      100: {
        normal: "NotoSans-Regular",
      },
      // extra light
      200: {
        normal: "NotoSans-Regular",
      },
      // light
      300: {
        normal: "NotoSans-Regular",
      },
      // regular
      400: {
        normal: "NotoSans-Regular",
      },
      // semi medium
      500: {
        normal: "NotoSans-Medium",
      },
      // medium
      600: {
        normal: "NotoSans-Medium",
      },
      // bold
      700: {
        normal: "NotoSans-Bold",
      },
      // extra bold
      800: {
        normal: "NotoSans-Bold",
      },
      // black
      900: {
        normal: "NotoSans-Bold",
      },
    },
  },

  fonts: {
    NotoSans: "NotoSans",
  },

  colors: {
    primary: {},
    accentGreen: {},
    accentBlue: {},
    errorMessage: "#9e2146", // a blackish red
    cta: {},
    primaryText: "#010A5A",
    accentLightBlue: "#d1eff7",
    accentLightGreen: "#EDF9F4",
    accentLightMediumGreen: "#bfe3d6",
    accentMediumGreen: "#90CDB7",
    veryLightGreen: "#f9fdfc",
    screenBackground: "#f0f4f0",
    sectionBackground: "white",
    sectionBorder: "gray.300",
  },

  shadows: {
    99: {
      shadowColor: "#000",
      shadowOffset: {
        width: 0,
        height: 0,
      },
      shadowOpacity: 0.2,
      shadowRadius: 8,
      elevation: 1,
    },
  },

  components: {
    Heading: {
      baseStyle: {
        _light: {
          color: "primaryText",
        },
        _dark: {
          color: "primaryText",
        },
      },
    },

    Text: {
      baseStyle: {
        _light: {
          color: "primaryText",
        },
        _dark: {
          color: "primaryText",
        },
        fontFamily: "NotoSans",
        fontSize: "md",
        fontWeight: "400",
      },
      defaultProps: {
        // DIRTY HACK: this disables OS level font scaling. better would be to fix the layout issues.
        // See also issue #146
        allowFontScaling: false,
        selectable: Platform.OS === "web",
      },
    },

    Icon: {
      baseStyle: {
        _light: {
          color: "primaryText",
        },
        _dark: {
          color: "primaryText",
        },
      },
    },

    Divider: {
      baseStyle: {
        bgColor: "gray.200",
      },
    },

    Pressable: {
      baseStyle: {
        // this removes the focus ring around all buttons and other pressable
        _focusVisible: {
          _web: { style: { boxShadow: "none", outlineWidth: 0 } },
        },
      },
    },

    Input: {
      baseStyle: {
        _disabled: {
          opacity: "0.5",
          color: "gray.900",
        },
        _input: {
          // this is necessary to operate the opacity, which Safari applies for disabled
          // input fields on mobile
          opacity: "1",
        },
      },
      defaultProps: {
        // DIRTY HACK: see comment for Text above
        allowFontScaling: false,
        variant: "unstyled",
        p: 3,
        fontSize: "lg",
        fontWeight: "400",
        color: "primaryText",
        placeholderTextColor: "primaryText",
      },
    },

    TextArea: {
      defaultProps: {
        // DIRTY HACK: see comment for Text above
        allowFontScaling: false,
        variant: "unstyled",
        p: 3,
        fontSize: "lg",
        fontWeight: "400",
        color: "primaryText",
        placeholderTextColor: "primaryText",
      },
    },

    ModalContent: {
      baseStyle: {
        _light: {
          _text: {
            color: "primaryText",
          },
        },
        _dark: {
          _text: {
            color: "primaryText",
          },
        },
      },
    },

    ModalHeader: {
      baseStyle: {
        _text: {
          fontWeight: "800",
        },
        _light: {
          _text: {
            color: "primaryText",
            textAlign: "center",
          },
        },
        _dark: {
          _text: {
            color: "primaryText",
            textAlign: "center",
          },
        },
      },
    },

    ModalBody: {
      baseStyle: {
        _light: {
          _text: {
            color: "primaryText",
          },
        },
        _dark: {
          _text: {
            color: "primaryText",
          },
        },
      },
    },

    ModalFooter: {
      baseStyle: {
        _light: {
          _text: {
            color: "primaryText",
          },
        },
        _dark: {
          _text: {
            color: "primaryText",
          },
        },
      },
    },

    AlertDialogContent: {
      baseStyle: {
        _light: {
          _text: {
            color: "primaryText",
          },
        },
        _dark: {
          _text: {
            color: "primaryText",
          },
        },
      },
    },

    AlertDialogHeader: {
      baseStyle: {
        _light: {
          _text: {
            color: "primaryText",
            fontWeight: "800",
            textAlign: "center",
          },
        },
        _dark: {
          _text: {
            color: "primaryText",
            fontWeight: "800",
            textAlign: "center",
          },
        },
      },
    },

    AlertDialogCloseButton: {
      baseStyle: {
        _light: {
          _icon: {
            color: "primaryText",
          },
        },
        _dark: {
          _text: {
            color: "primaryText",
          },
        },
      },
    },

    AlertDialogBody: {
      baseStyle: {
        _light: {
          _text: {
            color: "primaryText",
          },
        },
        _dark: {
          _text: {
            color: "primaryText",
          },
        },
      },
    },

    AlertDialogFooter: {
      baseStyle: {
        _light: {
          _text: {
            color: "primaryText",
          },
        },
        _dark: {
          _text: {
            color: "primaryText",
          },
        },
      },
    },

    Menu: {
      baseStyle: {
        borderRadius: 6,
        bg: "veryLightGreen",
      },
    },

    MenuItem: {
      baseStyle: {
        _text: {
          color: "primaryText",
          fontSize: "sm",
        },
      },
    },

    ActionsheetItem: {
      baseStyle: {
        // note: NativeBase default gives the item with focus a light gray shade. Makes
        // sense for keyboard use, but not on a mobile device with touch.
        _focus: { bg: "#ffffff" },

        _light: {
          _text: {
            color: "primaryText",
          },
        },
        _dark: {
          _text: {
            color: "primaryText",
          },
        },
      },
    },

    Spinner: {
      baseStyle: {
        color: "primary.700",
        size: "xl",
      },
    },
  },
});
