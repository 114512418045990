import React from "react";
import { Modal, Center } from "native-base";
import { PrimaryButton } from "../../../components/primitive/PrimaryButton";

export const ModalDeleteAccount: React.FC<{
  isOpen: boolean;
  onClose: () => void;
  balance: number;
}> = (props) => {
  const bowl = props.balance > 1 ? "bowls" : "bowl";
  const message = `You still have ${props.balance} ${bowl} borrowed. We would love for you to stay with Recirclable, but if you must leave, please return all bowls before deleting your account.`;

  return (
    <Center>
      <Modal
        isOpen={props.isOpen}
        size={"xl"}
        _backdrop={{ opacity: 0.4 }}
        style={{ marginVertical: 200 }}
      >
        <Modal.Content marginBottom="auto" marginTop={70}>
          <Modal.Header textAlign="center" fontWeight="700">
            Delete Account
          </Modal.Header>
          <Modal.Body px={6} py={4}>
            {message}
          </Modal.Body>
          <Modal.Footer justifyContent="center">
            <PrimaryButton label="Ok" onPress={props.onClose} minW="30%" />
          </Modal.Footer>
        </Modal.Content>
      </Modal>
    </Center>
  );
};
