import * as Sentry from "@sentry/react";
import { appConfigExtra } from "../expo/appConfig";
import { isEmulator, isProductionLike } from "../firebase/ifEnv";

export function initSentry() {
  console.log("🍏 SENTRY: using web Sentry");

  Sentry.init({
    dsn: appConfigExtra().sentry.dsn,
    tracesSampleRate: 1.0,
    environment: appConfigExtra().projectId,
    enabled: !isEmulator(), // isProductionLike(),
  });
}

export function sentry() {
  return Sentry;
}
