import { Platform } from "react-native";
import { appUniversalDomain } from "../expo/appConfig";
import { isEmulator } from "../firebase/ifEnv";
import { ONE_SEC } from "../../util/constants";
import { JsonObject } from "type-fest";

export async function loadConfigResource(path: string, contentType?: string) {
  // loading a config resource should not block the app. wait a max of 5sec for a config resource.
  const controller = new AbortController();
  const timeoutId = setTimeout(() => controller.abort(), 5 * ONE_SEC);
  try {
    let baseUrl = `https://${appUniversalDomain()}`;
    if (isEmulator()) {
      if (Platform.OS === "web") {
        // emulator web: Expo is serving the whole web-build directory
        baseUrl = `http://${window.location.host}`;
      } else {
        // emulator native: Metro is serving the whole project directory
        baseUrl = `http://localhost:19000/web`;
      }
    }

    const url = `${baseUrl}/.recirclable/${path}`;
    console.log("LOADING CONFIG RESOURCE", url);

    const request = new Request(url, { cache: "no-cache", signal: controller.signal });
    const response = await fetch(request);
    if (response.ok && response.status === 200) {
      if (!contentType || response.headers.get("content-type")?.includes(contentType)) {
        const text = await response.text();
        console.log("CONFIG RESOURCE loaded", text.substring(0, 20));
        return text;
      } else {
        console.warn("CONFIG RESOURCE: wrong content type.", response.headers.get("content-type"));
      }
    } else {
      console.warn("CONFIG RESOURCE: couldn't load config file.", response.status);
    }
  } catch (error) {
    console.warn("CONFIG RESOURCE: error loading config resource", path, error);
  } finally {
    clearTimeout(timeoutId);
  }

  return null;
}

export async function loadConfigJson(
  path: string,
  defaultJson: JsonObject = {}
): Promise<JsonObject> {
  try {
    const text = await loadConfigResource(path, "json");
    if (text) {
      let result = JSON.parse(text);
      if (typeof result === "object" || !Array.isArray(result)) {
        return result as JsonObject;
      }
      console.warn("CONFIG JSON: parsing json into something, which isn't an object", result);
    }
  } catch (error) {
    /* ignore error from loading the version file */
    console.warn("CONFIG JSON: error parsing json resource", path, error);
  }
  return defaultJson;
}
