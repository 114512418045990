import React, { useEffect, useState } from "react";
import { useFuncBeginStripe } from "./useFuncStripe";
import { Center } from "native-base";
import { PaymentCardSection } from "./PaymentCardSection";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe, Stripe } from "@stripe/stripe-js";
import { appConfigExtra } from "../../../../lib/expo/appConfig";
import { PaymentButtonSection } from "./PaymentButtonSection";
import { ActivitySpinner } from "../../../../components/primitive/ActivitySpinner";

export const PaymentInput = (props: {
  setup: boolean;
  isDisabled: boolean;
  isLoading: boolean;
  itemPrice?: number; // <- itemPrice might come delayed, because of view replication
  submitLabel: string;
  onProcessing: (state: boolean) => void;
  onDone: () => void;
}) => {
  const [stripe, setStripe] = useState<Stripe | null>(null);
  const [{ value: prepareResult }, runGetIntentSecret] = useFuncBeginStripe(props.setup);

  useEffect(() => {
    loadStripe(appConfigExtra().stripe.publicKey).then((result) => {
      setStripe(result);
    });
  }, []);

  useEffect(() => {
    runGetIntentSecret();
  }, []);

  if (stripe && prepareResult?.clientSecret && props.itemPrice) {
    return (
      <Elements stripe={stripe} options={{ clientSecret: prepareResult.clientSecret }}>
        <PaymentButtonSection
          isDisabled={props.isDisabled}
          isLoading={props.isLoading}
          itemPrice={props.itemPrice}
          intentSecret={prepareResult.clientSecret}
          onProcessing={props.onProcessing}
          onDone={props.onDone}
        />
        <PaymentCardSection
          isDisabled={props.isDisabled}
          isLoading={props.isLoading}
          submitLabel={props.submitLabel}
          intentSecret={prepareResult.clientSecret}
          onProcessing={props.onProcessing}
          onDone={props.onDone}
        />
      </Elements>
    );
  } else {
    return (
      <Center w="100%" h="100%">
        <ActivitySpinner />
      </Center>
    );
  }
};

export default PaymentInput;
