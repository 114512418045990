import { Box, IconButton, Text } from "native-base";
import React from "react";
import { AssetView } from "../primitive/AssetView";
import { HFlex } from "../layout/HFlex";
import { BackIcon, CloseIcon, IconType } from "../icons/Icons";

export const HEADER_HEIGHT = 46;
export const HEADER_HEIGHT_PX = `${HEADER_HEIGHT}px`;
const HEADER_ICON_WIDTH = 46;
const HEADER_ICON_WIDTH_PX = `${HEADER_ICON_WIDTH}px`;

export const Header: React.FunctionComponent<{
  title?: string;
  label?: string;
  backMode?: "none" | "close" | "back" | "both";
  onBack?: () => void;
  onLongBack?: () => void;
  onClose?: () => void;
  right?: React.ReactNode;
}> = (props) => {
  let IconLeft: IconType | null = null;
  let IconRight: IconType | null = null;
  let rightComponent = props.right;
  switch (props.backMode) {
    case "back":
      IconLeft = BackIcon;
      break;

    case "close":
      IconRight = CloseIcon;
      break;

    case "both":
      IconLeft = BackIcon;
      IconRight = CloseIcon;
      break;
  }

  return (
    <>
      <HFlex
        position="absolute"
        top={0}
        left={0}
        w="100%"
        h={`${HEADER_HEIGHT}px`}
        zIndex={10}
        justifyContent="center"
        alignItems="center"
        bg="white"
        borderColor="coolGray.200"
        borderBottomWidth={1}
        borderLeftWidth={1}
        borderRightWidth={1}
      >
        <Box
          position="absolute"
          top={0}
          left={0}
          justifyContent="center"
          alignItems="center"
          minW={HEADER_ICON_WIDTH_PX}
          w={HEADER_ICON_WIDTH_PX}
          zIndex={11}
        >
          {IconLeft ? (
            <IconButton
              onPress={props.onBack}
              onLongPress={props.onLongBack}
              variant="unstyled"
              colorScheme="primary"
              icon={<IconLeft size="xl" color="primaryText" />}
            />
          ) : null}
        </Box>
        <Box w="140px" h="100%" position="relative">
          <AssetView
            source={require("assets/logo/wordmark-darkblue.svg")}
            resizeMode="contain"
            w="140px"
            h="100%"
            alt="Recirclable"
          />
          {props.label ? (
            <Box position="absolute" top={0} left="160px">
              <Box my={2} borderRadius={4} bgColor="lime.400">
                <Text mx={2} my={1}>
                  {props.label}
                </Text>
              </Box>
            </Box>
          ) : null}
        </Box>
        <Box position="absolute" top={0} right={0} minW={HEADER_ICON_WIDTH_PX}>
          {rightComponent}
          {IconRight ? (
            <IconButton
              onPress={props.onClose ?? props.onBack}
              variant="unstyled"
              colorScheme="primary"
              icon={<IconRight size="xl" color="primaryText" />}
            />
          ) : null}
        </Box>
      </HFlex>
      <Box w="100%" minH={HEADER_HEIGHT_PX} h={HEADER_HEIGHT_PX} bg="transparent" />
    </>
  );
};
