import _omit from "lodash/omit";
import { Button, IButtonProps } from "native-base";
import React from "react";

export const SecondaryButton: React.FunctionComponent<{ label: string } & IButtonProps> = (
  props
) => {
  const extraProps = _omit(props, ["label", "colorScheme", "variant"]);

  return (
    <Button
      variant="unstyled"
      p={2}
      bg="white"
      borderWidth={0}
      borderColor="teal.500"
      borderRadius="2xl"
      _pressed={{ bg: "teal.700", _text: { color: "white" } }}
      _text={{ color: "teal.800", fontWeight: "600" }}
      {...extraProps}
      isLoading={props.isLoading}
      isDisabled={props.isDisabled || props.isLoading}
    >
      {props.label}
    </Button>
  );
};
