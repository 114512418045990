import React, { useEffect } from "react";
import { WaitScreen } from "../../../components/layout/WaitScreen";
import { VPoints } from "../../../model/VPointDoc";
import { FrontAppScreenProps } from "../../../frontAppStack";
import { selectPointScreen } from "./selectPointScreen";

// this screen is called as a result of an external scan. it's essentially a wait screen until we
// have loaded the information about the point.
export const PointLinkScreen = ({ navigation, route }: FrontAppScreenProps<"Point">) => {
  useEffect(() => {
    if (route.params.pointId) {
      VPoints.findById(route.params.pointId)
        .then((point) => {
          if (point) {
            navigation.replace(selectPointScreen(point), {
              pointId: point.id,
              locationId: point.locationId,
            });
          } else {
            navigation.replace("PointError");
          }
        })
        .catch((error) => {
          navigation.replace("PointError");
        });
    } else {
      navigation.replace("PointError");
    }
  }, []);

  return <WaitScreen name="Point" />;
};
