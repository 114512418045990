import { loadConfigResource } from "../../../lib/config/loadConfigResource";
import { OnlineOrderType } from "../../../../../backend-types/src";
import _entries from "lodash/entries";

export type PlaceholderConfig = {
  customerCode: string;
  orderRootUrl?: string;
};

async function loadScript(orderSystem: OnlineOrderType): Promise<string | null> {
  if (!orderSystem) return null;
  return await loadConfigResource(`order/${orderSystem}.js`);
}

export async function getScript(orderSystem: OnlineOrderType, values: PlaceholderConfig) {
  let script = await loadScript(orderSystem);
  if (!script) return null;

  _entries(values).forEach(([key, value]) => {
    script = script!.replaceAll(`%%%-${key}-%%%`, `${value}`);
  });
  return script;
}
