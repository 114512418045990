import useAsync from "react-use/lib/useAsync";
import { dateSince } from "../util/days";
import { VTransactions } from "./VTransactionDoc";
import { docQuery } from "../lib/firestore/fstore";

export function useLoadTransactionsSince(accountId: string | null | undefined, deltaMs: number) {
  return useAsync(async () => {
    console.log("load transaction", accountId, deltaMs);

    if (accountId) {
      try {
        const txns = docQuery(
          VTransactions.query()
            .where("accountId", "==", accountId)
            .where("transactionAt", ">=", dateSince(new Date(), deltaMs))
            .orderBy("transactionAt", "desc")
        );
        return txns;
      } catch (error) {
        console.error("error while querying transactions", error);
      }
    }
    return [];
  }, [accountId, deltaMs]);
}
