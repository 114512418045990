import useAsyncFn from "react-use/lib/useAsyncFn";
import { FrontRpc } from "../../../lib/functions/rpc";
import { useCustomer } from "../../../model/useCustomer";
import { overlayCancel, overlayWrite } from "../../../lib/firestore/overlay";

export function useBorrow() {
  const { balance } = useCustomer();

  return useAsyncFn(async (pointId: string, qty: number) => {
    try {
      // calculate the new balance
      let newOutstanding = (balance.outstandingSum ?? 0) + qty;

      // optimistically update the balance shown in the app
      overlayWrite(balance, { outstandingSum: newOutstanding });

      const result = await FrontRpc.call("selfBorrow", {
        pointId,
        qty,
      });

      // there are some edge cases where we don't get a error, but nothing was returned.
      if (!result.success || !result.transaction) {
        throw new Error("No borrow.");
      }

      // done
      return result;
    } catch (error) {
      overlayCancel(balance);
      throw error;
    }
  });
}
