import Constants from "expo-constants";
import { Platform } from "react-native";
import * as Application from "expo-application";
import { isProduction } from "../firebase/ifEnv";

// note: this is the extra data from the Expo app.json manifest
export const appConfig = () => {
  return Constants.expoConfig;
};

// note: this is the extra data from the Expo app.json manifest
export const appConfigExtra = () => {
  const config = appConfig()?.extra || {};
  return config;
};

export const appName = () => {
  return appConfig()?.name;
};

export function appUniversalDomain() {
  if (isProduction()) {
    return "recirclable.app";
  } else {
    return `${getPrjId()}.recirclable.app`;
  }
}

export function appAdminDomain() {
  if (isProduction()) {
    return "admin.recirclable.app";
  } else {
    return `admin-${getPrjId()}.recirclable.app`;
  }
}

export function appMiniDomain() {
  if (isProduction()) {
    return "m.recirclable.app";
  } else {
    return `m-${getPrjId()}.recirclable.app`;
  }
}

export function getAppScheme() {
  if (isProduction()) return "recirclable";
  return getProjectId();
}

export function getProjectId() {
  return appConfigExtra().firebase.projectId;
}

export function getPrjId() {
  return appConfigExtra().prj;
}

export function getAppVersion() {
  return appConfig()?.version ?? "N/A";
}

export function getBuildNumber() {
  return appConfigExtra().buildNumber ?? "N/A";
}

export function getRuntimeVersion() {
  return appConfigExtra().runtimeVersion ?? "N/A";
}

export function getNativeRuntimeVersion() {
  if (Platform.OS === "web") {
    return "web";
  } else {
    return Application.nativeBuildVersion ?? "N/A";
  }
}

export function getApiVersion() {
  return appConfigExtra().apiVersion ?? "N/A";
}
