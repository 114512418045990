import _omit from "lodash/omit";
import React from "react";
import { Button, Flex, IButtonProps, Text } from "native-base";
import tinycolor from "tinycolor2";
import { SecondaryButton } from "../../../components/primitive/SecondaryButton";
import { ActivitySpinner } from "../../../components/primitive/ActivitySpinner";
import { IconType } from "../../../components/icons/Icons";

export const ProviderButton: React.FunctionComponent<
  {
    providerColor: string;
    label: string;
    isLoading: boolean;
    icon: IconType;
  } & IButtonProps
> = (props) => {
  const extraProps = _omit(props, [
    "colorScheme",
    "variant",
    "providerColor",
    "label",
    "isLoading",
    "icon",
  ]);
  const tcolor = tinycolor(props.providerColor);
  const bright = tcolor.getBrightness() > 180;
  const textColor = bright ? "black" : "white";
  const pressedColor = (bright ? tcolor.darken(15) : tcolor.brighten(10)).toHexString();
  const ProviderIcon = props.icon;

  return (
    <Button
      variant="unstyled"
      p={2}
      bg={props.providerColor}
      borderColor={pressedColor}
      borderWidth={bright ? 1 : 0}
      _pressed={{ bg: pressedColor }}
      _text={{ color: textColor, fontWeight: "600" }}
      {...extraProps}
    >
      <Flex flexDirection="row" alignContent="center" w="100%">
        <ProviderIcon mx={1} size="lg" color={textColor} />
        <Text ml={1} mr={2} color={textColor}>
          {"Continue with " + props.label}
        </Text>
        {!!props.isLoading && <ActivitySpinner color={textColor} />}
      </Flex>
    </Button>
  );
};

export const AlternativeProviderButton: React.FunctionComponent<
  { label: string } & IButtonProps
> = (props) => {
  return <SecondaryButton {...props} label={"Use " + props.label} />;
};
