import { Text } from "native-base";
import React from "react";
import { Linking } from "react-native";
import { PrimaryButton } from "../../../components/primitive/PrimaryButton";
import { TextIndentSection } from "../../../components/layout/TextIndentSection";
import { Section } from "../../../components/layout/Sections";
import { SecondaryButton } from "../../../components/primitive/SecondaryButton";
import { VSpace } from "../../../components/layout/VSpace";
import { Screen } from "../../../components/layout/Screen";
import { HeroSection } from "../../../components/layout/HeroSection";
import { FrontAppScreenProps } from "../../../frontAppStack";
import { useCloseScreen } from "../../../components/navigation/useCloseScreen";

// ItemInfo shown when the customer is already authenticated.
export const ItemInfoScreen = ({ navigation, route }: FrontAppScreenProps<"ItemInfo">) => {
  const closeScreen = useCloseScreen();

  function handleGoHome() {
    closeScreen();
  }

  function handleGoScan() {
    navigation.replace<any>("Scan");
  }

  function handleLearnMore() {
    Linking.openURL("https://www.recirclable.com");
  }

  return (
    <Screen name="Item Info" backMode="close" onBack={handleGoHome}>
      <HeroSection image={require("assets/illustrations/QR-Code.svg")} />
      <Section>
        <Text my={1} mx={4} textAlign="center" fontSize="lg" fontWeight="600">
          You scanned the QR code on a bowl. What would you like to do?
        </Text>
      </Section>
      <Section>
        <PrimaryButton onPress={handleGoHome} my={1} label="Check my account status" />
      </Section>
      <Section>
        <SecondaryButton onPress={handleGoScan} mt={4} label="Borrow a bowl" />
      </Section>
      <TextIndentSection mt={0}>
        <Text textAlign="center">
          Please scan the LARGE QR CODE displayed at the restaurant checkout to proceed with
          borrowing bowls.
        </Text>
      </TextIndentSection>
      <Section>
        <SecondaryButton onPress={handleGoScan} mt={4} label="Return a bowl" />
      </Section>
      <TextIndentSection mt={0}>
        <Text textAlign="center">
          Please scan the LARGE QR CODE displayed at the Recirclable return bin.
        </Text>
      </TextIndentSection>
      <Section>
        <SecondaryButton onPress={handleLearnMore} my={2} label="Learn more about Recirclable" />
      </Section>
      <VSpace h="30px" />
    </Screen>
  );
};
