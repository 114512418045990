import React, { useCallback, useEffect, useRef, useState } from "react";
import { PrimaryButton } from "../../../components/primitive/PrimaryButton";
import { Section } from "../../../components/layout/Sections";
import { AlertYesNo } from "../../../components/modals/AlertYesNo";
import { VSpace } from "../../../components/layout/VSpace";
import { ONE_SEC } from "../../../util/constants";
import { ContainerDoc } from "../../../model/ContainerDoc";
import { useCustomer } from "../../../model/useCustomer";
import { appAnalytics } from "../../../lib/analytics/analytics";
import { FrontAppScreenProps } from "../../../frontAppStack";
import { FullScreen } from "../../../components/layout/FullScreen";
import { isSimulator } from "../../../util/deviceInfo";
import { ContainerScanner } from "./container/ContainerScanner";
import { ContainersView } from "./container/ContainersView";
import { VLocations } from "../../../model/VLocationDoc";
import { SecondaryButton } from "../../../components/primitive/SecondaryButton";
import { Animated, Platform } from "react-native";
import { SelfReturnScanIssueModalBody } from "./SelfReturnScanIssueModalBody";
import { AlertModal, useModal } from "../../../components/modals/useModal";
import { ScrollView } from "native-base";
import { useCloseScreen } from "../../../components/navigation/useCloseScreen";
import useTimeoutFn from "react-use/lib/useTimeoutFn";
import useAsync from "react-use/lib/useAsync";
import { IGNORE_RECENT_BORROW_FOR } from "./constantsCheckout";
import { useRemoteFlags } from "../../../lib/remote_flags/useRemoteFlags";
import { SectionMsg } from "../../../components/layout/SectionMsg";
import { useReturn } from "./useReturn";
import { sentry } from "../../../lib/sentry/sentry";

const SelfReturnScanInput: React.FunctionComponent<{
  pointId: string;
  locationId: string;
  disabled?: boolean;
  errorMsg?: string | null;
  onSubmit: (submittedIds: string[]) => void;
  onIssues: (scannedIds: string[], reasonCode: string | null) => void;
}> = (props) => {
  const ANIMATION_DURATION_MS = 800;

  const closeScreen = useCloseScreen();
  const featureFlags = useRemoteFlags();
  const [containers, setContainers] = useState<ContainerDoc[]>([]);

  // handling to fade-in issue button after a timeout
  const issueFadeIn = useRef(new Animated.Value(0)).current;
  const [issueColor, setIssueColor] = useState("yellow.100");
  const [issueShown, setIssueShown] = useState(false);
  const [timerIsReady, timerCancel, timerReset] = useTimeoutFn(
    () => {
      if (!issueShown) {
        setIssueShown(true);
        Animated.timing(issueFadeIn, {
          toValue: 1,
          duration: ANIMATION_DURATION_MS,
          useNativeDriver: true,
        }).start();
        issueFadeIn.addListener(({ value }) => {
          if (value > (ANIMATION_DURATION_MS / ONE_SEC) * 0.4) setIssueColor("yellow.50");
          if (value > (ANIMATION_DURATION_MS / ONE_SEC) * 0.8) setIssueColor("white");
        });
      }
    },
    isSimulator() ? 4 * ONE_SEC : featureFlags.returnFallback ?? 5 * ONE_SEC
  );

  // handle issue modal
  const [modalState, openIssueModal] = useModal<string>((yesno, result) => {
    if (yesno) {
      sentry().captureException(new Error(`customer reported issues with scanning '${result}'`));
      props.onIssues(
        containers.map((item) => item.id),
        result
      );
    }
  }, true);

  const handleHavingIssues = useCallback(() => {
    sentry().captureException(new Error("customer having issues with scanning"));
    openIssueModal();
  }, [openIssueModal]);

  const { value: location } = useAsync(async () => {
    return VLocations.findById(props.locationId);
  });

  function handleAddItem(item: ContainerDoc) {
    // reset the timer to wait again for a scan
    timerReset();

    // update the item list
    setContainers((state) => {
      // NOTE: double scans can easily happen, because the camera is constantly reading QR codes. we
      //       filter duplicates codes earlier, but due to async actions it can still happen that
      //       handleAddItem() is called twice with the same code. handle that situation here.
      const alreadyScanned = state.find((scanned) => scanned.id === item.id);
      if (alreadyScanned) return state;
      return state.concat([item]);
    });
  }

  function handleSubmit() {
    const containerIds = containers.map((item) => item.id);
    props.onSubmit(containerIds);
  }

  const handleNoCamera = useCallback(() => {
    if (Platform.OS === "web") {
      props.onIssues([], "web_no_camera");
    } else {
      closeScreen();
    }
  }, []);

  const buttonLabel = (numOfBowls: number) => {
    let label = "";
    if (numOfBowls === 0) {
      label = "No bowls scanned yet";
      if (location) label += ` at ${location.name}`;
    } else {
      if (numOfBowls === 1) {
        label = "Return 1 bowl";
      } else {
        label = `Return ${numOfBowls} bowls`;
      }
      if (location) label += ` to ${location.name}`;
    }
    return label;
  };

  return (
    <>
      <ContainerScanner
        disabled={props.disabled}
        // when the camera starts scanning for container codes, the user most likely is still
        // holding it towards the return sign. ignoring the code for a short time prevents an
        // immediate error message and makes the user experience nicer.
        tempIgnoreCode={props.pointId}
        alreadyScanned={containers}
        validate={(container: ContainerDoc) => {
          if (container.returnLockUntil && container.returnLockUntil.getTime() > Date.now()) {
            return "Someone else just returned this bowl";
          }
          return null;
        }}
        onAddContainer={handleAddItem}
        onNoCamera={handleNoCamera}
      />
      <VSpace h={3} />
      <ScrollView>
        <ContainersView
          containers={containers}
          emptyMsg="Please scan the QR code from each bowl you are returning."
          continueMsg="Please continue scanning bowls."
        />
        <Section mt={3}>
          <PrimaryButton
            my={1}
            isDisabled={props.disabled || containers.length < 1}
            isLoading={props.disabled}
            onPress={handleSubmit}
            label={buttonLabel(containers.length)}
          />
          <Animated.View style={{ opacity: issueFadeIn }}>
            <SecondaryButton
              my={1}
              bg={issueColor}
              label={"Having issues scanning?"}
              onPress={handleHavingIssues}
            />
          </Animated.View>
        </Section>
        {props.errorMsg && <SectionMsg text={props.errorMsg} type="error" />}
      </ScrollView>
      <AlertModal
        modalState={modalState}
        title="Scanning"
        text="Please describe the issue:"
        yesLabel="Continue"
        noLabel="Cancel"
        extraBody={SelfReturnScanIssueModalBody}
      />
    </>
  );
};

export const SelfReturnScanScreen = ({
  navigation,
  route,
}: FrontAppScreenProps<"PointReturnWScan">) => {
  const { balance } = useCustomer();
  const [showAlert, setShowAlert] = useState(false);
  const [submittedContainerIds, setSubmittedContainerIds] = useState<string[]>([]);
  const [returnState, callSelfReturn] = useReturn();
  const [errorMsg, setErrorMsg] = useState<string | null>(null);

  const handleIssues = (scannedIds: string[], reasonCode: string | null) => {
    navigation.replace("PointReturn", {
      pointId: route.params.pointId,
      locationId: route.params.locationId,
      containerIds: scannedIds.join(","),
      ...(reasonCode && { reasonCode }),
    });
  };

  useEffect(() => {
    if (returnState.value?.transaction) {
      appAnalytics().eventReturn(submittedContainerIds.length);

      navigation.replace("ShowReceipt", {
        transactionId: returnState.value.transaction.id,
        transaction: returnState.value.transaction,
      });
    } else if (returnState.error) {
      setErrorMsg("An error occurred. Please try again.");
    }
  }, [returnState.value, returnState.error]);

  function handleReturn(submittedIds: string[]) {
    if (
      !!IGNORE_RECENT_BORROW_FOR &&
      balance.isAmbigousReturn(submittedIds.length, IGNORE_RECENT_BORROW_FOR)
    ) {
      // returning a recent borrow -> show alert
      setSubmittedContainerIds(submittedIds);
      setShowAlert(true);
    } else {
      callSelfReturn(route.params.pointId, submittedIds.length, 0, submittedIds);
    }
  }

  function handleCloseAlert(yesno?: boolean) {
    setShowAlert(false);
    const ignoreRecentMs = yesno === false ? IGNORE_RECENT_BORROW_FOR : 0;
    callSelfReturn(
      route.params.pointId,
      submittedContainerIds.length,
      ignoreRecentMs,
      submittedContainerIds
    );
  }

  return (
    <FullScreen name="Return /w Scan">
      <SelfReturnScanInput
        pointId={route.params.pointId}
        locationId={route.params.locationId}
        disabled={returnState.loading}
        errorMsg={errorMsg}
        onSubmit={handleReturn}
        onIssues={handleIssues}
      />
      <AlertYesNo
        isOpen={showAlert}
        title="Return Bowls"
        primary="No, other bowls"
        secondary="Yes, these bowls"
        msg="Are you returning bowls you just borrowed within the last hour?"
        onClose={handleCloseAlert}
      />
    </FullScreen>
  );
};
