import { Input, Modal, Text } from "native-base";
import React, { useCallback, useRef, useState } from "react";
import { PrimaryButton } from "../../../components/primitive/PrimaryButton";
import { fixPhone, validatePhone } from "../../../util/phone";
import useAsyncFn from "react-use/lib/useAsyncFn";
import { FrontRpc } from "../../../lib/functions/rpc";
import { SectionMsg } from "../../../components/layout/SectionMsg";
import { ModalState } from "../../../components/modals/useModal";

export const ModalPhoneInput = (props: { modalState: ModalState<string> }) => {
  const [msg, setMsg] = useState<string>("");
  const inputRef = useRef<any>(null);
  const [value, setValue] = useState("");

  const [submitState, doSubmit] = useAsyncFn(async (phone: string) => {
    const result = await FrontRpc.call("changeProfile", { phone });
    if (result.success) {
      props.modalState.onClose(true);
    } else {
      setMsg("Phone number already in use.");
    }
  });

  const handleSubmit = useCallback(async () => {
    const phone = fixPhone(value);
    if (validatePhone(value)) doSubmit(phone);
  }, [value, doSubmit]);

  const valid = validatePhone(fixPhone(value));
  return (
    <Modal
      initialFocusRef={inputRef}
      isOpen={props.modalState.isOpen}
      onClose={props.modalState.onClose}
      size="xl"
    >
      <Modal.Content>
        <Modal.CloseButton />
        <Modal.Header textAlign="center" fontWeight="700">
          Phone Number
        </Modal.Header>
        <Modal.Body px={6} pt={3} pb={1}>
          <Text>
            Please enter your mobile phone number you are using for placing the online order. It
            will be added to your Recirclable account.
          </Text>
          <Input
            ref={inputRef}
            px={-4}
            mt={4}
            mb={2}
            keyboardType="phone-pad"
            placeholder="Enter Phone Number"
            autoComplete="tel"
            _input={{
              mx: 2,
            }}
            isFocused={true}
            placeholderTextColor="muted.400"
            value={value}
            onChangeText={setValue}
            borderWidth={1}
            borderColor="gray.200"
          />
          <SectionMsg text={msg} type="error" />
        </Modal.Body>
        <Modal.Footer justifyContent="center">
          <PrimaryButton
            isLoading={submitState.loading}
            isDisabled={!valid}
            onPress={handleSubmit}
            label={"Continue"}
            minW="30%"
          />
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  );
};
