import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { AlertOk } from "./AlertOk";
import { FUNC_NOOP } from "../../util/constants";
import { AlertYesNo } from "./AlertYesNo";

type AlertOkOptions = {
  title: string;
  text: string;
  ok?: string;
};

type AlertYesNoOptions = {
  title: string;
  text: string;
  yes?: string;
  no?: string;
};

type AlertContextType = {
  showAlertOk: (opts: AlertOkOptions) => Promise<boolean | null>;
  showAlertYesNo: (opts: AlertYesNoOptions) => Promise<boolean | null>;
  resolveAlert: (result: boolean | null | undefined) => void;
};

const AlertContext = createContext<AlertContextType | null>(null);

export const AlertProvider = (props: { children?: JSX.Element | JSX.Element[] }) => {
  const [alertOkOptions, setAlertOkOptions] = useState<AlertOkOptions | null>(null);
  const [alertYesNoOptions, setAlertYesNoOptions] = useState<AlertYesNoOptions | null>(null);
  const resolveRef = useRef<(value: boolean | null | undefined) => void>(FUNC_NOOP);

  // resolves and closes any open alert
  const resolveAlert = useCallback((result: boolean | null | undefined) => {
    resolveRef.current?.(result);
    setAlertOkOptions(null);
    setAlertYesNoOptions(null);
    resolveRef.current = FUNC_NOOP;
  }, []);

  const showAlertOk = useCallback((opts: AlertOkOptions): Promise<boolean | null> => {
    // close any open alert
    resolveAlert(null);

    // open the alert and use a promise for the close rendevous
    setAlertOkOptions(opts);
    return new Promise((resolve, reject) => {
      // @ts-ignore
      resolveRef.current = resolve;
    });
  }, []);

  const showAlertYesNo = useCallback((opts: AlertYesNoOptions): Promise<boolean | null> => {
    // close any open alert
    resolveAlert(null);

    // open the alert and use a promise for the close rendevous
    setAlertYesNoOptions(opts);
    return new Promise((resolve, reject) => {
      // @ts-ignore
      resolveRef.current = resolve;
    });
  }, []);

  const context = useMemo(() => ({ showAlertOk, showAlertYesNo, resolveAlert }), []);

  useEffect(() => {
    // when the provider is unmounted also resolve all wait clients
    return () => {
      context.resolveAlert(null);
    };
  }, []);

  return (
    <AlertContext.Provider value={context}>
      {props.children}
      {!!alertOkOptions && (
        <AlertOk
          isOpen={!!alertOkOptions}
          title={alertOkOptions.title}
          msg={alertOkOptions.text}
          primary={alertOkOptions.ok}
          onClose={(yes) => {
            resolveAlert(yes);
          }}
        />
      )}
      {!!alertYesNoOptions && (
        <AlertYesNo
          isOpen={!!alertYesNoOptions}
          title={alertYesNoOptions.title}
          msg={alertYesNoOptions.text}
          primary={alertYesNoOptions.yes}
          secondary={alertYesNoOptions.no}
          onClose={(yesno) => {
            resolveAlert(yesno);
          }}
        />
      )}
    </AlertContext.Provider>
  );
};

export const useAlerts = () => {
  const alerts = useContext(AlertContext);

  useEffect(() => {
    // if the containing view is unmounted, then make sure also all alerts are closed
    return () => {
      alerts?.resolveAlert(null);
    };
  }, []);

  return alerts!;
};
