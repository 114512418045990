import { Box, HStack, Text } from "native-base";
import React from "react";
import { BoxSection } from "../../../../components/layout/Sections";
import { ContainerDoc } from "../../../../model/ContainerDoc";
import { ContainerList } from "./ContainerList";

export const ContainersView: React.FunctionComponent<{
  containers: ContainerDoc[];
  emptyMsg?: string;
  continueMsg?: string;
  showProductInfo?: boolean;
}> = (props) => {
  const items = props.containers;
  return (
    <BoxSection title="Scanned Bowls">
      <HStack my={2} justifyContent="space-between">
        <ContainerList
          containers={items}
          showProductInfo={props.showProductInfo}
          emptyMsg={props.emptyMsg}
          continueMsg={props.continueMsg}
        />
        <Box
          ml={1}
          borderLeftColor={"gray.100"}
          borderLeftWidth="2"
          justifyContent="center"
          alignItems="center"
          alignSelf="stretch"
          w="20%"
        >
          <Text fontSize="5xl" fontWeight="bold">
            {items.length}
          </Text>
        </Box>
      </HStack>
    </BoxSection>
  );
};
