import { PartialDeep } from "type-fest";
import { createStore } from "zustand";
import { subscribeWithSelector } from "zustand/middleware";
import { ONE_SEC } from "../../util/constants";
import { TypedDoc } from "./TypedDoc";
import _cloneDeep from "lodash/cloneDeep";
import _merge from "lodash/merge";

const DEFAULT_EXPIRE = 20 * ONE_SEC;

//
// ------  the store, where we store the overlays -----
// (intentionally implemented as a vanilla store. no react hook)
//

type OverlayEntry = {
  path: string;
  updatedAt: Date;
  values: Record<string, any>;
  expiresAt: number;
};

type OverlayState = {
  overlays: Map<string, OverlayEntry | null>;
  apply<T extends TypedDoc>(doc: T): T;
  write<T extends TypedDoc>(doc: T, values: Record<string, any>, expireMs?: number): void;
  cancel<T extends TypedDoc>(doc: T): void;
};

const overlayStore = createStore(
  subscribeWithSelector<OverlayState>((set, get) => {
    return {
      overlays: new Map<string, OverlayEntry | null>(),

      apply<T extends TypedDoc>(doc: T): T {
        const entry = get().overlays.get(doc._ref.path);

        // check that the entry didn't expire
        if (entry && entry.expiresAt > Date.now()) {
          return _merge(_cloneDeep(doc), entry.values);

          // we could check, whether there were updates since the overlay was applied,
          // and remove the overlay. but that way intermediate changes get visible. this
          // happens e.g. when a return balances out multiple borrows. in that case the balance
          // is updated from the every borrow where the outstandingQty is reduced.
          //
          // // check that the document itself wasn't updated
          // if (entry.updatedAt.getTime() === doc.updatedAt?.getTime()) {
          //   return _.merge(_.cloneDeep(doc), entry.values);
          // }
        }
        return doc;
      },

      write<T extends TypedDoc>(
        doc: T,
        values: PartialDeep<T>,
        expireMs: number = DEFAULT_EXPIRE
      ): void {
        // prepare the overlay entry
        const expiresAt = Date.now() + expireMs;
        const entry: OverlayEntry = {
          path: doc._ref.path,
          updatedAt: doc.updatedAt!,
          values,
          expiresAt: expiresAt,
        };

        // set the overlay values --> subscribers will be notified
        set((state) => {
          return {
            overlays: new Map<string, OverlayEntry | null>(state.overlays).set(entry.path, entry),
          };
        });

        // set the timer to auto remove the entry
        setTimeout(() => {
          // check that it's still the entry written and that there is no newer overlay
          if (get().overlays.get(entry.path)?.expiresAt === expiresAt) {
            // remove the entry --> subscribers will be notified
            set((state) => {
              return {
                overlays: new Map<string, OverlayEntry | null>(state.overlays).set(
                  entry.path,
                  null
                ),
              };
            });
          }
        }, expireMs);
      },

      cancel<T extends TypedDoc>(doc: T): void {
        const path = doc._ref.path;
        set((state) => {
          if (state.overlays.get(doc._ref.path)) {
            return {
              overlays: new Map<string, OverlayEntry | null>(state.overlays).set(path, null),
            };
          }
          return state;
        });
      },
    };
  })
);

//
// -----  the public accessor functions  -----
//

export function overlayApply<T extends TypedDoc>(doc: T) {
  return overlayStore.getState().apply(doc);
}

export function overlayWrite<T extends TypedDoc>(
  doc: T,
  values: PartialDeep<T>,
  expireMs?: number
) {
  overlayStore.getState().write(doc, values, expireMs);
}

export function overlayCancel<T extends TypedDoc>(doc: T) {
  overlayStore.getState().cancel(doc);
}

export function onOverlay(path: string, callback: () => void) {
  return overlayStore.subscribe(
    // selector. filters for a particular doc
    (state) => {
      return state.overlays.get(path)?.values;
    },
    // listener
    (values, prevValues) => {
      // check for actual change
      if (values !== prevValues) {
        callback(); // --> just inform.listern must call apply() to get the overwrite
      }
    }
  );
}
