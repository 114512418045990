import React from "react";
import {
  Image,
  Pressable,
  ScrollView,
  StatusBar,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import { authSignOut } from "../../lib/auth/authState";
import { sentry } from "../../lib/sentry/sentry";
import { ifProduction } from "../../lib/firebase/ifEnv";
import { ONE_SEC } from "../../util/constants";
import { reloadApp } from "../../util/reload";
import * as ExpoSplashScreen from "expo-splash-screen";

interface ErrorBoundaryProps {
  children?: JSX.Element | JSX.Element[] | string | any;
}

interface ErrorBoundaryState {
  hasError: boolean;
  error?: any;
  showError?: boolean;
  errorAt?: number;
  lastErrorAt?: number;
}

export class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: any) {
    sentry().captureException(error);
    ExpoSplashScreen.hideAsync(); // make sure the Splash Screen is gone
    console.error("ERROR BOUNDARY:", error);
    return { hasError: true, error, errorAt: Date.now() };
  }

  async handleRetry() {
    // repeated errors --> do a deeper reset of the app
    const repeatMs = ifProduction(20 * ONE_SEC, 3 * ONE_SEC);
    if (
      this.state.errorAt &&
      this.state.lastErrorAt &&
      this.state.errorAt < this.state.lastErrorAt + repeatMs
    ) {
      authSignOut();

      // maybe also in the future clear the local storage
      // sessionStorage.clear();
      // localStorage.clear();

      // TODO: do we need to reset the navigation?
    }

    // update the timestamps
    this.setState((s) => ({ lastErrorAt: s.errorAt, showError: false }));

    // try restarting the app
    reloadApp();

    // in case restart not possible, just remove the error
    this.setState({ hasError: false });
  }

  // rendering uses intentionally pure React Native
  render() {
    if (this.state.hasError) {
      return (
        <View style={styles.screen}>
          <StatusBar barStyle="dark-content" translucent={true} backgroundColor="white" />
          <View style={styles.contentContainer}>
            <Pressable onLongPress={() => this.setState({ showError: true })}>
              <Image
                source={require("assets/illustrations/error.png")}
                resizeMode="contain"
                accessibilityLabel="Error Illustration"
                style={styles.image}
              />
            </Pressable>
            <Text style={styles.text}>
              Something went wrong. Are you connected to a network? Please try again.
            </Text>
            <View style={styles.buttonContainer}>
              <TouchableOpacity onPress={() => this.handleRetry()} style={styles.button}>
                <Text style={styles.buttonLabel}>Retry</Text>
              </TouchableOpacity>
            </View>
          </View>
          {this.state.showError ? (
            <ScrollView style={styles.errorScroll}>
              <>
                <Text style={styles.errorText}>{String(this.state.error)}</Text>
                {"stack" in this.state.error
                  ? String(this.state.error.stack)
                      .split("\n")
                      .map((line) => {
                        return <Text style={styles.stackText}>{line}</Text>;
                      })
                  : null}
              </>
            </ScrollView>
          ) : null}
        </View>
      );
    } else {
      return this.props.children;
    }
  }
}

const styles = StyleSheet.create({
  screen: {
    position: "absolute",
    flexDirection: "column",
    // justifyContent: "center",
    paddingTop: 90,
    width: "100%",
    height: "100%",
    backgroundColor: "#f0f4f0",
  },
  contentContainer: {
    flexDirection: "column",
    alignItems: "center",
    padding: 8,
    width: "100%",
  },
  image: {
    margin: 16,
    width: 180,
    height: 180,
  },
  text: {
    fontSize: 16,
    fontWeight: "600",
    color: "#010A5A",
    padding: 20,
    textAlign: "center",
  },
  errorScroll: {
    flex: 1,
    flexDirection: "column",
    width: "100%",
    borderColor: "#a0a0a0",
    borderWidth: 1,
    backgroundColor: "white",
  },
  errorText: {
    fontSize: 12,
    fontWeight: "400",
    color: "#010A5A",
    padding: 20,
    textAlign: "center",
  },
  stackText: {
    fontSize: 12,
    fontWeight: "400",
    color: "#010A5A",
    padding: 2,
    textAlign: "center",
  },
  buttonContainer: {
    flexDirection: "row",
    justifyContent: "center",
    width: "100%",
    marginTop: 4,
  },
  button: {
    paddingHorizontal: 16,
    paddingVertical: 8,
    borderRadius: 4,
    backgroundColor: "#115e59",
  },
  buttonLabel: {
    fontSize: 16,
    fontWeight: "400",
    color: "white",
  },
});
