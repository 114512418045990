import React from "react";
import { Box } from "native-base";
import { StatsRow } from "../../../components/primitive/StatsRow";
import { useCustomer } from "../../../model/useCustomer";
import { SectionTitle } from "../../../components/layout/SectionTitle";
import { ActivityBlock } from "./ActivityBlock";
import { Screen } from "../../../components/layout/Screen";
import { CountSection } from "../../../components/primitive/CountSection";
import { FrontAppScreenProps } from "../../../frontAppStack";

export const ActivityScreen = ({ navigation, route }: FrontAppScreenProps<"Activity">) => {
  const { balance } = useCustomer();

  function handleShowRecent(transactionId: string) {
    navigation.push("ShowReceipt", { transactionId });
  }

  const borrowed = balance?.total.borrowed.toFixed() ?? " ";
  const returned = balance ? (balance.total.returned + balance.total.credits).toFixed() : " ";
  const purchased = balance?.total.purchased.toFixed() ?? " ";

  return (
    <Screen name="Activity Details">
      <CountSection
        mt={2}
        count={returned}
        title="Bowls&nbsp;saved from&nbsp;waste"
        imageSource={require("assets/illustrations/Earth-Ecosystem.svg")}
      />
      <Box w="100%" py={2}>
        <SectionTitle title="Your impact since you joined" />
        <StatsRow borrowed={borrowed} returned={returned} purchased={purchased} />
        <ActivityBlock
          accountId={balance.id}
          hasTransactions={balance.total?.borrowed > 0}
          onShowReceipt={handleShowRecent}
        />
      </Box>
    </Screen>
  );
};
