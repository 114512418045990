import { Box, Flex, IButtonProps } from "native-base";
import React from "react";
import { PrimaryButton } from "./PrimaryButton";
import { SecondaryButton } from "./SecondaryButton";

export const TwoButtonGroup: React.FunctionComponent<
  {
    _left: { label: string } & IButtonProps;
    _right: { label: string } & IButtonProps;
  } & IButtonProps
> = (props) => {
  return (
    <Flex flexDirection="row" w="100%">
      <SecondaryButton flex={1} {...props._left} />
      <Box w="12px" />
      <PrimaryButton flex={1} {...props._right} />
    </Flex>
  );
};
