import { IBoxProps, Text } from "native-base";
import React from "react";
import { Section } from "../layout/Sections";
import * as WebBrowser from "expo-web-browser";

export const TCSection: React.FunctionComponent<{} & IBoxProps> = (props) => {
  const openBrowserInApp = async (url: string) => {
    await WebBrowser.openBrowserAsync(url, {
      windowName: "_self",
      presentationStyle: WebBrowser.WebBrowserPresentationStyle.PAGE_SHEET,
    });
  };

  return (
    <Section flexDir="row" width="100%" justifyContent="center">
      <Text mt={4} mx={8} mb={4} fontSize="xs" textAlign="center" width="100%">
        <Text>By continuing, you are indicating that you accept our </Text>
        <Text
          onPress={() => openBrowserInApp("https://www.recirclable.com/terms")}
          underline
          color="black"
        >
          Term of Service
        </Text>
        <Text> and </Text>
        <Text
          onPress={() => openBrowserInApp("https://www.recirclable.com/privacy")}
          underline
          color="black"
        >
          Privacy Policy
        </Text>
        <Text>.</Text>
      </Text>
    </Section>
  );
};
