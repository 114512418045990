import { Platform } from "react-native";
import { detectAppType } from "./device";
import * as Device from "expo-device";

export function isSimulator() {
  if (Platform.OS === "ios") return !Device.isDevice;

  // there is an issue with isDevice on Android
  return false;
}

export function isWebAndroid() {
  return isWeb() && (detectAppType().web === "android" || detectAppType().native === "android");
}

export function isWeb() {
  return Platform.OS === "web";
}

export function isNative() {
  return Platform.OS !== "web";
}

export function isNativeAndroid() {
  return Platform.OS === "android";
}

export function isNativeIOS() {
  return Platform.OS === "ios";
}
