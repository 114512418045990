import _omit from "lodash/omit";
import { Box, IBoxProps, IconButton, Text } from "native-base";
import React from "react";
import { Platform } from "react-native";
import { PrimaryButton } from "./PrimaryButton";
import { VFlex } from "../layout/VFlex";
import { HFlex } from "../layout/HFlex";
import { AddCircleIcon, MinusCircleIcon } from "../icons/Icons";
import { SectionMsg } from "../layout/SectionMsg";

const PlusMinusInput: React.FunctionComponent<{
  value: number;
  min?: number;
  max: number;
  onChange: (value: number) => void;
}> = (props) => {
  return (
    <HFlex justifyContent="center" alignItems="center">
      <IconButton
        icon={<MinusCircleIcon size="6xl" color="teal.800" />}
        isDisabled={props.value <= (props.min ?? 0)}
        onPress={() => {
          props.onChange(Math.max(props.value - 1, props.min ?? 0));
        }}
      />
      <Box
        my={4}
        bg="white"
        borderRadius="md"
        shadow={99}
        w="170px"
        alignContent="center"
        justifyContent="center"
      >
        <Text textAlign="center" fontSize={90} fontWeight="bold" letterSpacing={8}>
          {props.value}
        </Text>
      </Box>
      <IconButton
        icon={<AddCircleIcon size="6xl" color="teal.800" />}
        isDisabled={props.value >= props.max}
        onPress={() => {
          props.onChange(Math.min(props.value + 1, props.max));
        }}
      />
    </HFlex>
  );
};

export const LargeCounterInput: React.FunctionComponent<
  {
    busy?: boolean;
    count: number;
    min?: number;
    max: number;
    message?: string;
    label: string;
    errorMsg?: string | null;
    onChange: (value: number) => void;
    onSubmit: () => void;
  } & IBoxProps
> = (props) => {
  const extraProps = _omit(props, [
    "busy",
    "count",
    "max",
    "message",
    "label",
    "onChange",
    "onSubmit",
  ]);

  return (
    <VFlex alignItems="center" {...extraProps}>
      {props.message ? (
        <Text textAlign="center" fontSize="md" fontWeight={600}>
          {props.message}
        </Text>
      ) : null}
      <VFlex>
        <PlusMinusInput
          value={props.count}
          min={props.min}
          max={props.max}
          onChange={props.onChange}
        />
        <PrimaryButton
          isDisabled={props.count <= 0}
          isLoading={props.busy}
          onPress={props.onSubmit}
          w={Platform.OS === "web" ? "100%" : null}
          label={props.label}
        />
      </VFlex>
      {props.errorMsg && <SectionMsg text={props.errorMsg} type={"error"} />}
    </VFlex>
  );
};
