import firebase from "firebase/compat/app";
import { TypedDoc } from "../lib/firestore/TypedDoc";
import {
  ExternalMetaData,
  TCustomerMode,
  VTransactionData,
  VTransactionTypes,
} from "../lib/apiDefs";
import { Repo } from "../lib/firestore/fstore";

export class VTransactionDoc extends TypedDoc implements VTransactionData {
  profileId!: string;
  customerId!: string; // DEPRECATED: for now = accountId
  accountId!: string;
  mode!: TCustomerMode;
  transactionAt!: Date;
  type!: VTransactionTypes;
  itemQty!: number;
  partnerId?: string;
  locationId?: string;
  locationName?: string;
  outstandingQty?: number;
  expiresAt?: Date;
  external?: ExternalMetaData;
  creditQty?: number;
  purchaseQty?: number;
  itemPrice?: number;

  constructor(snapshot: firebase.firestore.DocumentSnapshot) {
    super(snapshot);
  }
}

export const VTransactions = new (class VTransactions extends Repo<VTransactionDoc> {
  constructor() {
    super("v_transactions", VTransactionDoc);
  }
})();
