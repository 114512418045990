import React, { useImperativeHandle, useMemo, useRef, useState } from "react";
import { WebView } from "react-native-webview";
import {
  ShouldStartLoadRequest,
  WebViewErrorEvent,
  WebViewHttpErrorEvent,
  WebViewMessageEvent,
  WebViewSource,
} from "react-native-webview/lib/WebViewTypes";
import { LogCmd } from "../../../components/webview/LogCmd";
import { appName, getBuildNumber } from "../../../lib/expo/appConfig";
import { WebWaitOverlay } from "../../../components/webview/WebWaitOverlay";
import useError from "react-use/lib/useError";

export type WebContainerProps = {
  url: string;
  title?: string;
  injectScript: string;
  onCanGoBack?: (canGoBack: boolean) => void;
};

const allowedSchemes = ["http", "https"];

// a wrapper around a WebView with just adds functionality to handle the mechanics of an
// embedded WebView
export const OrderWebContainer = React.forwardRef<WebView, WebContainerProps>((props, propsRef) => {
  const dispatchError = useError();
  const [showOverlay, setShowOverlay] = useState(true);
  const webViewRef = useRef<WebView>(null);

  // expose the inner ref to the outside
  useImperativeHandle(propsRef, () => webViewRef.current!, [webViewRef]);

  // check that we navigate only to valid locations
  const shouldLoadRequest = React.useCallback((request: ShouldStartLoadRequest) => {
    console.log(
      `🍏 WEBVIEW load request: ${request.url}`,
      `[main document: ${request.mainDocumentURL}]`,
      `[${request.isTopFrame}]`
    );

    // check that we don't navigate to "about:blank" or anything like that
    // in the main document frame
    if (request.isTopFrame) {
      const reqScheme = request.url.substring(0, request.url.indexOf(":"));
      if (!allowedSchemes.includes(reqScheme)) {
        // .. for now we just throw an error
        dispatchError(new Error("🍎 WEBVIEW unsupported url: " + request.url));
        return false;
      }
    }
    return true;
  }, []);

  // when the page resources are load remove the overlay
  const handleLoadEnd = React.useCallback((event: any) => {
    // remove the overlay with a small delay. looks nicer.
    setTimeout(() => {
      setShowOverlay(false);
    }, 200);
  }, []);

  // some other error
  const handleError = React.useCallback((event: WebViewErrorEvent) => {
    console.log("🍎 WEBVIEW error:", event);
    dispatchError(
      new Error(`🍎 WEBVIEW error: ${event.nativeEvent?.code} + ${event.nativeEvent?.description}`)
    );
    event.preventDefault();
  }, []);

  // HTTP protocol error
  const handleHttpError = React.useCallback((event: WebViewHttpErrorEvent) => {
    console.log("🍎 WEBVIEW http error:", event);
    dispatchError(new Error(`🍎 WEBVIEW http error: ${event.nativeEvent.description}`));
    event.preventDefault();
  }, []);

  // we only support logging as a commanf from the web app
  const handleMessage = React.useCallback((event: WebViewMessageEvent) => {
    try {
      // console.log("🍏 WEBVIEW msg:", event.nativeEvent.data);
      if (event.nativeEvent.data.length < 50000) {
        const msg: Partial<LogCmd> = JSON.parse(event.nativeEvent.data);

        // "log" is the only cmd we handle for now
        if (msg.cmd === "log") {
          if (msg.params?.level === "error") {
            console.log("🍊 EMBEDED WEB ERROR:", msg.params?.message);
          } else {
            console.log("🍏 EMBEDED WEB LOG:", msg.params?.message);
          }
        }
      } else {
        console.error("🍎 WEBVIEW MSG: too long", event.nativeEvent.data);
      }
    } catch (error) {
      console.error("🍎 WEBVIEW MSG: error processing message", event?.nativeEvent?.data, error);
    }
  }, []);

  // if the OS terminates the render process, we just reload. this probably happens
  // when the app is longer in the background.
  const handleTerminate = React.useCallback(() => {
    // NOTE: an alternative would be to notify the parent and navigate out of the web view.
    webViewRef.current?.reload();
  }, [webViewRef]);

  // the unique user agent can be used by the web app to detect the host app
  const userAgent = `${appName()} Online Order/${getBuildNumber()}`;

  // the actual uri loaded must be provided as object
  const source: WebViewSource = useMemo(() => ({ uri: props.url }), [props.url]);

  return (
    <>
      <WebView
        ref={webViewRef}
        style={{ flex: 1, backgroundColor: "#f0f4f0" }}
        /* -- general -- */
        autoManageStatusBarEnabled={false}
        applicationNameForUserAgent={userAgent}
        setBuiltInZoomControls={false}
        geolocationEnabled={true}
        cacheEnabled={true}
        textInteractionEnabled={true}
        // enableApplePay={true} ... Apple Pay still works. Hmmm?
        /* -- cookie -- */
        sharedCookiesEnabled={true}
        domStorageEnabled={true} // Android only
        /* -- rendering related -- */
        contentMode="mobile"
        dataDetectorTypes="none"
        allowsLinkPreview={false}
        textZoom={100}
        // textInteractionEnabled={false}
        /* -- scrolling related -- */
        overScrollMode="never"
        scrollEnabled={true}
        pullToRefreshEnabled={true}
        /* -- video related -- */
        allowsFullscreenVideo={false}
        allowsInlineMediaPlayback={true}
        mediaPlaybackRequiresUserAction={false}
        mediaCapturePermissionGrantType={"grant"}
        /* -- content source -- */
        source={source}
        originWhitelist={["*"]}
        /* -- auth token -- */
        injectedJavaScriptBeforeContentLoaded={props.injectScript}
        /* -- event handler -- */
        onNavigationStateChange={(event) => {
          // sufficient on iOS to figure out whether we can go back
          props.onCanGoBack?.(event.canGoBack);
        }}
        onLoadProgress={(event) => {
          // necessary on Android to figure out whether we can go back (more than once fired)
          props.onCanGoBack?.(event.nativeEvent.canGoBack);
        }}
        onLoadEnd={handleLoadEnd}
        onMessage={handleMessage}
        onShouldStartLoadWithRequest={shouldLoadRequest}
        onContentProcessDidTerminate={handleTerminate}
        onRenderProcessGone={handleTerminate}
        // onNavigationStateChange={(event) => {
        //   console.log("DEBUG WEBVEW NAV STATE", event.url);
        //   return true;
        // }}
        onError={handleError}
        onHttpError={handleHttpError}
      />
      {showOverlay ? <WebWaitOverlay label="Online Ordering ..." /> : null}
    </>
  );
});
