import { ONE_MIN } from "../../util/constants";
import { localStorage } from "../../util/storage";

// postpone deep link navigation. that link is valid for 3min. that should be enough
// time for someone to sign-in/up
const LINK_EXPIRE = 3 * ONE_MIN;
const PENDING_LINK_PATH = "recirclable:pending_link_path";
const PENDING_LINK_EXPIRE = "recirclable:pending_link_expire";

export function getPendingLink() {
  const link = localStorage.get(PENDING_LINK_PATH);
  const expire = localStorage.getInt(PENDING_LINK_EXPIRE, 0);
  if (!!link && !!expire && expire > Date.now()) return link;
  return null;
}

export function setPendingLink(link: string) {
  console.log("SET PENDING LINK", link);
  localStorage.set(PENDING_LINK_PATH, link);
  localStorage.setInt(PENDING_LINK_EXPIRE, Date.now() + LINK_EXPIRE);
}

export function clearPendingLink() {
  console.log("CLEAR PENDING LINK");
  localStorage.remove(PENDING_LINK_PATH);
  localStorage.remove(PENDING_LINK_EXPIRE);
}

export function hasPendingLink(): boolean {
  return !!getPendingLink();
}

export function popPendingLink() {
  if (hasPendingLink()) {
    const link = localStorage.get(PENDING_LINK_PATH);
    console.log("POP PENDING LINK", link);
    clearPendingLink();
    return link;
  }
  return null;
}
