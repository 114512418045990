import { Flex, IBoxProps, IFlexProps } from "native-base";
import React from "react";

export const HFlex = React.forwardRef<unknown, IBoxProps<IFlexProps>>((props, ref) => {
  return (
    <Flex flexDirection="row" {...props}>
      {props.children}
    </Flex>
  );
});
