import { Divider, IBoxProps, Pressable, Text, useDisclose } from "native-base";
import React from "react";
import {
  getApiVersion,
  getBuildNumber,
  getNativeRuntimeVersion,
  getProjectId,
  getRuntimeVersion,
} from "../../lib/expo/appConfig";
import { Section } from "../layout/Sections";
import { isWeb } from "../../util/deviceInfo";
import { FrontRpc } from "../../lib/functions/rpc";
import { useAuthState } from "../../lib/auth/authState";
import useAsync from "react-use/lib/useAsync";
import { useRemoteFlags } from "../../lib/remote_flags/useRemoteFlags";
import { useAuthCustomer } from "../../model/useCustomer";
import { getInstallationId } from "../../lib/installation/installationId";

export const VersionSection = (props: IBoxProps) => {
  const { isOpen, onToggle } = useDisclose();

  return (
    <Section mb={8} {...props}>
      <Pressable onLongPress={onToggle}>
        <Text textAlign="center" color="muted.400" fontSize="xs">
          {`\u00A9 Recirclable LLC, ${new Date().getFullYear()}`}
        </Text>
      </Pressable>
      {isOpen ? <VersionDetails /> : null}
    </Section>
  );
};

export const VersionDetails = () => {
  const isAuthenticated = useAuthState((s) => s.isAuthenticated);
  const { value: installationId } = useAsync(getInstallationId);
  const { user, profile, balance } = useAuthCustomer();
  const versionState = useAsync(async () => {
    if (!isAuthenticated) return null;
    return FrontRpc.call("versionInfo", {});
  }, [isAuthenticated]);
  const featureFlags = useRemoteFlags();

  return (
    <>
      <Divider color="muted.400" h="1px" my={4} />
      {isAuthenticated && (
        <>
          <Text textAlign="center" color="muted.400" fontSize="xs">
            User: {user?.uid?.substring(0, 8)}
          </Text>
          <Text textAlign="center" color="muted.400" fontSize="xs">
            Profile: {profile?.id?.substring(0, 8)}
          </Text>
          <Text textAlign="center" color="muted.400" fontSize="xs">
            Balance: {balance?.id?.substring(0, 8)}
          </Text>
          <Text textAlign="center" color="muted.400" fontSize="xs">
            Installation: {installationId ?? ""}
          </Text>
        </>
      )}
      <Text mt={3} textAlign="center" color="muted.400" fontSize="xs">
        Project: {getProjectId()}
      </Text>
      {isWeb() && (
        <>
          <Text mt={3} textAlign="center" color="muted.400" fontSize="xs">
            Web Host: {window.location.hostname}
          </Text>
          <Text textAlign="center" color="muted.400" fontSize="xs">
            Web: {getBuildNumber()}
          </Text>
        </>
      )}
      {!isWeb() && (
        <>
          <Text mt={3} textAlign="center" color="muted.400" fontSize="xs">
            Bundle: {getBuildNumber()}
          </Text>
          <Text textAlign="center" color="muted.400" fontSize="xs">
            Runtime (requested): {getRuntimeVersion()}
          </Text>
          <Text textAlign="center" color="muted.400" fontSize="xs">
            Runtime (actual): {getNativeRuntimeVersion()}
          </Text>
        </>
      )}
      <Text textAlign="center" color="muted.400" fontSize="xs">
        App API: {getApiVersion()}
      </Text>
      <Text textAlign="center" color="muted.400" fontSize="xs">
        Backend: {versionState.value ? versionState.value.version : "-"}
      </Text>
      <Text mt={3} textAlign="center" color="muted.400" fontSize="xs">
        Feature Flags: {JSON.stringify(featureFlags)}
      </Text>
    </>
  );
};
