import { createNavDef } from "../../components/navigation/NavigationDefinitions";
import SignInScreen from "./auth/SignInScreen";
import { Welcome1Screen } from "./welcome/Welcome1Screen";
import { Welcome2Screen } from "./welcome/Welcome2Screen";
import { EmailSignInScreen } from "./auth/EmailSignInScreen";
import { PhoneSignInScreen } from "./auth/PhoneSignInScreen";
import { PremableItemInfoScreen } from "./item/PreambleItemInfoScreen";
import { NativeStackScreenProps } from "@react-navigation/native-stack";

export type AuthProviderScreenParamList = {
  EmailSignIn:
    | {
        // NOTE: these parameters are only provided by Firebase in case of a password reset
        mode?: string;
        oobCode?: string;
        apiKey?: string;
        continueUrl?: string;
      }
    | undefined;
  PhoneSignIn: undefined;
};

export type PreambleScreenParamList = {
  Welcome1: undefined;
  Welcome2: undefined;
  SignIn: {
    redirectError?: any;
    otherProviderEmail?: string | null;
    otherProviderId?: string | null;
  };
  ItemInfo: undefined;
} & AuthProviderScreenParamList;

export type PreambleScreenProps<Screen extends keyof PreambleScreenParamList> =
  NativeStackScreenProps<PreambleScreenParamList, Screen>;

export const preambleNavDef = createNavDef({
  postponeLinks: true,
  fallbackPath: "a/signin",
  screens: {
    Welcome1: {
      component: Welcome1Screen,
      title: "Welcome",
    },
    Welcome2: {
      component: Welcome2Screen,
      title: "How It Works",
    },
    SignIn: {
      component: SignInScreen,
      title: "Sign In/Up",
      path: "a/signin",
    },
    EmailSignIn: {
      component: EmailSignInScreen,
      title: "Email Sign In/Up",
      path: "a/email-signin",
    },
    PhoneSignIn: {
      component: PhoneSignInScreen,
      title: "Phone Sign In/Up",
      path: "a/phone-signin",
    },
    ItemInfo: {
      component: PremableItemInfoScreen,
      title: "Item Info",
      path: "a/item/:itemId",
    },
  },
});
