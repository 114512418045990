import { isAndroid, isIOS } from "react-device-detect";
import { AppInfoType } from "../../../backend-types/src";

export function detectAppType(): AppInfoType {
  let os: AppInfoType["native"] | AppInfoType["web"] = "other";
  if (isIOS) {
    os = "ios";
  } else if (isAndroid) {
    os = "android";
  }
  return { web: os };
}
