import { useNavigation } from "@react-navigation/native";
import { useCallback } from "react";

export function useCloseScreen() {
  const navigation = useNavigation();

  return useCallback((backScreen?: string) => {
    if (navigation.canGoBack()) {
      navigation.goBack();
    } else {
      /* navigating back from the PaymentSetupScreen should be the only situation, where
         there is no back screen. but when cloesScreen() is called, the preamble stack is
         already installed. so just navigate to the first screen in that stack. this is also
         a safe version, in case there is no backwards screen in other situations. */
      navigation.navigate(navigation.getState().routeNames[0]);
    }
  }, []);
}
