import { Box, Text } from "native-base";
import { HFlex } from "./HFlex";
import { ChevronForwardIcon } from "../icons/Icons";
import { Pressable } from "react-native";
import { cloneElement } from "react";

export function EnhancedSectionButton(props: {
  label: string;
  tag?: string;
  tagColor?: string;
  isDisabled?: boolean;
  iconLeft?: React.ReactNode;
  iconRight?: React.ReactNode;
  onPress?: () => void;
}) {
  if (props.isDisabled) {
    const styledIconLeft =
      props.iconLeft && cloneElement(props.iconLeft as any, { color: "gray.400", size: "md" });
    const styledIconRight =
      props.iconRight && cloneElement(props.iconRight as any, { color: "gray.400", size: "md" });
    return (
      <HFlex p={2} px={3} justifyContent="space-between">
        <HFlex>
          <Box alignSelf="center">{styledIconLeft}</Box>
          <Text ml={1} fontSize="md" color="gray.400">
            {props.label}
          </Text>
        </HFlex>
        <Box alignSelf="center">{styledIconRight}</Box>
      </HFlex>
    );
  } else {
    const styledIconLeft =
      props.iconLeft && cloneElement(props.iconLeft as any, { color: "primaryText", size: "md" });
    return (
      <Pressable onPress={props.onPress}>
        <HFlex flexDirection="row" p={2} px={3} justifyContent="space-between" alignItems="center">
          <HFlex>
            <Box alignSelf="center">{styledIconLeft}</Box>
            <Text ml={2} fontSize="md">
              {props.label}
            </Text>
          </HFlex>
          <HFlex>
            {props.tag && (
              <Box bg={props.tagColor ?? "primaryText"} borderRadius={4} px={2}>
                <Text color="white">{props.tag}</Text>
              </Box>
            )}
            <Box alignSelf="center">
              <ChevronForwardIcon size={"md"} />
            </Box>
          </HFlex>
        </HFlex>
      </Pressable>
    );
  }
}
