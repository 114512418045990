import firebase from "firebase/compat/app";
import { firebaseAuth } from "../firebase/fbenv";
import { create, StoreApi } from "zustand";
import { localStorage, sessionStorage } from "../../util/storage";
import { FUNC_NOOP } from "../../util/constants";
import { detectAppType } from "../../util/device";
import { getApiVersion, getAppVersion } from "../expo/appConfig";
import { sentry } from "../sentry/sentry";
import { appAnalytics } from "../analytics/analytics";
import { BackRpc, FrontRpc } from "../functions/rpc";
import { getInstallationId } from "../installation/installationId";

//
// support for a preferred auth provider
//

// whenever a provider is selected on the SignIn screen the provider
// is recorded here as a sign-in attempt. when succesful that provider
// gets copied as preferred provider. taking the provder from the user
// record doesn't work, because the user can have two.

const AUTH_ATTEMPT_PROVIDER_KEY = "recirclable:auth_attempt_provider";
export function setAttemptProvider(providerId?: string | null) {
  sessionStorage.set(AUTH_ATTEMPT_PROVIDER_KEY, providerId);
}
export function getAttemptProvider() {
  const value = sessionStorage.get(AUTH_ATTEMPT_PROVIDER_KEY);
  return value;
}

const AUTH_PROVIDER_KEY = "recirclable:auth_preferred_provider";
export function setPreferredProvider(providerId?: string | null) {
  localStorage.set(AUTH_PROVIDER_KEY, providerId);
}
export function getPreferredProvider() {
  const value = localStorage.get(AUTH_PROVIDER_KEY);
  return value;
}

//
// Auth State definition
//

export type AuthStateOptions = {
  serverAfterSignIn?: boolean;
};

export type AuthStatePending = {
  isPending: true;
  isAuthenticated: false;
  redirectError: undefined;
  user: undefined;
  isPhoneSignIn?: undefined;
  isEmailSignIn?: undefined;
  isSocialSignIn?: undefined;
  hasGoogleProvider?: undefined;
  hasAppleProvider?: undefined;
  signInProviderId?: undefined;
  claims?: undefined;
  start: (opts?: AuthStateOptions) => void;
  stop: () => Promise<void>;
  signOut: () => Promise<void>;
};

const AUTH_PENDING: Omit<AuthStatePending, "start" | "stop" | "signOut" | "refresh"> = {
  isPending: true,
  isAuthenticated: false,
  redirectError: undefined,
  user: undefined,
};

export type AuthStateNotAuthenticated = {
  isPending: false;
  isAuthenticated: false;
  redirectError?: any;
  user: undefined;
  isPhoneSignIn?: undefined;
  isEmailSignIn?: undefined;
  isSocialSignIn?: undefined;
  hasGoogleProvider?: undefined;
  hasAppleProvider?: undefined;
  signInProviderId?: undefined;
  claims?: undefined;
  start: (opts?: AuthStateOptions) => void;
  stop: () => Promise<void>;
  signOut: () => Promise<void>;
};

const AUTH_NOT_AUTHENTICATED: Omit<
  AuthStateNotAuthenticated,
  "start" | "stop" | "signOut" | "refresh"
> = {
  isPending: false,
  isAuthenticated: false,
  // note: don't set redirectError here
  user: undefined,
};

export type AuthStateAuthenticated = {
  isPending: false;
  isAuthenticated: true;
  redirectError: undefined;
  user: firebase.User;
  isPhoneSignIn?: boolean;
  isEmailSignIn?: boolean;
  isSocialSignIn?: boolean;
  hasGoogleProvider?: boolean;
  hasAppleProvider?: boolean;
  signInProviderId?: string | null;
  claims?: any;
  start: (opts?: AuthStateOptions) => void;
  stop: () => Promise<void>;
  signOut: () => Promise<void>;
};

export type AuthState = AuthStatePending | AuthStateAuthenticated | AuthStateNotAuthenticated;

export const useAuthState = create<AuthState>((set, get) => {
  let options: AuthStateOptions = { serverAfterSignIn: true };
  let unsubscribe = FUNC_NOOP;

  // inner function, which does the actual state update
  function updateUser(user: firebase.User | null, opts: AuthStateOptions) {
    // we also read in the beginning currentUser and explicitly apply it. this check
    // filters out the unnecessary update from the listener. And yes ... use object
    // equality.
    if (!get().isPending && user === get().user) {
      console.log("AUTH STATE: no change", user);
      return;
    }

    console.log("AUTH STATE: change", user);
    if (user) {
      if (user.email || user.phoneNumber) {
        console.log("AUTH STATE: authenticated", user.email, user.phoneNumber);

        const hasGoogleProvider = user.providerData.some(
          (p) => p?.providerId === firebase.auth.GoogleAuthProvider.PROVIDER_ID
        );
        const hasAppleProvider = user.providerData.some((p) => p?.providerId === "apple.com");
        set({
          isPending: false,
          isAuthenticated: true,
          redirectError: undefined,
          user,
          hasGoogleProvider,
          hasAppleProvider,
        });

        // ASYNC IN BACKGROUND: copy some values from the token
        user
          .getIdTokenResult()
          .then((result) => {
            const signInProviderId = result.signInProvider;
            const isPhoneSignIn =
              signInProviderId === firebase.auth.PhoneAuthProvider.PROVIDER_ID ||
              signInProviderId === "custom" ||
              signInProviderId === "unknown";
            const isSocialSignIn =
              signInProviderId === firebase.auth.GoogleAuthProvider.PROVIDER_ID ||
              signInProviderId === "apple.com";
            const isEmailSignIn =
              signInProviderId === firebase.auth.EmailAuthProvider.PROVIDER_ID || isSocialSignIn;
            set({
              signInProviderId,
              isPhoneSignIn,
              isEmailSignIn,
              isSocialSignIn,
              claims: result.claims, // claims, will be used for roleState
            });
          })
          .catch((error) => {});

        // ASYNC IN BACKGROUND: send some user data to the server
        if (opts.serverAfterSignIn) {
          const appInfo = detectAppType();
          appInfo.apiVersion = getApiVersion();
          appInfo.appVersion = getAppVersion();

          // the afterSignIn call records information about the app and the sign-in. it also fixes the
          // user record in one special case. it copies a phone number back into the user profile (see
          // doc for afterSignIn).it's a "fire and forget", so no waiting and no action necessary.
          getInstallationId().then((installationId) => {
            FrontRpc.fire("afterSignIn", { app: { ...appInfo, installationId } });
          });
        }

        // preferred provider is:
        // - a provide was selected in this session --> that must be the one successful
        // - something found in the user credentials
        //
        // TODO: fix me. find a better way. our own phone based login shows up with the
        //       provider id "custom".
        setPreferredProvider(getAttemptProvider() ?? user.providerData[0]?.["providerId"]);

        // track user id
        sentry().setUser({ id: user.uid });
        appAnalytics().setUserId(user.uid);
      } else {
        // NOTE: this should never happen. there should be always an email address or phone number.
        //       we can't raise an exception, so just reset the auth state and log the situation.
        console.error("AUTH STATE: user has neither an email nor a phone number");
        firebaseAuth().signOut(); // that at least resets the auth state, so that app can also reset

        set(AUTH_NOT_AUTHENTICATED);
        sentry().configureScope((scope) => scope.setUser(null));
      }
    } else {
      console.log("AUTH STATE: user is null");

      set(AUTH_NOT_AUTHENTICATED);
      sentry().configureScope((scope) => scope.setUser(null));
    }
  }

  let store: AuthState = {
    ...AUTH_PENDING,

    start: (opts?: AuthStateOptions) => {
      Object.assign(options, opts);

      console.log("AUTH STATE: start", get());

      // if we already have a user (e.g. from a previous run, then immediately set it)
      if (firebaseAuth().currentUser) {
        console.log("AUTH STATE: user was already set at start");
        updateUser(firebaseAuth().currentUser, options);
      }

      // IMPORTANT: when the store is initialized, it's not yet listening to the auth state.
      // if we would listen then, firebase auth is required, which requires the projectId,
      // which requires the app manifest, which is on production not available while JS global
      // code executes. this is because expo runs it's update logic independent of the app.
      // so start listening lazy with start()
      if (unsubscribe === FUNC_NOOP) {
        console.log("AUTH STATE: start listening");
        unsubscribe = firebaseAuth().onAuthStateChanged((user) => {
          updateUser(user, options);
        });
      }
    },

    stop: async () => {
      // set an unauthenticated state. this will also trigger all subscribers.
      set(AUTH_NOT_AUTHENTICATED);

      // ... then stop listening to Firebase.
      unsubscribe();
      unsubscribe = FUNC_NOOP;

      // ... then make sure we are really signed out
      await firebaseAuth().signOut();
    },

    signOut: () => {
      return authSignOut();
    },
  };

  return store;
});

// -----  utility functions  -----

// sign out the current user
export async function authSignOut() {
  // this doesn't work. error message says, that the cache can be cleared only before the
  // firestore instances is initialized or after it's terminated. research whether we really
  // need to clear the cache. probably not.
  // await firebaseFirestore().clearPersistence();
  await firebaseAuth().signOut();
  return;
}

// retrieve a custom token for the current user. this is used to path an auth
// state into the web view.
export async function retrieveAuthToken(): Promise<string | undefined> {
  try {
    const user = useAuthState.getState().user;
    if (user) {
      const idToken = await user.getIdToken(true);
      const { token } = await BackRpc.call("authToken", { idToken });
      if (token) return token;
    }
  } catch (error) {
    console.error("🍎 error retrieving auth token", error);
  }
}
