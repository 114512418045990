import { useCallback } from "react";
import * as MailComposer from "expo-mail-composer";
import { useAlerts } from "../components/modals/AlertProvider";

export function useMailComposer() {
  const alerts = useAlerts();
  const compose = useCallback((opts: { to: string; subject: string; body: string }) => {
    MailComposer.isAvailableAsync().then((available) => {
      if (available) {
        MailComposer.composeAsync({
          recipients: [opts.to],
          subject: opts.subject,
          body: opts.body,
        });
      } else {
        alerts?.showAlertOk({
          title: "Send Email",
          text: "Please configure a default email app before you can send a support request.",
        });
      }
    });
  }, []);
  return compose;
}
