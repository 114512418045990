import { Box, IButtonProps, Pressable, Text } from "native-base";
import React from "react";
import { HFlex } from "./HFlex";
import { ChevronForwardIcon } from "../icons/Icons";

export const SectionButton: React.FunctionComponent<
  {
    label: string;
    value?: string;
    fontSize?: string;
    icon?: JSX.Element;
    onPress?: () => void;
  } & IButtonProps
> = (props) => {
  return (
    <Pressable onPress={props.onPress} isDisabled={props.isDisabled}>
      <HFlex flexDirection="row" p={2} px={3} justifyContent="space-between" alignItems="center">
        {props.icon ? (
          <>
            {props.icon}
            <Box w="14px" />
          </>
        ) : null}
        <Text fontSize={props.fontSize ? props.fontSize : "md"}>{props.label}</Text>
        <Box flexGrow={1} />
        <Text mx={1} fontSize={props.fontSize ? props.fontSize : "md"} color={"gray.400"}>
          {props.value}
        </Text>
        <ChevronForwardIcon size={"md"} />
      </HFlex>
    </Pressable>
  );
};
