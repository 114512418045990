import _cloneDeep from "lodash/cloneDeep";
import _kebabCase from "lodash/kebabCase";
import _merge from "lodash/merge";
import { PartialDeep } from "type-fest";

// the basic info we need for our screen definitions
export type ScreenDefinition = {
  component: any;
  title?: string;
  path?: string;
  style?: "modal" | "from_bottom" | "replace" | "default";
};

// the definition for a whole stack of screens
export type ScreenDefinitions<S extends string> = {
  [k in S]: ScreenDefinition;
};

// a map with path rewrites
export type PathRewrites = Record<string, string>;

export type NavigationDefinition<S extends string> = {
  // optional prefix, which all paths must start with. this ensures for example
  // that all admin path start with "/a/admin" and that we can use it in the
  // index.js to select the right app.
  pathPrefix?: string;

  // flag, whether unknown links are postponed to later handling. this is only enabled for
  // preamble screens, where we want to postpone navigation. it doesn't happen for the actual
  // app.
  postponeLinks?: boolean;

  // optional link, which is opened for a unknown link. used in the preamble to navigation
  // to the sign-in screen
  fallbackPath?: string;

  // the initial route name. will be set automatically to the first screen definition
  initialScreen: string;

  // the actual screen definitions
  screens: ScreenDefinitions<S>;
};

export function createNavDef<S extends string>(
  source: PartialDeep<NavigationDefinition<S>>
): NavigationDefinition<S> {
  // copy and aply defaults
  const target: NavigationDefinition<S> = Object.assign(
    { redirects: {}, screens: {} },
    _cloneDeep(source) as any
  );

  // set the initial screen
  const initialScreen = Object.getOwnPropertyNames(source.screens)[0];
  target.initialScreen = initialScreen;

  // derive default paths from the screen names
  (Object.keys(target.screens) as S[]).forEach((name) => {
    const screenDef = target.screens[name];
    screenDef.path = screenDef.path ?? `${source.pathPrefix ?? "a/"}${_kebabCase(name)}`;
  });

  return target;
}
