import React from "react";
import { Section } from "./components/layout/Sections";
import { Image, Text } from "native-base";
import { PrimaryButton } from "./components/primitive/PrimaryButton";
import { VFlex } from "./components/layout/VFlex";
import useLocalStorage from "react-use/lib/useLocalStorage";
import { safe } from "./util/safe";
import { isDevelopment, isEmulator } from "./lib/firebase/ifEnv";
import { ONE_DAY, ONE_MIN } from "./util/constants";
import { FlatScreen } from "./components/layout/FlatScreen";
import { HFlex } from "./components/layout/HFlex";
import { HeroSection } from "./components/layout/HeroSection";
import { sentry } from "./lib/sentry/sentry";

const ENABLED = true;
const DEBUG_ALWAYS = false;

export const AppStoreBanner = (props: { children?: React.ReactNode }) => {
  const [bannerAt, setBannertAt] = useLocalStorage("DID_SHOW_APP_STORE_BANNER", 0);
  const showBanner =
    ENABLED &&
    (DEBUG_ALWAYS ||
      !bannerAt ||
      safe.parseInt(bannerAt) < Date.now() - (isEmulator() || isDevelopment() ? ONE_MIN : ONE_DAY));

  const handleDismiss = React.useCallback(() => {
    sentry().captureException(new Error(`customer dismissed app download banner`));
    setBannertAt(Date.now());
  }, []);

  if (showBanner) {
    return (
      <FlatScreen name="Install Recirclable App">
        <HeroSection h={100} mt={0} image={require("assets/logo/logo-with-tm.svg")} />
        <VFlex>
          <Section>
            <VFlex p={4}>
              <Text fontWeight="bold">
                Download the Recirclable App for the richest and most reliable experience:
              </Text>
              <Text>{"\u2022"} Quick performance</Text>
              <Text>{"\u2022"} Check your current balance at any time</Text>
              <Text>{"\u2022"} Order online from select restaurants</Text>
            </VFlex>
          </Section>
          <Section>
            <HFlex p={4} justifyContent="space-between">
              <a href="https://apps.apple.com/us/app/recirclable/id1638609156">
                <Image
                  alt="Download from App Store"
                  src="/app-store-badge.svg"
                  resizeMode="contain"
                  width="152px"
                  height="50px"
                />
              </a>
              <a href="https://play.google.com/store/apps/details?id=com.recirclable.recirclable">
                <Image
                  alt="Download from Play Store"
                  src="/google-play-badge.png"
                  resizeMode="contain"
                  width="172px"
                  height="50px"
                />
              </a>
            </HFlex>
          </Section>
        </VFlex>
        <Section mt={2}>
          <PrimaryButton my={1} onPress={handleDismiss} label="Skip for now" />
        </Section>
      </FlatScreen>
    );
  } else {
    return props.children;
  }
};
