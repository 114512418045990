import { safe } from "./safe";
import { SafeStorage } from "./storageType";

export function createSafeStorage(storage: Storage): SafeStorage {
  return {
    get: (key: string, defaultValue?: string): string | undefined => {
      return storage.getItem(key) ?? defaultValue;
    },

    getInt: (key: string, defaultValue?: number): number => {
      return safe.parseInt(storage.getItem(key), defaultValue);
    },

    set: (key: string, value?: string | null): void => {
      if (value) storage.setItem(key, value);
      else storage.remove(key);
    },

    setInt: (key: string, value: number): void => {
      storage.setItem(key, value.toFixed());
    },

    // increments a numeric value and returns the value before the increment
    inc: (key: string): number => {
      const count = safe.parseInt(storage.getItem(key), 0);
      storage.setItem(key, (count + 1).toFixed());
      return count;
    },

    remove: (key: string): void => {
      storage.removeItem(key);
    },

    clear: (prefix?: string) => {
      if (prefix) {
        for (var i = 0, len = storage.length; i < len; ++i) {
          const key = storage.key(i);
          if (key && key.startsWith(prefix)) storage.removeItem(key);
        }
      } else {
        storage.clear();
      }
    },
  };
}

export const localStorage: SafeStorage = createSafeStorage(window.localStorage);
export const sessionStorage: SafeStorage = createSafeStorage(window.sessionStorage);
