import React, { useCallback, useState } from "react";
import { Section, BoxSection } from "../../../components/layout/Sections";
import { Box, Divider, Input, Radio, Text, useDisclose, useToast } from "native-base";
import { useCustomer } from "../../../model/useCustomer";
import { SecondaryButton } from "../../../components/primitive/SecondaryButton";
import useAsyncFn from "react-use/lib/useAsyncFn";
import { VersionSection } from "../../../components/footer/VersionSection";
import { ModalDeleteAccount } from "./ModalDeleteAccount";
import * as WebBrowser from "expo-web-browser";
import { authSignOut, useAuthState } from "../../../lib/auth/authState";
import { BackOfficeHeaderButtons } from "../../../components/navigation/BackOfficeHeaderButtons";
import { Screen } from "../../../components/layout/Screen";
import { FrontAppScreenProps } from "../../../frontAppStack";
import {
  CardIcon,
  EmailIcon,
  KeyIcon,
  LogoAppleIcon,
  LogoGoogleIcon,
  PhoneIcon,
} from "../../../components/icons/Icons";
import { FrontRpc } from "../../../lib/functions/rpc";
import { useMailComposer } from "../../../hooks/useMailComposer";
import { EnhancedSectionButton } from "../../../components/layout/EnhancedSectionButton";
import { ChangeProfileOptions } from "../../../lib/apiDefs";

export const ProfileScreen = ({ navigation, route }: FrontAppScreenProps<"Profile">) => {
  const composeMail = useMailComposer();
  const { balance, profile } = useCustomer();
  const [name, setName] = useState<string>(profile.name ?? "");
  const [nameFocus, setNameFocus] = useState<boolean>(false);
  const [notification, setNotification] = useState<"phone" | "email">(profile.notification.method);

  const openBrowserInApp = async (url: string) => {
    await WebBrowser.openBrowserAsync(url, {
      windowName: "_self",
      presentationStyle: WebBrowser.WebBrowserPresentationStyle.PAGE_SHEET,
    });
  };

  const [_changeState, callChangeProfile] = useAsyncFn(async (options: ChangeProfileOptions) => {
    const result = await FrontRpc.call("changeProfile", options);
    return result;
  });

  const { isOpen, onOpen, onClose } = useDisclose();

  function handleNameInputChange(text: string) {
    setName(text);
  }

  const handleSubmitNameChange = useCallback(async () => {
    await callChangeProfile({ name });
  }, [name]);

  function handleSubmitNotificationChange(value: string) {
    if (value === "email" || value === "phone") {
      setNotification(value);
      callChangeProfile({ notification: value });
    }
  }

  async function handleSupport() {
    composeMail({
      to: "hello@recirclable.com",
      subject: `Support Question [${balance.id.substring(0, 6)}]`,
      body: "Please write us an email with your support question. Thank you!",
    });
  }

  async function handleSignOut() {
    await authSignOut();
    // nothing else to do. MainNavigation will swap out the screens.
  }

  function handleDelete() {
    //check if the user have outstanding bowls
    if (balance.outstandingSum ?? 0) {
      onOpen();
    } else {
      navigation.push("DeleteAccount");
    }
  }

  return (
    <Screen name="Profile" right={<BackOfficeHeaderButtons />}>
      <BoxSection title="Name" divider={<Divider w="100%" />}>
        <Input
          nativeID="name"
          flex={1}
          fontSize="md"
          placeholder={nameFocus ? "" : "Add First & Last Name"}
          placeholderTextColor="primaryText"
          returnKeyType="done"
          autoComplete="name"
          autoCorrect={false}
          value={name}
          onChangeText={handleNameInputChange}
          onFocus={() => setNameFocus(true)}
          onBlur={handleSubmitNameChange}
        />
      </BoxSection>

      <BoxSection title="Contact" divider={<Divider w="100%" />}>
        <ChangeEmailButton
          email={profile.email}
          onPress={() => navigation.push("ProfileChangeEmail")}
        />
        <ChangePhoneButton
          phone={profile.phone}
          phoneVerified={profile.phoneVerified}
          onPress={() => navigation.push("ProfileChangePhone")}
        />
      </BoxSection>
      <BoxSection title="Notification">
        <ChangeNotificationGroup
          email={profile.email}
          phone={profile.phone}
          notification={notification}
          onChange={handleSubmitNotificationChange}
        />
      </BoxSection>

      <BoxSection title="Payment">
        <EnhancedSectionButton
          label={profile?.paymentInfo ?? ""}
          iconLeft={<CardIcon />}
          onPress={() => navigation.push("PaymentChange")}
        />
      </BoxSection>

      <BoxSection title="Need some help?">
        <EnhancedSectionButton label="Support" onPress={handleSupport} />
        <Divider w="100%" />
        <EnhancedSectionButton
          label="FAQ"
          onPress={() => openBrowserInApp("https://www.recirclable.com/faq")}
        />
        <Divider w="100%" />
        <EnhancedSectionButton
          label="About"
          onPress={() => openBrowserInApp("https://www.recirclable.com")}
        />
      </BoxSection>

      <Section mt={16}>
        <SecondaryButton my={1} onPress={handleSignOut} label="Sign Out" />
        <SecondaryButton my={1} onPress={handleDelete} label="Delete Account" />
      </Section>

      <VersionSection />
      <ModalDeleteAccount isOpen={isOpen} onClose={onClose} balance={balance.outstandingSum ?? 0} />
    </Screen>
  );
};

function ChangePhoneButton(props: {
  phone?: string | null;
  phoneVerified?: boolean;
  onPress: () => void;
}) {
  const { signInProviderId, isPhoneSignIn } = useAuthState((s) => ({
    signInProviderId: s.signInProviderId,
    isPhoneSignIn: s.isPhoneSignIn,
  }));

  const isDisabled = !signInProviderId || isPhoneSignIn;

  let phoneChangeText;
  if (props.phone) {
    phoneChangeText = props.phone;
  } else {
    phoneChangeText = "Add Phone Number";
  }

  const needsVerification = props.phone && !props.phoneVerified;

  return (
    <EnhancedSectionButton
      label={phoneChangeText}
      tag={needsVerification ? "Please verify" : undefined}
      tagColor="danger.700"
      isDisabled={isDisabled}
      iconLeft={<PhoneIcon />}
      iconRight={isPhoneSignIn ? <KeyIcon /> : undefined}
      onPress={props.onPress}
    />
  );
}

function ChangeEmailButton(props: { email?: string | null; onPress: () => void }) {
  const { signInProviderId, isEmailSignIn, hasGoogleProvider, hasAppleProvider } = useAuthState(
    (s) => ({
      signInProviderId: s.signInProviderId,
      isEmailSignIn: s.isEmailSignIn,
      hasGoogleProvider: s.hasGoogleProvider,
      hasAppleProvider: s.hasAppleProvider,
    })
  );
  const isDisabled = !signInProviderId || isEmailSignIn || hasGoogleProvider || hasGoogleProvider;

  const label = props.email ?? "Add Email Address";
  let iconLeft = <EmailIcon />;
  if (hasGoogleProvider) {
    iconLeft = <LogoGoogleIcon />;
  } else if (hasAppleProvider) {
    iconLeft = <LogoAppleIcon />;
  }

  return (
    <EnhancedSectionButton
      label={label}
      isDisabled={isDisabled}
      iconLeft={iconLeft}
      iconRight={isEmailSignIn ? <KeyIcon /> : undefined}
      onPress={props.onPress}
    />
  );
}

function ChangeNotificationGroup(props: {
  notification?: string;
  email?: string | null;
  phone?: string | null;
  onChange: (value: string) => void;
}) {
  return (
    <Radio.Group
      name="notificationMethod"
      accessibilityLabel="Select Notification Method"
      colorScheme="blueGray"
      value={props.notification}
      onChange={props.onChange}
    >
      <Box p={2} px={3} w="100%">
        <Radio
          value="email"
          size="sm"
          _text={{ color: "primaryText" }}
          _disabled={{
            _text: {
              color: "gray.600",
            },
          }}
          isDisabled={!props.email}
          justifyContent="flex-start"
        >
          Notify with Email
        </Radio>
      </Box>
      <Box p={2} px={3} w="100%">
        <Radio
          value="phone"
          size="sm"
          _text={{ color: "primaryText" }}
          _disabled={{
            _text: {
              color: "gray.600",
            },
          }}
          isDisabled={!props.phone}
          justifyContent="flex-start"
        >
          Notify with Text Message
        </Radio>
      </Box>
    </Radio.Group>
  );
}
