import semver from "semver";
import { getAppVersion } from "../lib/expo/appConfig";

type VersionJsonType = {
  warnAppVersion?: string;
  minAppVersion?: string;

  // used in older apps
  minApiVersion?: string;
};

export type VersionCheckResult = {
  outdated: boolean; // true -> outdated, show update screen
  required: boolean; // true -> too old, require update
};

const VERSION_CHECK_OK: VersionCheckResult = {
  outdated: false,
  required: false,
};

export function compareVersion(versionJson: VersionJsonType): VersionCheckResult {
  try {
    if (versionJson) {
      const appVersion = getAppVersion();
      if (appVersion) {
        if (
          versionJson.minAppVersion &&
          semver.compare(appVersion, versionJson.minAppVersion) < 0
        ) {
          return { outdated: true, required: true };
        }
        if (
          versionJson.warnAppVersion &&
          semver.compare(appVersion, versionJson.warnAppVersion) < 0
        ) {
          return { outdated: true, required: false };
        }
      }
    }
  } catch (error) {
    /* ignore error from loading the version file */
    console.error("VERSION: error checking version information", error);
  }
  return VERSION_CHECK_OK;
}
