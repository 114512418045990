import React from "react";
import { ImageSourcePropType } from "react-native";
import { AssetView } from "../primitive/AssetView";
import { ShadowedSection } from "./Sections";

const IMAGE_SIZE = 180;
export const HeroSection: React.FunctionComponent<{
  image?: ImageSourcePropType;
  alt?: string;
  mt?: number;
  h?: number;
}> = (props) => {
  const height = props.h ?? IMAGE_SIZE;

  return (
    <ShadowedSection bg="#d1eff7" mt={props.mt ?? -2} alignItems="center">
      <AssetView
        my={2}
        source={props.image ?? require("assets/images/placeholder.png")}
        alt={props.alt ?? "Placeholder"}
        resizeMode="contain"
        w={height}
        h={height}
      />
    </ShadowedSection>
  );
};
