import firebase from "firebase/compat/app";
import _clone from "lodash/clone";
import _omit from "lodash/omit";
import _cloneDeepWith from "lodash/cloneDeepWith";
import { ERROR_DOC_ID } from "./fstore";

// base class for all documents on the client side
export class TypedDoc {
  _ref: firebase.firestore.DocumentReference;
  _snapshot: firebase.firestore.DocumentSnapshot;

  id: string;
  v?: number;
  createdAt?: Date;
  updatedAt?: Date;

  constructor(snapshot: firebase.firestore.DocumentSnapshot) {
    if (snapshot.exists) {
      this._ref = snapshot.ref;
      this._snapshot = snapshot;
      this.id = snapshot.id;
      Object.assign(this, this.data());
    } else {
      // NOTE: incase the object doesn't exit, we create a special placeholder. in case the app
      //       continues, it will never lead to conflicts with the existing data.
      this._ref = snapshot.ref.parent.doc(ERROR_DOC_ID);
      this._snapshot = {} as any;
      this.id = ERROR_DOC_ID;
    }
  }

  // actual conversion from Firestore data into our representation.
  private data() {
    return _cloneDeepWith(this._snapshot.data(), (value: any) => {
      if (value instanceof firebase.firestore.Timestamp) {
        return value.toDate();
      }
      return undefined; // signals to use default behavior to cloneDeep()
    });
  }

  asJSON() {
    const copy = _clone(this);
    return _omit(copy, ["_ref", "_snapshot"]);
  }

  asData() {
    const copy = _cloneDeepWith(this._snapshot.data(), (value: any) => {
      if (value instanceof firebase.firestore.Timestamp) {
        return value.toDate();
      }
      return undefined; // signals to use default behavior to cloneDeep()
    });
    return { id: this._snapshot.id, ...this.data() };
  }
}

// constructor type for a document class
export type TypeDocCons<D extends TypedDoc> = new (
  snapshot: firebase.firestore.DocumentSnapshot
) => D;
