import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { createNavDef } from "../../../components/navigation/NavigationDefinitions";
import { PhoneSetupScreen } from "./PhoneSetupScreen";

export type PhoneSetupParamList = {
  PhoneSetup: undefined;
};

export type PhoneSetupScreenProps<Screen extends keyof PhoneSetupParamList> =
  NativeStackScreenProps<PhoneSetupParamList, Screen>;

export const phoneSetupNavDef = createNavDef({
  postponeLinks: false,
  screens: {
    PhoneSetup: {
      component: PhoneSetupScreen,
    },
  },
});
