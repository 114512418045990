import { Link, Modal, Text, useDisclose, VStack } from "native-base";
import React from "react";
import { PrimaryButton } from "../../../components/primitive/PrimaryButton";
import { useLocalUserState } from "../../../hooks/useLocalUserState";
import { AssetView } from "../../../components/primitive/AssetView";
import useEffectOnce from "react-use/lib/useEffectOnce";

export const ModalSignUpWelcome: React.FunctionComponent<{
  children: JSX.Element | JSX.Element[] | string | any;
}> = (props) => {
  const { didShowPaymentSetup: isSignUp } = useLocalUserState();
  const { didVisitPoint, didShowWelcome } = useLocalUserState();
  const { isOpen, onClose } = useDisclose(isSignUp && !didVisitPoint && !didShowWelcome);
  const { showedWelcome } = useLocalUserState();

  useEffectOnce(() => {
    // mark that the welcome was shown
    if (isOpen) showedWelcome();
  });

  if (isOpen) {
    return (
      <Modal isOpen={isOpen} onClose={onClose} size={"xl"} _backdrop={{ opacity: 0.4 }}>
        <Modal.Content marginBottom="auto" marginTop={70}>
          <Modal.Body p={4}>
            <VStack alignItems="center">
              <AssetView
                source={require("assets/illustrations/Friends-Wave.svg")}
                alt="Reminder"
                w="160px"
                h="160px"
              />
              <Text my={3} fontSize="lg" fontWeight="700" textAlign="center">
                Welcome!
              </Text>
              <Text mb={5} fontSize="md" fontWeight="400" textAlign="center">
                <Text>Thank you for signing up with Recirclable. Visit one of our </Text>
                <Link
                  href="https://www.recirclable.com/restaurants"
                  isUnderlined
                  _text={{ fontSize: "md", fontWeight: 700 }}
                >
                  participating restaurants
                </Link>
                <Text>
                  {" "}
                  and let them know you want your takeout in a Recirclable container. Scan the QR
                  code at checkout, follow the instructions and enjoy your food!
                </Text>
              </Text>
              <PrimaryButton onPress={onClose} label="See you!" />
            </VStack>
          </Modal.Body>
        </Modal.Content>
      </Modal>
    );
  } else {
    // note: multiple modals are "chained" as children. after one was shown, the next can be shown.
    return <>{props.children}</>;
  }
};
