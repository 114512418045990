import { CameraView } from "expo-camera";
import { Camera as LegacyCamera, CameraType as LegacyCameraType } from "expo-camera/legacy";
import { Box, Center, Text, View } from "native-base";
import React, { useCallback, useEffect } from "react";
import { Linking, Platform } from "react-native";
import { AlertYesNo } from "../modals/AlertYesNo";
import { useCameraPermission } from "./useCameraPermission";
import { CodeResultSuccess, parseQRCode } from "../../util/code";
import { HFlex } from "../layout/HFlex";
import { VFlex } from "../layout/VFlex";
import { useAutoClearState } from "../../hooks/useAutoClearState";
import { useAutoClearRef } from "../../hooks/useAutoClearRef";
import { ONE_SEC } from "../../util/constants";
import { AlertOk } from "../modals/AlertOk";
import { sentry } from "../../lib/sentry/sentry";
import { isSimulator } from "../../util/deviceInfo";

const OverlayLines = (props: { color: string; size: number; overlayCaption?: string | null }) => {
  const color = props.color;
  const size = `${props.size}px`;

  return (
    <VFlex alignSelf="center" justifyContent="center" flexGrow={1} bg="transparent">
      <HFlex bg="transparent">
        <Box
          bg="transparent"
          borderLeftColor={color}
          borderTopColor={color}
          borderBottomColor="transparent"
          borderRightColor="transparent"
          borderWidth={3}
          w={size}
          h={size}
        />
        <Box w={props.size} h={props.size} bg="transparent" />
        <Box
          bg="transparent"
          borderRightColor={color}
          borderTopColor={color}
          borderBottomColor="transparent"
          borderLeftColor="transparent"
          borderWidth={3}
          w={size}
          h={size}
        />
      </HFlex>
      <Box h={props.size} bg="transparent" />
      <HFlex bg="transparent">
        <Box
          bg="transparent"
          borderLeftColor={color}
          borderBottomColor={color}
          borderTopColor="transparent"
          borderRightColor="transparent"
          borderWidth={3}
          w={size}
          h={size}
        />
        <Box w={size} h={size} bg="transparent" />
        <Box
          bg="transparent"
          borderRightColor={color}
          borderBottomColor={color}
          borderTopColor="transparent"
          borderLeftColor="transparent"
          borderWidth={3}
          w={size}
          h={size}
        />
      </HFlex>
      <HFlex my={1} justifyContent="center" bg={"transparent"}>
        <Text color={color} textAlign="center" fontWeight="bold">
          {props.overlayCaption ?? ""}
        </Text>
      </HFlex>
    </VFlex>
  );
};

export interface ScanMsg {
  type: "wait" | "success" | "warn" | "error" | "alarm";
  text: string;
}
const msgBgColors = {
  wait: "white",
  success: "tertiary.700",
  warn: "amber.400",
  error: "red.700",
  alarm: "red.900",
};
const msgTextColors = {
  wait: "primaryText",
  success: "white",
  warn: "primaryText",
  error: "white",
  alarm: "white",
};

export const CameraOverlay = (props: { overlayCaption?: string | null; msg?: ScanMsg | null }) => {
  return (
    <>
      <OverlayLines color="yellow.300" overlayCaption={props.overlayCaption} size={70} />
      {!!props.msg?.text && (
        <Box position="absolute" bottom={0} left={0} w="100%" bgColor={msgBgColors[props.msg.type]}>
          <Text p={2} textAlign="center" color={msgTextColors[props.msg.type]} fontWeight="400">
            {props.msg.text}
          </Text>
        </Box>
      )}
    </>
  );
};

const SafeCamera = (props: {
  onQRCode: (qrCode: string) => void;
  onCameraPermission: (permission: boolean | null) => void;
  onNoCamera?: () => void;
  children?: JSX.Element | null;
}) => {
  const hasPermission = useCameraPermission();

  const handleCameraAlert = useCallback(
    (yesno?: boolean) => {
      if (yesno === true) {
        // yes -> open the setting screen
        Linking.openSettings();
      } else if (props.onNoCamera) {
        // no or the x button -> close the screen
        props.onNoCamera();
      }
    },
    [props.onNoCamera]
  );

  useEffect(() => {
    console.log("SAFE CAMERA PERMISSION", hasPermission);
    props.onCameraPermission(hasPermission);
  }, [hasPermission]);

  return (
    <>
      {hasPermission ? (
        Platform.select({
          native: (
            <CameraView
              style={{
                position: "relative",
                flex: 1,
                flexDirection: "column",
                justifyContent: "space-between",
                width: "100%",
              }}
              facing={"back"}
              barcodeScannerSettings={{
                barcodeTypes: ["qr"],
              }}
              onBarcodeScanned={(result) => {
                console.log("DID SCAN CODE", result.data);
                props.onQRCode(result.data);
              }}
              onCameraReady={() => {
                console.log("CAMERA READY");
              }}
              onMountError={(error) => {
                // supress error on simulator, because the simulator doesn't support a camera
                if (!isSimulator()) {
                  console.error("CAMERA ERROR", error);
                  sentry().captureException(error);
                }
              }}
            >
              {props.children}
            </CameraView>
          ),
          web: (
            /* need to use the legacy camera, because of bugs in Expo */
            <LegacyCamera
              style={{
                position: "relative",
                flex: 1,
                flexDirection: "column",
                justifyContent: "space-between",
                width: "100%",
              }}
              type={LegacyCameraType.back}
              barCodeScannerSettings={{
                interval: 50, // -> 20 fps
                barCodeTypes: ["qr"],
              }}
              onBarCodeScanned={(result) => {
                console.log("DID SCAN CODE", result.data);
                props.onQRCode(result.data);
              }}
              onCameraReady={() => {
                console.log("CAMERA READY");
              }}
              onMountError={(error) => {
                console.error("CAMERA ERROR", error);
                sentry().captureException(error);
              }}
            >
              {props.children}
            </LegacyCamera>
          ),
        })
      ) : (
        <Center height="100%">
          <Text color="yellow.300" fontWeight="bold">
            Camera starting ...
          </Text>
        </Center>
      )}
      {Platform.OS === "web" ? (
        <AlertOk
          title="Camera Permission"
          msg="The app requires access to the camera to scan QR codes and to function. Please enable the camera for this web site."
          primary="Continue"
          isOpen={hasPermission === false}
          onClose={props.onNoCamera}
        />
      ) : (
        <AlertYesNo
          title="Camera Permission"
          msg="The app requires access to the camera to scan QR codes and to function. Please enable access to the camera in the settings."
          primary="Settings"
          secondary="Close"
          isOpen={hasPermission === false}
          onClose={handleCameraAlert}
        />
      )}
    </>
  );
};

const DEFAULT_MSG = "";

export const QRCodeScanner: React.FunctionComponent<{
  expectedTypes: ("item" | "point")[];
  tempIgnoreCode?: string;
  onScan?: (result: CodeResultSuccess) => void;
  onNoCamera?: () => void;
  overlayCaption?: string;
  msg?: ScanMsg | null;
}> = (props) => {
  const [ignoreCodeRef, setIgnoreCode] = useAutoClearRef<string | null>(null, 5 * ONE_SEC);
  const [prevQRCodeRef, setPrevQRCode] = useAutoClearRef<string | null>(null);
  const [scanMsg, setScanMsg] = useAutoClearState<ScanMsg | null>(
    null /* {
    type: "wait",
    text: "Waiting for camera ...",
  } */
  );

  const handlePermissionChange = useCallback(
    (permission: boolean | null) => {
      if (permission) {
        setScanMsg({ type: "success", text: DEFAULT_MSG });
      } else if (permission === false) {
        setScanMsg({
          type: "alarm",
          text: "No camera. Please allow using the camera.",
        });
      }
    },
    [setScanMsg]
  );

  // apply a message passed in from the parent
  useEffect(() => {
    if (props.msg) setScanMsg(props.msg);
  }, [props.msg]);

  // apply ignore code passed in from the parent
  //
  // this allows the scanner to ignore erroneous code. here the background: when the customer
  // scans a return with scan point, the app immediately switches to container scanning, but
  // the phone is still pointed at the return code. this immediately leads to an error message,
  // which is confusing for the customer. we temporaily suppress this code.
  useEffect(() => {
    if (props.tempIgnoreCode) setIgnoreCode(props.tempIgnoreCode);
  }, [props.tempIgnoreCode]);

  // callback when the camera detects a QR code
  //
  // NOTE: the expo-camera implementation holds on to initially passed callback. that causes
  // some issues throughout the app. that's the reason we use a ref instead of a state here,
  // because we can change the ref's value from outside.
  const onQRCode = useCallback(
    (qrCode: string) => {
      // quick and dirty exclude the ignoreCode
      if (ignoreCodeRef.current && qrCode.includes(ignoreCodeRef.current)) return;

      // the camera constantly produces a stream of QR code, we only trigger a callback,
      // when the code changes. otherwise we would flood the receiver with messages
      if (qrCode === prevQRCodeRef.current) return;
      setPrevQRCode(qrCode);

      // do the actual handling
      const result = parseQRCode(qrCode, props.expectedTypes);
      if (result.code) {
        props.onScan?.(result);
      } else {
        setScanMsg({ type: "error", text: result.errorMsg! });
      }
    },
    [props.onScan, ignoreCodeRef]
  );

  return (
    <View>
      {/* a surrounding box was the best way to control the camera size */}
      <Box w="100%" h="380" bgColor="black">
        <SafeCamera
          onQRCode={onQRCode}
          onCameraPermission={handlePermissionChange}
          onNoCamera={props.onNoCamera}
        >
          <CameraOverlay msg={scanMsg} overlayCaption={props.overlayCaption} />
        </SafeCamera>
      </Box>
    </View>
  );
};
