import React, { useEffect } from "react";
import { useDisclose } from "native-base";
import { BoxSection } from "../../../components/layout/Sections";
import { HelloSection } from "./HelloSection";
import { useDate } from "../../../hooks/useDate";
import { ModalReminder } from "./ModalReminder";
import { useCustomer } from "../../../model/useCustomer";
import { isProduction } from "../../../lib/firebase/ifEnv";
import { SectionButton } from "../../../components/layout/SectionButton";
import { ModalSignUpWelcome } from "./ModalSignUpWelcome";
import { useTestState } from "../../../hooks/useTestState";
import { ModalCompleteProfile } from "./ModalCompleteProfile";
import { BackOfficeHeaderButtons } from "../../../components/navigation/BackOfficeHeaderButtons";
import DevActionSheet from "./DevActionSheet";
import { Screen } from "../../../components/layout/Screen";
import { PartnerSection } from "./PartnerSection";
import { CountSection } from "../../../components/primitive/CountSection";
import { hasPendingLink, popPendingLink } from "../../../components/navigation/pendingLink";
import { useLinkTo } from "@react-navigation/native";
import { FrontAppScreenProps } from "../../../frontAppStack";
import { friendlyFuture } from "../../../util/format";
import { VTransactions } from "../../../model/VTransactionDoc";
import { InteractionManager } from "react-native";
import { useMailComposer } from "../../../hooks/useMailComposer";
import { VerifyPhoneToast } from "../profile/VerifyPhoneToast";
import { ONE_DAY } from "../../../util/constants";
import { docQuery } from "../../../lib/firestore/fstore";
import { useRemoteFlags } from "../../../lib/remote_flags/useRemoteFlags";

export const MAX_PAST_RECEIPT = 2 * ONE_DAY;

export const HomeScreen = ({ navigation, route }: FrontAppScreenProps<"Home">) => {
  const composeMail = useMailComposer();
  const linkTo = useLinkTo();
  const { sampleReturnInfo, sampleCompleteModal } = useTestState();
  const dateState = useDate();
  const { balance, profile } = useCustomer();
  const featureOnlineOrder = useRemoteFlags((f) => f.onlineOrder);
  const devSheetState = useDisclose(false);

  let summary = balance.getExpirationSummary(dateState.now());
  summary.today += summary.overdue;
  if (!isProduction()) {
    if (sampleReturnInfo == 1) {
      summary = {
        sum: 15,
        overdue: 0,
        today: 1,
        tomorrow: 2,
        days2: 3,
        week: 4,
        rest: 5,
      };
    } else if (sampleReturnInfo == 2) {
      summary = {
        sum: 9,
        overdue: 0,
        today: 0,
        tomorrow: 0,
        days2: 0,
        week: 4,
        rest: 5,
      };
    }
  }

  useEffect(() => {
    const link = popPendingLink();
    if (link) {
      console.log("HAS PENDING LINK");
      InteractionManager.runAfterInteractions(() => {
        try {
          console.log("HANDLING PENDING LINK");
          linkTo(link);
        } catch (error) {
          console.error("LINK ERROR", link);
        }
      });
    }
  }, []);

  function handleDetailsPress() {
    navigation.push("Activity");
  }

  function handleSuggestPress() {
    composeMail({
      to: "hello@recirclable.com",
      subject: "Restaurant Suggestion",
      body:
        "Do you have a favorite restaurant that isn't yet using reusable " +
        "containers but is interested in exploring this option? " +
        "Please tell us about them, and help to shift habits and " +
        "mindset from recyclable to Recirclable! Thank you!",
    });
  }

  function handleShowMostRecent() {
    docQuery(
      VTransactions.query()
        .where("customerId", "==", balance.id)
        .where("type", "==", "borrow")
        .where("transactionAt", ">=", new Date(Date.now() - MAX_PAST_RECEIPT))
        .orderBy("transactionAt", "desc")
        .limit(1)
    ).then((transactions) => {
      if (transactions.length > 0) {
        navigation.push("ShowReceipt", { transactionId: transactions[0].id });
      }
    });
  }

  function handleCompleteProfile() {
    navigation.navigate("Profile");
  }

  const handleOnlineOrder = (locationId: string) => {
    navigation.navigate("OnlineOrder", { locationId });
  };

  const nextAt = balance.getNextExpiresAt();
  const nextDueMessage = nextAt ? `due by ${friendlyFuture(nextAt)}` : undefined;

  return (
    <Screen
      name="Home"
      backMode="none"
      right={<BackOfficeHeaderButtons />}
      overlay={() => (
        <VerifyPhoneToast profile={profile} onPress={() => navigation.push("ProfileChangePhone")} />
      )}
    >
      <HelloSection onLongPress={devSheetState.onOpen} />
      <CountSection
        count={balance?.outstandingSum?.toFixed() ?? "0"}
        title="Currently Borrowed"
        message={nextDueMessage}
        onPress={handleShowMostRecent}
      />
      <BoxSection>
        <SectionButton label="Show Activity Details" onPress={handleDetailsPress} />
      </BoxSection>
      {/* <PartnerSection
        title="Order Recirclable online"
        onlyOnlineOrder={true}
        onSuggest={handleSuggestPress}
        onOnlineOrder={handleOnlineOrder}
      /> */}
      <PartnerSection
        suggestTile={true}
        onSuggest={handleSuggestPress}
        onOnlineOrder={featureOnlineOrder ? handleOnlineOrder : undefined}
      />
      <DevActionSheet {...devSheetState} />
      {!hasPendingLink() ? (
        <ModalReminder summary={summary}>
          <ModalSignUpWelcome>
            <ModalCompleteProfile
              missingName={
                /*
                turned off since required phone setup also asks for name: {!profile.name || sampleCompleteModal}
                */
                false
              }
              missingEmail={!profile.email || sampleCompleteModal}
              onCompleteProfile={handleCompleteProfile}
            />
          </ModalSignUpWelcome>
        </ModalReminder>
      ) : null}
    </Screen>
  );
};
