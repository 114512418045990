import { Text } from "native-base";
import React from "react";
import { Linking } from "react-native";
import { BoxSection, Section } from "../../../components/layout/Sections";
import { PrimaryButton } from "../../../components/primitive/PrimaryButton";
import { SecondaryButton } from "../../../components/primitive/SecondaryButton";
import { VSpace } from "../../../components/layout/VSpace";
import { Screen } from "../../../components/layout/Screen";
import { HeroSection } from "../../../components/layout/HeroSection";
import { PreambleScreenProps } from "../preambleStack";

export const PremableItemInfoScreen = ({ navigation, route }: PreambleScreenProps<"ItemInfo">) => {
  function handleAction() {
    navigation.replace("SignIn", {});
  }

  function handleLearnMore() {
    Linking.openURL("https://www.recirclable.com");
  }

  return (
    <Screen name="Item Info" backMode="close">
      <HeroSection image={require("assets/illustrations/QR-Code.svg")} />
      <Section>
        <Text mx={4} my={2} textAlign="center" fontSize="lg" fontWeight="600">
          You scanned the QR code on a bowl. What would you like to do?
        </Text>
      </Section>
      <Section>
        <PrimaryButton onPress={handleAction} my={1} label="Sign In/Up" />
      </Section>
      <BoxSection px={3} py={2}>
        <Text mb={1} textAlign="center" fontSize="lg" fontWeight="700">
          Borrow a bowl:
        </Text>
        <Text>
          Please sign In/Up and then scan the LARGE QR CODE displayed at the restaurant checkout to
          proceed with borrowing bowls.
        </Text>
      </BoxSection>
      <BoxSection px={3} py={2}>
        <Text mb={1} textAlign="center" fontSize="lg" fontWeight="700">
          Return a bowl
        </Text>
        <Text>
          Please sign In/Up and then scan the LARGE QR CODE displayed at the Recirclable return bin.
        </Text>
      </BoxSection>
      <Section>
        <SecondaryButton onPress={handleLearnMore} my={1} label="Learn more about Recirclable" />
      </Section>
      <VSpace h="30px" />
    </Screen>
  );
};
