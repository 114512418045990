import { Box, Divider, HStack, Link, Modal, Text, useDisclose, VStack } from "native-base";
import React, { useEffect } from "react";
import { PrimaryButton } from "../../../components/primitive/PrimaryButton";
import { AssetView } from "../../../components/primitive/AssetView";
import { ExpirationSummary } from "../../../model/VBalanceDoc";

function dueMessage(summary: ExpirationSummary) {
  let message: string | undefined;
  let count: number = 0;

  if (summary.overdue || summary.today) {
    count = summary.overdue + summary.today;
    message = "today";
  } else if (summary.tomorrow) {
    count = summary.tomorrow;
    message = "tomorrow";
  } else if (summary.days2) {
    count = summary.days2;
    message = "in 2 days";
  } else if (summary.week) {
    count = summary.week;
    message = "within a week";
  } else if (summary.rest) {
    count = summary.rest;
    message = "in more than a week";
  }

  if (count === 1) {
    message = `Your bowl is due for return ${message}`;
  } else if (count === summary.sum) {
    message = `All bowls are due for return ${message}`;
  } else {
    message = `${count} bowls are due for return ${message}`;
  }
  return message;
}

export const ModalReminder = (props: {
  summary: ExpirationSummary;
  children?: JSX.Element | JSX.Element[] | string | any;
}) => {
  const { isOpen, onClose, onOpen } = useDisclose(false);

  // Whenever the summary changes, calculate whether the modal should be shown. The summary
  // changes, when a Dev text changes the date.
  useEffect(() => {
    if (
      props.summary.today > 0 ||
      props.summary.tomorrow > 0 ||
      props.summary.days2 > 0 ||
      props.summary.week > 0
    ) {
      onOpen();
    }
  }, [props.summary.today, props.summary.tomorrow, props.summary.days2, props.summary.week]);

  if (isOpen) {
    const urgentCount = props.summary.today;

    const imageSource =
      urgentCount > 0
        ? require("assets/illustrations/Appointment.svg")
        : require("assets/illustrations/Time.svg");

    return (
      <Modal isOpen={isOpen} onClose={onClose} size={"xl"} _backdrop={{ opacity: 0.4 }}>
        <Modal.Content marginBottom="auto" marginTop={70}>
          <Modal.Body p={4}>
            <VStack alignItems="center">
              <AssetView source={imageSource} alt="Reminder" w="180px" h="180px" />
              {urgentCount > 0 ? (
                <HStack p={2} justifyContent="flex-start" alignItems="flex-end">
                  <Box bg="primaryText" borderRadius="md" w="53px" h="53px">
                    <Text
                      lineHeight="53px"
                      color="white"
                      textAlign="center"
                      // FIXME: verticalAlign="middle"
                      fontSize="3xl"
                      fontWeight="700"
                    >
                      {urgentCount}
                    </Text>
                  </Box>
                  <Text px={4} py={2} color="primaryText" fontSize="xl" fontWeight="700">
                    Bowl(s) due today
                  </Text>
                </HStack>
              ) : (
                <Text pt={3} pb={1} fontSize="lg" fontWeight="700" textAlign="center">
                  {dueMessage(props.summary)}
                </Text>
              )}
              <Divider my={3} w="100%" />
              <Text mb={6} fontSize="md" fontWeight="600" textAlign="center">
                <Text>Please rinse and return to any </Text>
                <Link
                  href="https://www.recirclable.com/restaurants"
                  isUnderlined
                  _text={{ fontSize: "md", fontWeight: 700 }}
                >
                  participating restaurants
                </Link>
                <Text>. Thank you!</Text>
              </Text>
              <PrimaryButton onPress={onClose} label="Continue" />
            </VStack>
          </Modal.Body>
        </Modal.Content>
      </Modal>
    );
  } else {
    // note: multiple modals are "chained" as children. after one was shown, the next can be shown.
    return props.children ?? null;
  }
};
