import React from "react";
import { IconButton, IInputProps, Input } from "native-base";
import { HFlex } from "../layout/HFlex";
import { EyeIcon, EyeOffIcon } from "../icons/Icons";
import useBoolean from "react-use/lib/useBoolean";

export const PasswordField: React.FunctionComponent<{} & IInputProps> = (props) => {
  const [isShowPwd, toogleShowPwd] = useBoolean(false);

  const eyeIcon = <EyeIcon color="primaryText" size="lg" />;
  const eyeOffIcon = <EyeOffIcon color="primaryText" size="lg" />;

  return (
    <HFlex justifyContent="space-between">
      <Input
        flexGrow={1}
        nativeID="password"
        variant="unstyled"
        keyboardType="default"
        autoComplete="password"
        secureTextEntry={!isShowPwd}
        {...props}
      />
      <IconButton icon={isShowPwd ? eyeOffIcon : eyeIcon} onPress={toogleShowPwd} />
    </HFlex>
  );
};
