import { ONE_MIN } from "./constants";

type CacheEntry<T> = {
  value: T;
  timestamp: number;
};

export class TTLCache<T> {
  ttl: number;
  map: Map<string, CacheEntry<T>>;

  constructor(ttl: number = 10 * ONE_MIN) {
    this.ttl = ttl;
    this.map = new Map<string, CacheEntry<T>>();
  }

  get(key: string): T | undefined {
    const entry = this.map.get(key);
    if (entry?.timestamp && entry.timestamp + this.ttl > Date.now()) return entry.value;
    return undefined;
  }

  set(key: string, value: T) {
    this.map.set(key, { value, timestamp: Date.now() });
  }

  clear() {
    this.map.clear();
  }
}
